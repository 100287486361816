import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";

const Culture = () => {
  return (
    <div>
      <Header selected="Culture" />
      <div className="font-sans absolute w-full">
        <div className="mt-[103px] md:mt-[152px] lg:mt-[158.5px] min-h-[40vw] lg:bg-culture lg:bg-cover lg:px-[10%]">
          <img
            src="/assets/group_culture_teaser02_mobil.jpg"
            alt="hero-social"
            className="lg:hidden"
          />
          <h1 className="hidden lg:block lg:pl-16 text-white text-[24px] uppercase font-semibold leading-[1em] pt-[180px] lg:pt-[220px]">
            THE HAITIAN CULTURE
          </h1>
          <h2 className="hidden lg:block text-white lg:pl-16 text-[45px] uppercase font-semibold lg:leading-[36px] mt-2 xl:mt-4">
            KNOWING THE WAY TO THE FUTURE
            <br />
            BY RESPECTING THE PAST
          </h2>
        </div>
        <div className="h-[30px] bg-[#E4BE70]" />

        <div className="w-[92%] md:w-[80%] mx-auto pt-10 md:pt-9 text-left">
          <h2 className="font-medium text-[24px] mb-2.5 text-[#001b36] uppercase lg:hidden">
            The Haitian Culture
          </h2>
          <h3 className="text-[32px] text-[#001b36] uppercase font-semibold leading-[1em] lg:hidden">
            KNOWING THE WAY TO THE FUTURE
            <br />
            BY RESPECTING THE PAST
          </h3>

          <p className="mt-20 lg:mt-10 text-center text-[18pt] text-[#d0a740] leading-[23.8px]">
            Entrepreneurial spirit, cost awareness, willingness to invest and
            full respect for&nbsp;employees, partners and customers are the
            cornerstones of our corporate culture.&nbsp;Because high-quality and
            internationally competitive products are created&nbsp;in the minds
            and with the hands of motivated employees.
          </p>

          <div className="bg-[#001b36] p-[20px] lg:p-0 mt-8 md:mt-16 text-center text-white">
            <div className="lg:flex">
              <div className="hidden lg:inline lg:bg-spirit lg:bg-cover lg:bg-no-repeat lg:bg-center lg:w-1/2" />

              <div className="lg:w-1/2 lg:p-[50px]">
                <img src="/assets/01.png" alt="01" className="mx-auto" />
                <h2 className="text-[32px] font-semibold">SPIRIT</h2>
                <p className="text-[14px] leading-[23.8px]">
                  <strong>
                    PRACTICE, DEVELOPMENT, INNOVATION AND PERSISTENCE.
                  </strong>
                </p>
                <p className="text-[14px] leading-[23.8px] mt-3">
                  With courage, passion and visionary power, Mr. Zhang
                  Jingzhang&nbsp;1966 founded the company Haitian in Ningbo. We
                  used every opportunity&nbsp;to develop and grow, and the
                  diversity of technological&nbsp;potential – without any
                  restrictions in thinking and ready to go&nbsp;beyond and build
                  global connections.&nbsp;1974 we manufactured the first
                  injection moulding machine with&nbsp;30 g injection volume.
                  1989 We exported our first machine to Europe.&nbsp;1994, we
                  have been the world‘s number 1 in terms of units and holds
                  this position to date.
                </p>
                <p className="text-[14px] leading-[23.8px] mt-3">
                  Haitian is more than a company. We take responsibility for our
                  employees&nbsp;and society, stand for continuity with strong
                  family bond&nbsp;and steady growth with sustainable strategies
                </p>
              </div>
            </div>

            <div className="lg:flex">
              <div className="mt-12 lg:w-1/2 lg:p-[50px] lg:mt-0">
                <img src="/assets/02.png" alt="01" className="mx-auto" />
                <h2 className="text-[32px] font-semibold">PHILOSOPHY</h2>
                <p className="text-[14px] leading-[23.8px]">
                  <strong>LOYALTY TO CUSTOMERS,</strong>
                  <br />
                  <strong>SERVICE TO STAFFS AND BENEFIT TO SOCIETY.</strong>
                </p>
                <p className="text-[14px] leading-[23.8px] mt-3">
                  While we emphasize on performance at work, we pay equal
                  attention&nbsp;to a healthy lifestyle for our employees
                  after-work. We have set&nbsp;up 17 recreational clubs under
                  Haitian Group Union and they offer&nbsp;plenty of different
                  opportunities for our employees to engage in&nbsp;healthy
                  activities and develop personal interests.
                </p>
              </div>

              <div className="hidden lg:inline lg:bg-philosophy lg:bg-no-repeat lg:bg-center lg:w-1/2" />
            </div>

            <div className="lg:flex">
              <div className="hidden lg:inline lg:bg-divisions lg:bg-no-repeat lg:bg-center lg:w-1/2" />

              <div className="mt-12 lg:w-1/2 lg:p-[50px] lg:mt-0">
                <img src="/assets/03.png" alt="01" className="mx-auto" />
                <h2 className="text-[32px] font-semibold">MISSION</h2>
                <p className="text-[14px] leading-[23.8px]">
                  <strong>EQUIPMENT FOR CHINA. EQUIPMENT FOR THE WORLD.</strong>
                </p>
                <p className="text-[14px] leading-[23.8px] mt-3">
                  Our differentiated machine concepts not only meet the
                  specific&nbsp;requirements of the most important industries
                  and future industries.&nbsp;Innovative drive solutions with
                  high energy savings and further&nbsp;measures to conserve
                  resources provide sustainable environmental&nbsp;protection
                  around the globe. and generate further
                  competitive&nbsp;advantages for our customers.
                </p>
                <button className="w-[200px] p-[3px] xl:py-[6px] border-[2px] border-white text-[11.2px] mt-8">
                  <Link to="/portfolios">LEARN MORE</Link>
                </button>
              </div>
            </div>

            <div className="lg:flex">
              <div className="mt-12 lg:w-1/2 lg:p-[50px] lg:mt-0">
                <img src="/assets/04.png" alt="01" className="mx-auto" />
                <h2 className="text-[32px] font-semibold leading-[1em] mb-2">
                  „THREE-COST“ MANAGEMENT
                </h2>
                <p className="text-[14px] leading-[23.8px]">
                  <strong>LABOR COST, PRODUCTION COST</strong>
                  <br />
                  <strong>AND CAPITAL COST.</strong>
                </p>
                <p className="text-[14px] leading-[23.8px] mt-3">
                  A strong cost awareness and the inner conviction to continue
                  to&nbsp;track and exploit technological potential are also
                  shaping our willingness&nbsp;to invest in product development.
                  Always with the aim&nbsp;to improve our own production
                  efficiency and to generate new,&nbsp;economically significant
                  solutions for the benefit of our customers&nbsp;and to expand
                  them. Continuously increasing sales figures and
                  the&nbsp;continued market leadership for decades confirm this
                  path.
                </p>
              </div>

              <div className="hidden lg:inline lg:bg-management lg:bg-no-repeat lg:bg-center lg:w-1/2" />
            </div>

            <div className="lg:flex">
              <div className="hidden lg:inline lg:bg-quality lg:bg-no-repeat lg:bg-center lg:w-1/2" />

              <div className="mt-12 lg:w-1/2 lg:p-[50px] lg:mt-0">
                <img src="/assets/05.png" alt="01" className="mx-auto" />
                <h2 className="text-[32px] font-semibold leading-[1em]">
                  QUALITY GUIDELINE
                </h2>
                <p className="text-[14px] leading-[23.8px] mt-3">
                  <strong>BEST QUALITY, HIGHEST EFFICIENCY AS WELL AS</strong>
                  <br />
                  <strong>REPUTABLE IN THE WORLD.</strong>
                </p>
                <p className="text-[14px] leading-[23.8px] mt-3">
                  We have set up a complete product quality assurance system
                  which&nbsp;involves specific personnel monitoring quality at
                  different points in&nbsp;our business flow from procurement of
                  components, processing&nbsp;and assembly, final products
                  testing and commissioning.
                </p>
                <p className="text-[14px] leading-[23.8px] mt-3">
                  We provide full-service to our customers after our products
                  are put&nbsp;into full operation and ensure our customers
                  receive prompt feedback&nbsp;and solutions. We understand the
                  importance of different&nbsp;knowledge and skills required for
                  different posts and the need for your employees to keep
                  updated with latest development in our industry&nbsp;and
                  technology. In 2016 was the total number of
                  employee&nbsp;trainings 13,342 entries.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Culture;
