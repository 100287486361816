import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";

const HaitianEastern = () => {
  return (
    <div>
      <Header />
      <div className="font-sans absolute w-full">
        <div className="mt-[153px] md:mt-[202px] lg:mt-[208.5px] w-[92%] md:w-[80%] mx-auto pt-16 pb-10 border-[0.75pt] border-[rgba(0,0,0,0.4)]">
          <h1 className="text-center text-[#d0a740] uppercase text-[23px] font-semibold leading-[50px] px-[25px]">
            HAITIAN EASTERN OPERATION CENTER SOUTHEAST CHINA SETS SAIL
          </h1>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] text-center mt-3">
            2022-07-22
          </p>
          <img
            src="/assets/huanan_1.jpg"
            alt="huanan_1"
            className="w-full my-[25px]"
          />
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] mt-10 pb-3.5 px-[25px]">
            On July 2, Haitian Eastern Operation Center Southeast China
            officially opened in Dongguan, which is not only an important step
            for Haitian to consolidate the layout of South China and deeply
            cultivate regional development, but also will bring high-standard
            and high-quality products and services to local customers and
            actively empower the industry.
          </p>
          <h2 className="text-[#001b36] px-[25px] text-[18px] leading-[22px] mt-6 mb-10">
            <strong>
              PLAN A REGION FROM A GLOBAL PERSPECTIVE, AND SERVE THE GLOBAL WITH
              A REGION
            </strong>
          </h2>
          <section className="lg:flex lg:justify-between lg:px-[25px]">
            <img
              src="/assets/huanan_2.jpg"
              alt="huanan_2"
              className="w-full mt-6 lg:w-[47%] px-[25px] lg:px-0"
            />
            <img
              src="/assets/huanan_7.jpg"
              alt="huanan_7"
              className="w-full mt-6 lg:w-[47%] px-[25px] lg:px-0"
            />
          </section>
          <section className="lg:flex lg:justify-between lg:px-[25px]">
            <img
              src="/assets/huanan_3.jpg"
              alt="huanan_3"
              className="w-full mt-6 lg:w-[47%] px-[25px] lg:px-0"
            />
            <img
              src="/assets/huanan_4.jpg"
              alt="huanan_4"
              className="w-full mt-6 lg:w-[47%] px-[25px] lg:px-0"
            />
          </section>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] mt-8 pb-3.5 px-[25px]">
            Haitian Eastern Operation Center Southeast China, opened and
            operated by Haitian Plastics Machinery Group South China Company, is
            located in the core circle of high-speed logistics in Dongguan. The
            Center is built into a three-floor single comprehensive building
            integrating sales, application, service and office, with a total
            area of 1,500m<sup>2</sup>. The first floor is mainly used for the
            machine exhibition, customer training, mold testing, the second
            floor is used for popular sample display, conference, training and
            office and the third floor is used for both comprehensive services
            and product applications. At the same time, the Center is equipped
            with a green screen live streaming room to meet all kinds of live
            streaming activities and create a new model of the traditional
            machinery industry.
          </p>
          <section className="lg:flex lg:justify-between">
            <img
              src="/assets/huanan_10.jpg"
              alt="huanan_10"
              className="w-full px-[25px] mt-6 lg:w-[31%] lg:pr-0"
            />
            <img
              src="/assets/huanan_11.jpg"
              alt="huanan_11"
              className="w-full px-[25px] mt-6 lg:w-[31%] lg:px-0"
            />
            <img
              src="/assets/huanan_12.jpg"
              alt="huanan_12"
              className="w-full px-[25px] mt-6 lg:w-[31%] lg:pl-0"
            />
          </section>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] mt-8 pb-4 px-[25px]">
            In addition to the hardware configuration, the Center has a
            professional application engineer team to fully meet the diversified
            customer needs from pre-sales technical support to in-sales and
            after-sales service.
          </p>
          <section className="lg:flex lg:justify-between">
            <img
              src="/assets/huanan_13.jpg"
              alt="huanan_13"
              className="w-full px-[25px] mt-6 lg:w-[31%] lg:pr-0"
            />
            <img
              src="/assets/huanan_14.jpg"
              alt="huanan_14"
              className="w-full px-[25px] mt-6 lg:w-[31%] lg:px-0"
            />
            <img
              src="/assets/huanan_15.jpg"
              alt="huanan_15"
              className="w-full px-[25px] mt-6 lg:w-[31%] lg:pl-0"
            />
          </section>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] mt-8 pb-4 px-[25px]">
            At the opening ceremony, leader representatives including Shen Li,
            General Manager of Haitian Machinery Guangdong Co., Ltd. and Haitian
            Plastics Machinery Guangzhou Co., Ltd., Xu Haihui, General Manager
            of Haitian Plastics Machinery Group South China Company and Miao
            Longzhen, Deputy General Manager of Haitian Plastics Machinery Group
            South China Company attended the ceremony and delivered speeches.
          </p>
          <section className="lg:flex lg:justify-between lg:px-[25px]">
            <img
              src="/assets/huanan_16.jpg"
              alt="huanan_16"
              className="w-full mt-6 lg:w-[47%] px-[25px] lg:px-0"
            />
            <img
              src="/assets/huanan_19.jpg"
              alt="huanan_19"
              className="w-full mt-6 lg:w-[47%] px-[25px] lg:px-0"
            />
          </section>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] mt-8 pb-4 px-[25px]">
            Along with the applause, all leaders and guests took to the stage
            for the ribbon-cutting ceremony to witness the grand opening of
            Haitian Eastern Operation Center Southeast China. After the
            ribbon-cutting ceremony, painting the eyes of the dancing lion,
            meaning auspicious, brought the site atmosphere to a high point.
          </p>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] pb-5 px-[25px]">
            Plan a region from a global perspective, and serve the global with a
            region. Dongguan, an important manufacturing area in Guangdong-Hong
            Kong-Macao Greater Bay Area, has strong manufacturing strength,
            complete industrial system and obvious geographical advantages.
            Therefore, the establishment of Haitian Eastern Operation Center
            Southeast China in Dongguan will not only further improve the sales
            and service layout in South China, but also radiate to cities such
            as Dongguan, Shenzhen and Huizhou on the East Bank of the Pearl
            River, thus bringing more convenient and intimate product service
            experience and overall solutions to local customers, and greatly
            improving the comprehensive service capacity of Haitian Eastern
            South China.
          </p>
          <h2 className="text-[#001b36] px-[25px] text-[18px] leading-[22px] mb-3">
            <strong>ALL IS READY</strong>
          </h2>
          <img
            src="/assets/huanan_22.jpg"
            alt="huanan_22"
            className="w-full px-[25px]"
          />
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] mt-6 pb-4 px-[25px]">
            With a broad market space, South China is the key strategic market
            and development center of Haitian. In March last year, the Haitian
            Comprehensive South China Headquarters Project was launched in
            Shunde, Guangdong, opening the “double center” model of Ningbo and
            South China. The Eastern South China Operation Center is another
            subsidiary of Haitian in South China, which will further improve the
            regional service system of Haitian in South China.
          </p>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] pb-1 px-[25px]">
            <strong>All is ready.</strong>&nbsp;As a key component for Haitian
            to deeply cultivate in the South China market continuously, Haitian
            Eastern Operation Center Southeast China will complement each other
            and gather a strong joint force. With more meticulous and
            considerate service, Haitian will bring the ultimate product
            experience to more customers in South China, deploy more
            high-quality projects in South China and work with local customers
            to develop and create the future together.
          </p>
        </div>

        <div className="mt-10 w-[92%] md:w-[80%] mx-auto border-[0.75pt] border-[rgba(0,0,0,0.4)] p-[25px]">
          <h2 className="text-[#d0a740] text-[18px] font-bold">
            RELATED POSTS
          </h2>
          <div className="xl:flex xl:justify-between">
            <div className="md:flex md:justify-between xl:w-[65%]">
              <div className="md:w-[48%]">
                <Link to="/haitian-chint">
                  <img
                    src="/assets/zhengtai_home-648x250.jpg"
                    alt="hti-china-headquarter"
                    className="my-4 min-h-[150px] object-cover w-full"
                  />
                  <p className="text-[#d0a740] text-[14px] font-bold uppercase">
                    Haitian &amp; Chint｜Thematic Exchange Meeting – Grow
                    together with Customers
                  </p>
                  <p className="text-[rgba(0,0,0,0.6)] text-[14px] pb-5">
                    2022-08-31
                  </p>
                </Link>
              </div>

              <div className="md:w-[48%]">
                <Link to="/new-developments">
                  <img
                    src="/assets/hti-china-headquarter-1024x576-1-648x250.jpg"
                    alt="hti-china-headquarter"
                    className="my-4 min-h-[150px] object-cover w-full"
                  />
                  <p className="text-[#d0a740] text-[14px] font-bold uppercase">
                    New developments and European premieres at K 2022
                  </p>
                  <p className="text-[rgba(0,0,0,0.6)] text-[14px] pb-5">
                    2022-08-05
                  </p>
                </Link>
              </div>
            </div>

            <div className="xl:w-[33%]">
              <Link to="/tour">
                <img
                  src="/assets/germany-open-house_home-648x250.jpg"
                  alt="germany-open-house_home"
                  className="my-4 min-h-[150px] object-cover w-full"
                />
                <p className="text-[#d0a740] text-[14px] font-bold uppercase">
                  A Tour in Haitian International Germany Open House
                </p>
                <p className="text-[rgba(0,0,0,0.6)] text-[14px] pb-5">
                  2022-07-27
                </p>
              </Link>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default HaitianEastern;
