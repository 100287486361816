import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import Footer from "../components/Footer";
import Header from "../components/Header";

const Milestones = () => {
  return (
    <div>
      <Header />
      <div className="font-sans absolute w-full">
        <div className="mt-[103px] md:mt-[152px] lg:mt-[158.5px] min-h-[40vw]">
          <div className="bg-milestone bg-cover bg-center h-[200px] md:h-[300px] lg:h-[400px]" />
        </div>

        <div className="w-[92%] md:w-[80%] mx-auto pt-8 md:pt-12 xl:p-0  text-left">
          <h1 className="text-[rgba(20,58,132,0.7)] text-[15px] md:text-[24px]">
            OUR HISTORY
          </h1>
          <h2 className="relative pb-[7px] text-[16px] md:text-[32px] text-[#143a84] font-semibold after:content-[''] after:w-[70px] after:h-[4px] after:absolute after:left-0 after:ml-0 after:bottom-[-10px] after:bg-[#cdcdcd]">
            50 YEARS EXPERIENCE AT A GLANCE
          </h2>
        </div>

        <div className="w-[92%] md:w-[80%] mx-auto py-28">
          <VerticalTimeline lineColor="#D7E4ED">
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2022"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                HAITIAN HUAYUAN JAPAN BRANCH OPENS NEW BUSINESS LOCATION IN
                NAGOYA
              </p>
              <img
                src="/assets/japan_2-1.jpg"
                alt="japan_2-1"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2021"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                HAITIAN EXPERIENCE CENTER OFFICIALLY OPENED
              </p>
              <img src="/assets/shown2.jpg" alt="shown2" className="mt-5" />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2021"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                THE OPENING OF THE HAITIAN CULTURE AND SPORTS CENTER
              </p>
              <img src="/assets/wenti_8.jpg" alt="wenti_8" className="mt-5" />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2021"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                OFFICIAL FOUNDATION OF NINGBO HAITIAN SMART SOLUTIONS TECHNOLOGY
                CO., LTD.
              </p>
              <img src="/assets/openning.jpg" alt="openning" className="mt-5" />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2021"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                HAITIAN DIE CASTING BONDED AREA BASE LAUNCHED OFFICIALLY
              </p>
              <img
                src="/assets/dc_open_1.jpg"
                alt="dc_open_1"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2020"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                “HAITIAN UNIVERSITY” WAS OFFICIALLY ESTABLISHED
              </p>
              <img
                src="/assets/微信图片_20200713151445.jpg"
                alt="微信图片_20200713151445"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2020"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                Market launch 3rd Generation
              </p>
              <img
                src="/assets/MA3_history.jpg"
                alt="MA3_history"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2019"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                THE NEW TECHNOLOGY GENERATION 3
              </p>
              <img
                src="/assets/generation-3-logo.jpg"
                alt="generation-3-logo"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2018"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                GRAND OPENING OF NEW PLANT IN HAITIAN TURKEY
              </p>
              <img
                src="/assets/Opening-Turkey_05.jpg"
                alt="Opening-Turkey_05"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2018"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                OPENING OF HAITIAN INDIA NEW PLANT
              </p>
              <img
                src="/assets/26_opening_haitian_india_new_plant_2018.jpg"
                alt="26_opening_haitian_india_new_plant_2018"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2018"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                NAMED AS NATIONAL HIGH-TECH ENTERPRISE AWARD
              </p>
              <img src="/assets/2005-1.jpg" alt="2005-1" className="mt-5" />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2018"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                The foundation laying ceremony of Haitian Cultural and Sports
                Center was held
              </p>
              <img
                src="/assets/2018_CULTURAL-SPORTS-CENTER.jpg"
                alt="2018_CULTURAL-SPORTS-CENTER"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2017"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                ACCUMULATIVE SALES OF MA SERIES EXCEEDED 200,000 UNITS
              </p>
              <img
                src="/assets/MAIIs_2500_frontal_Product.png"
                alt="MAIIs_2500_frontal_Product"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2017"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                HAITIAN MEDIUM AND LARGE INJECTION MOLDING MACHINE INTELLIGENT
                MANUFACTURING BASE SETTLED DOWN IN WUXI
              </p>
              <img
                src="/assets/21.12.17_Haitian-medium-and-large-injection-molding-machine-intelligent-manufacturing-base-settled-down-in-Wuxi.jpg"
                alt="21.12.17_Haitian-medium-and-large-injection-molding-machine-intelligent-manufacturing-base-settled-down-in-Wuxi"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2017"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                WE PASSED THE IS014001 CERTIFICATION
              </p>
              <img
                src="/assets/2017_IS014001-CERTIFICATION.png"
                alt="2017_IS014001-CERTIFICATION"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2017"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                WE PASSED THE OHSAS18001 CERTIFICATION
              </p>
              <img
                src="/assets/2017_OHSAS18001-CERTIFICATION.png"
                alt="2017_OHSAS18001-CERTIFICATION"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2017"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                CEO Zhang Was Listed President of China Plastic Machinery
                Industry Association
              </p>
              <img
                src="/assets/12.05.17_CEO-Zhang-was-listed-President-of-Ningbo-Plastic-Machinery-Industry-Association.jpg"
                alt="12.05.17_CEO-Zhang-was-listed-President-of-Ningbo-Plastic-Machinery-Industry-Association"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2017"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                HAITIAN WON 2017 YFPO SUPPLIER 10TH ANNIVERSARY SPECIAL
                CONTRIBUTION AWARD
              </p>
              <img
                src="/assets/04.12.17_Haitian-won-2017-YFPO-Supplier-10th-Anniversary-Special-Contribution-Award.jpg"
                alt="04.12.17_Haitian-won-2017-YFPO-Supplier-10th-Anniversary-Special-Contribution-Award"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2016"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                Ningbo Haitian Precision Machinery Co., Ltd. was successfully
                listed on the Shanghai Stock Exchange
              </p>
              <img src="/assets/2016-1_s.jpg" alt="2016-1_s" className="mt-5" />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2016"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                Haitian Die Casting Equipment Co., Ltd. was founded
              </p>
              <img
                src="/assets/海天金属门卫2019-08-16-1_s.jpg"
                alt="海天金属门卫2019-08-16-1_s"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2016"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                HAITIAN INTERNATIONAL RAISES ITS BRAND VISIBILITY ON TIMES
                SQUARE, NEW YORK
              </p>
              <img
                src="/assets/23_raise_of_brand_visibility_TS_NY_2016.jpg"
                alt="23_raise_of_brand_visibility_TS_NY_2016"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2016"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                OPENING OF THE NEW PRODUCTION HALL AT HAITIAN INTERNATIONAL
                GERMANY
              </p>
              <img
                src="/assets/22_opening_new_production_hall_germany_2016.jpg"
                alt="22_opening_new_production_hall_germany_2016"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2016"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                OPERATION OF HAITIAN TONGTU ROAD PLANT
              </p>
              <img
                src="/assets/24_operation_tongtu_road_plant_2016.jpg"
                alt="24_operation_tongtu_road_plant_2016"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2016"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                OPENING OF HAITIAN SUBSIDARY IN THAILAND
              </p>
              <img
                src="/assets/21_opening_haitian_thailand_2016.jpg"
                alt="21_opening_haitian_thailand_2016"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2016"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                HAITIAN CELEBRATES ITS 50TH ANNIVERSARY
              </p>
              <img
                src="/assets/50-years-logo.jpg"
                alt="50-years-logo"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2015"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                GRAND OPENING OF ZHAFIR CHUNXIAO PLANT
              </p>
              <img
                src="/assets/zhafir_2015.jpg"
                alt="zhafir_2015"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2015"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                OPENING OF HAITIAN SUBSIDIARY IN Mexico
              </p>
              <img
                src="/assets/20_opening_haitian_mexico_2015.jpg"
                alt="20_opening_haitian_mexico_2015"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2015"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                OPENING OF HAITIAN SUBSIDIARY IN INDONESIA
              </p>
              <img
                src="/assets/19_opening_haitian_indonesia_2015.jpg"
                alt="19_opening_haitian_indonesia_2015"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2014"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                OPENING OF HAITIAN SUBSIDIARY IN INDIA
              </p>
              <img
                src="/assets/18_opening_haitian_india_2014.jpg"
                alt="18_opening_haitian_india_2014"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2013"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                Haitian Charity Foundation was established
              </p>
              <img src="/assets/2013-2_s.jpg" alt="2013-2_s" className="mt-5" />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2013"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                DELIVERY OF JU66000II, THE SUPER LARGE TWO-PLATEN MACHINE
              </p>
              <img
                src="/assets/17_JU66000II_delivery_2014.jpg"
                alt="17_JU66000II_delivery_2014"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2013"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                OPENING OF THE NEW HAITIAN HEADQUARTERS IN NINGBO, CHINA
              </p>
              <img
                src="/assets/2014_opening-new-headquarters.jpg"
                alt="2014_opening-new-headquarters"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2012"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                TOP 100 MACHINERY INDUSTRY ENTERPRISE IN CHINA
              </p>
              <img src="/assets/2012.jpg" alt="2012" className="mt-5" />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2012"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                Worldwide Introduction of the New Machine Generation 2
              </p>
              <img
                src="/assets/2012_machine-generation-2.jpg"
                alt="2012_machine-generation-2"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2012"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                Ningbo Haitian Precision Machinery Co., Ltd. was founded
              </p>
              <img
                src="/assets/海天精工堰山事业部门卫-小.jpg"
                alt="海天精工堰山事业部门卫-小"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2011"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                Haitian Vietnam Production Base was put into production
              </p>
              <img
                src="/assets/16_opening_haitian_vietnams_plant_2011.jpg"
                alt="16_opening_haitian_vietnams_plant_2011"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2010"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                MANUFACTURING BREAK 30,000 MACHINES
              </p>
              <img
                src="/assets/十一分厂整机装配现场.jpg"
                alt="十一分厂整机装配现场"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2010"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                OPENING OF TECHNOLOGY CENTER IN JAPAN
              </p>
              <img
                src="/assets/2010_opening-technology-center.jpg"
                alt="2010_opening-technology-center"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2009"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                HAITIAN ROAD PRODUCTION BASE WAS PUT INTO PRODUCTION
              </p>
              <img src="/assets/2009-1.jpg" alt="2009-1" className="mt-5" />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2009"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                OPENING OF GERMAN ZHAFIR WORKSHOP
              </p>
              <img
                src="/assets/2009_opening-zhafir-workshop.jpg"
                alt="2009_opening-zhafir-workshop"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2009"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                ESTABLISHMENT OF TIANJIAN BRAND
              </p>
              <img
                src="/assets/14_establishment_TPM_2009.jpg"
                alt="14_establishment_TPM_2009"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2008"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                Grand Opening of Ningbo Zhafir Production Base
              </p>
              <img
                src="/assets/13_grand_opening_ningbo_zhafir_2008.jpg"
                alt="13_grand_opening_ningbo_zhafir_2008"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2008"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                Opening of Haitian International (East and South China)
                Technology Center
              </p>
              <img src="/assets/2008.jpg" alt="2008" className="mt-5" />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2007"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                Wuxi Haitian Machinery Co., Ltd. was founded
              </p>
              <img src="/assets/2007-1.jpg" alt="2007-1" className="mt-5" />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2007"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                Acquisition of the German Zhafir Plastics Machinery GMBH
              </p>
              <img src="/assets/2007-3.jpg" alt="2007-3" className="mt-5" />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2007"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                NAMED AS “CHINA TOP BRAND NAME”
              </p>
              <img src="/assets/2007-4-2.jpg" alt="2007-4-2" className="mt-5" />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2006"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                “National Post-doctoral Scientific Research Workstation” was
                established
              </p>
              <img src="/assets/2006.jpg" alt="2006" className="mt-5" />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2006"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                Haitian International Holdings Limited was successfully listed
                in Hong Kong
              </p>
              <img
                src="/assets/12_IPO_2006.jpg"
                alt="12_IPO_2006"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2006"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                Haitian Celebrates Its 40th Anniversary
              </p>
              <img
                src="/assets/2006_40.Geburtstag.jpg"
                alt="2006_40.Geburtstag"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2005"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                Establishment of Ningbo Haitian Huayuan Machinery Co., Ltd.
              </p>
              <img
                src="/assets/11_opening_huayuan_2005.jpg"
                alt="11_opening_huayuan_2005"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2005"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                RECOGNISED AS A NATIONAL TECHNOLOGY CENTRE
              </p>
              <img src="/assets/2018-1.jpg" alt="2018-1" className="mt-5" />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2005"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                NAMED AS A “MAJOR CHINESE BRAND FOR EXPORT SALES”
              </p>
              <img
                src="/assets/2005_major-brand-export.jpg"
                alt="2005_major-brand-export"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2005"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                Haitian Beihua Technology Co., Ltd. was founded
              </p>
              <img src="/assets/2005-2.jpg" alt="2005-2" className="mt-5" />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2004"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                Named as One of the “TOP 500” Chinese Machinery Manufacturers
              </p>
              <img src="/assets/2004_s.jpg" alt="2004_s" className="mt-5" />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2004"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                Opening of Haitian Brazil
              </p>
              <img
                src="/assets/10_haitian_brazil_2004.jpg"
                alt="10_haitian_brazil_2004"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2003"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                Haitian Precision’s first HT-850G gantry processing center
                passed identification
              </p>
              <img src="/assets/2003-2.jpg" alt="2003-2" className="mt-5" />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2003"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                Haitian was elected the president unit of China Plastics
                Machinery Industry Association
              </p>
              <img src="/assets/2003-1.jpg" alt="2003-1" className="mt-5" />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2002"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                Haitian Builds the Largest-Ever Asian Injection Molding Machine
                of Its Time, With 36,000 KN Clamping Force
              </p>
              <img
                src="/assets/9_3600Tmachine_2002.jpg"
                alt="9_3600Tmachine_2002"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2002"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                Ningbo Haitian Precision Machinery Co., Ltd. was founded
              </p>
              <img src="/assets/2002.jpg" alt="2002" className="mt-5" />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2001"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                Establishment of Haitian Turkey
              </p>
              <img
                src="/assets/8_establishment_of_haitian_turkey.jpg"
                alt="8_establishment_of_haitian_turkey"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2000"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                The trial production of the first generation two-plate plastic
                injection mould machine succeeded
              </p>
              <img src="/assets/HTK.jpg" alt="HTK" className="mt-5" />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="2000"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                Grand opening of Haitian Plastics Machinery’s factory in
                Guangzhou
              </p>
              <img
                src="/assets/7_opening_haitian_guangzhou_2000.jpg"
                alt="7_opening_haitian_guangzhou_2000"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="1999"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                First Energy-Saving Injection Molding Machine with Patent in
                China
              </p>
              <img
                src="/assets/6_first_energy_saving_IMM_in_CN_1999.jpg"
                alt="6_first_energy_saving_IMM_in_CN_1999"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="1998"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                Demag Haitian Machinery Co., Ltd., a joint venture with German
                businessmen, was founded
              </p>
              <img src="/assets/1998_1-1.jpg" alt="1998_1-1" className="mt-5" />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="1997"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                NAMED AS A FAMOUS-BRAND FOR MACHINERY INDUSTRY PRODUCTS
              </p>
              <img src="/assets/1997_s.jpg" alt="1997_s" className="mt-5" />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="1994"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                COMPREHENSIVE ECONOMIC INDICATORS RANKED THE FIRST IN THE
                COUNTRY FOR THE FIRST TIME
              </p>
              <img src="/assets/1994.jpg" alt="1994" className="mt-5" />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="1991"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                Start to construct the Jiangnan Road Plant
              </p>
              <img
                src="/assets/4_construction_of_Jiangnam_road_plant_1991.jpg"
                alt="4_construction_of_Jiangnam_road_plant_1991"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="1989"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                “NINGBO HAITIAN MACHINERY MANUFACTURING CO. LTD.” WAS FOUNDED
              </p>
              <img src="/assets/1989.jpg" alt="1989" className="mt-5" />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="1989"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                First Haitian Machine Was Exported
              </p>
              <img
                src="/assets/3_first_Export_machine_1989.jpg"
                alt="3_first_Export_machine_1989"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="1985"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                REGISTRATION OF THE BRAND NAME ‘HAITIAN’
              </p>
              <img
                src="/assets/1_HT_Logo_Registration_1985.jpg"
                alt="1_HT_Logo_Registration_1985"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="1973"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                DELIVERY OF THE FIRST INJECTION MOLDING MACHINE
              </p>
              <img
                src="/assets/1973_Delivery-of-the-first-Injection-molding-machine.jpg"
                alt="1973_Delivery-of-the-first-Injection-molding-machine"
                className="mt-5"
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work uppercase"
              contentStyle={{
                background: "transparent",
                color: "#001b36",
                padding: "0",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                border: "none",
              }}
              date="1966"
              iconStyle={{
                background: "#001b36",
                color: "#fff",
                height: "30px",
                width: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "1em",
                  marginTop: "0",
                  fontWeight: "600",
                }}
              >
                JIANGNAN AGRICULTURAL MACHINERY PLANT WAS FOUNDED (PREDECESSOR
                OF HAITIAN GROUP)
              </p>
              <img
                src="/assets/1966_Establishes-Haitian-in-Ningbo.jpg"
                alt="1966_Establishes-Haitian-in-Ningbo"
                className="mt-5"
              />
            </VerticalTimelineElement>
          </VerticalTimeline>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Milestones;
