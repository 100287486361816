import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

const Contact = () => {
  return (
    <div>
      <Header />
      <div className="font-sans absolute w-full">
        <div className="mt-[103px] md:mt-[152px] lg:mt-[158.5px] min-h-[40vw] lg:bg-contact lg:bg-cover lg:px-[6%]">
          <img
            src="/assets/group_contact_teaser_mobil.jpg"
            alt="group_contact_teaser_mobil"
            className="lg:hidden"
          />

          <h2 className="hidden lg:block text-white lg:pl-20 text-[45px] uppercase font-semibold leading-[1em] pt-[250px]">
            Contact
          </h2>
        </div>

        <div className="w-[92%] md:w-[80%] mx-auto pt-10 md:pt-9 text-left lg:hidden">
          <h3 className="text-[32px] text-[#001b36] uppercase font-semibold leading-[1em]">
            Contact
          </h3>
        </div>

        <div className="w-[92%] md:w-[80%] mx-auto pt-8 lg:pt-16">
          <div className="lg:flex lg:justify-between">
            <div className="md:flex md:justify-between md:pt-6 mr-[1rem]">
              <div className="border-b-[5px] border-b-[#d0a740] pb-[12px] md:w-[47%]">
                <img
                  src="/assets/HTI_Golden.png"
                  alt="hti_golden"
                  className="w-[55%] block m-auto"
                />
                <img
                  src="/assets/HTInternational.svg"
                  alt="htinternational"
                  className="w-[40%] mx-auto"
                />
              </div>

              <div className="border-b-[5px] border-b-[#d0a740] pb-[12px] md:w-[47%]">
                <img
                  src="/assets/Metal_Golden.png"
                  alt="metal_golden"
                  className="w-[55%] block m-auto"
                />
                <img
                  src="/assets/haitian-precision-logo.svg"
                  alt="haitian-precision"
                  className="w-[40%] mx-auto"
                />
              </div>
            </div>

            <div className="md:flex md:justify-between md:pt-6 mr-[1rem]">
              <div className="border-b-[5px] border-b-[#d0a740] pb-[12px] md:w-[47%]">
                <img
                  src="/assets/Drives_Golden.png"
                  alt="drives_golden"
                  className="w-[55%] block m-auto"
                />
                <img
                  src="/assets/haitian-drive-svg.svg"
                  alt="haitian-drive"
                  className="w-[40%] mx-auto"
                />
              </div>

              <div className="border-b-[5px] border-b-[#d0a740] pb-[12px] md:w-[47%]">
                <img
                  src="/assets/DieCasting_Golden.png"
                  alt="diecasting_golden"
                  className="w-[55%] block m-auto"
                />
                <img
                  src="/assets/Diecasting.svg"
                  alt="diecasting"
                  className="w-[40%] mx-auto"
                />
              </div>
            </div>

            <div className="border-b-[5px] border-b-[#d0a740] pb-[12px] md:w-[47%] xl:w-[38%] lg:pt-6">
              <img
                src="/assets/SmartWorld_Golden.png"
                alt="smartworld_golden"
                className="w-[55%] block m-auto"
              />
              <img
                src="/assets/SmartSolutions.svg"
                alt="smartsolutions"
                className="w-[40%] mx-auto"
              />
            </div>
          </div>
        </div>

        <div className="w-[92%] md:w-[80%] mx-auto pt-4 text-[rgba(0,0,0,0.7)] text-center mt-10">
          <p className="text-[22px] mb-2">97 E Brokaw Rd, San Jose, CA 95112</p>
          <p className="text-[20px] mb-2">Phone: +1 (442) 259-4130</p>
          <p>
            <a
              href="mailto:contact@thehaitiangroup.com"
              className="text-[#2ea3f2] text-[18px]"
            >
              contact@thehaitiangroup.com
            </a>
          </p>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default Contact;
