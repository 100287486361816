import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import image from "../assets/brochure-img.png";
import pdf from "../assets/Brochure.pdf";

const Newsroom = () => {
  return (
    <div>
      <Header />
      <div className="font-sans absolute w-full">
        <div className="mt-[103px] md:mt-[152px] lg:mt-[158.5px] min-h-[40vw]">
          <div className="bg-newsroom bg-cover bg-center h-[200px] md:h-[300px] lg:h-[400px]" />
        </div>

        <div className="w-[92%] md:w-[80%] mx-auto pt-8 md:pt-12 xl:pt-0 text-left">
          <h1 className="text-[rgba(20,58,132,0.7)] text-[24px]">NEWSROOM</h1>
          <h2 className="relative pb-[7px] uppercase text-[16px] md:text-[32px] text-[#143a84] font-semibold after:content-[''] after:w-[70px] after:h-[4px] after:absolute after:left-0 after:ml-0 after:bottom-[-10px] after:bg-[#cdcdcd]">
            Brochure
          </h2>

          <img
            src="/assets/haitian-group-logo.svg"
            alt="logo"
            className="w-[48%] lg:w-[35%] xl:w-[20%] mt-16"
          />

          <div>
            <img
              src={image}
              alt="brochure"
              className="w-full md:w-[80%] lg:w-[60%] mt-8 mb-4"
            />
          </div>
          <a
            href={pdf}
            target="_blank"
            rel="noreferrer"
            className="text-[rgba(20,58,132,0.7)] xl:text-xl"
          >
            VIEW
          </a>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Newsroom;
