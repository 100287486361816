import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";

const HaitianChint = () => {
  return (
    <div>
      <Header />
      <div className="font-sans absolute w-full">
        <div className="mt-[153px] md:mt-[202px] lg:mt-[208.5px] w-[92%] md:w-[80%] mx-auto pt-16 pb-10 border-[0.75pt] border-[rgba(0,0,0,0.4)]">
          <h1 className="text-center text-[#d0a740] uppercase text-[23px] font-semibold leading-[50px] px-[25px]">
            New developments and European premieres at K 2022
          </h1>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] text-center mt-3">
            2022-08-05
          </p>
          <img
            src="/assets/hti-china-headquarter-1024x576-1.jpg"
            alt="hti-china-headquarter"
            className="w-full my-[25px]"
          />
          <h2 className="px-[25px] text-[#001b36] text-[18px] leading-[22px] uppercase mt-16">
            A bigger booth, more exhibits, more room for smart solutions: At its
            almost 700 sqm booth in Hall 15/A57, the global market leader will
            showcase the benchmarks of its broad technology spectrum for a wide
            range of applications in key sectors of the plastics processing
            industries. Besides two European machine premieres, Haitian
            International will also present new developments and strategies
            within the Haitian Group at K 2022.
          </h2>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] mt-8 px-[25px]">
            Under the motto “Smart Technologies – Flexible Integration –
            Sustainable Solutions,” Haitian International is clearly positioning
            its Zhafir and Haitian brands for the future and, together with
            various partners, will showcase live applications from different
            industries and application areas. Haitian International will present
            a total of five exhibits at K 2022. The broad portfolio of electric
            series from Zhafir Plastics Machinery – whether as an all-electric
            solution, with integrated hydraulics or as a hybrid solution – will
            be represented by three machines.
          </p>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] mt-5 pb-3.5 px-[25px]">
            In addition, Haitian International is communicating the synergies
            within the Haitian Group. Haitian Smart Solutions, also a member of
            the Haitian Group, will be presented for the first time in
            Düsseldorf at a European trade show as an accomplished integration
            partner.
          </p>

          <h3 className="px-[25px] text-[#001b36] text-[16px] leading-[22px] uppercase mt-6">
            New Technologies
          </h3>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] mt-3 pb-3.5 px-[25px]">
            While the current machine portfolio already covers almost all
            requirements in the plastics processing industry, Haitian
            International is pushing ahead with new developments of its machine
            series. In addition to two European machine premieres showcasing the
            wide range of applications for Haitian machines, Haitian
            International will be exhibiting for the first time intelligent
            machine functions that enable plastics processors to achieve
            additional energy savings and more efficient processes. For
            instance, an intelligent energy management function. Furthermore,
            the company will introduce other features such as intelligent
            process optimization, plasticizing efficiency or clamping force
            optimization and thereby give an outlook to the next machine
            generation. Haitian International’s declared goal: To offer smart
            features as a standard feature at no extra charge.
          </p>

          <h3 className="px-[25px] text-[#001b36] text-[16px] leading-[22px] uppercase mt-6">
            Flexible Integration
          </h3>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] mt-3 pb-3.5 px-[25px]">
            Standardized interfaces and open system integration are the
            fundamentals for maximum flexibility in terms of connectivity. Thus,
            Zhafir and Haitian machines offer processors almost unlimited
            freedom in their choice of integration partners. Accordingly,
            several system partners will be integrated into the machine concepts
            at K 2022. In the MES area, in addition to Haitian Smart Solutions’
            own “GoFactory”, another MES partner will be presented specifically
            for the European market. All 5 exhibits will be connected to both
            systems.
          </p>

          <h3 className="px-[25px] text-[#001b36] text-[16px] leading-[22px] uppercase mt-6">
            Industry-oriented, sustainable solutions
          </h3>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] mt-3 pb-2 px-[25px]">
            Energy efficiency has always been a key aspect in the development of
            all Haitian machines. In this regard, sister company Haitian Drive
            Systems confirms its value as a co-innovation driver by developing
            and providing energy-efficient drives. Since 2006, these are used as
            standard in all machine series of the Zhafir and Haitian brands.
          </p>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] mt-3 pb-3.5 px-[25px]">
            In the future, Haitian will intensify the development of
            industry-oriented and sustainable solutions. With the European
            premier of the Haitian Mars K Series, Haitian shows a machine not
            only ready for fast cycles, but also an effective solution for the
            processing of recycles and sustainable materials.
          </p>

          <h3 className="px-[25px] text-[#001b36] text-[16px] leading-[22px] uppercase mt-6">
            The K2022 exhibits at a glance
          </h3>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] mt-3 pb-2 px-[25px]">
            <strong>Medical: </strong> An electric Zhafir Zeres Med Series with
            1,200 kN and medical package, will demonstrate an economical
            cleanroom solution made from PP with a laminar flowbox from Max
            Petek.
          </p>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] mt-3 pb-2 px-[25px]">
            <strong>Commodity: </strong> An electric Zhafir Zeres Multi Series
            with 3,000 kN, presented as an R-version (piggyback) will be
            integrated into a production cell with in-house robotics of the
            Hilectro brand. The ZE-M produces a tape measure housing made from
            ABS/TPE.
          </p>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] mt-3 pb-2 px-[25px]">
            <strong>Automotive: </strong> The electric hybrid Zhafir Jenius
            Series with 3,600 kN, is producing an automotive part that is
            removed with a “Success 33” from Sepro.
          </p>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] mt-3 pb-2 px-[25px]">
            <strong>Logistic: </strong> A servo-hydraulic Haitian Jupiter with
            two-platen technology and 4,500 kN produces a fruit box made from a
            material compound HDPE and TetraPak recyclate. The application is
            integrated into a manufacturing cell with in-house Hilectro
            robotics.
          </p>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] mt-3 pb-2 px-[25px]">
            <strong>Packaging: </strong> The servo-hydraulic Haitian Mars K
            Series with high injection performance of up to 1,000 mm/s is
            celebrating its premiere in Europe. It will produce a food container
            with IML, integrated into an automation system from Hilectro.
          </p>

          <h3 className="px-[25px] text-[#001b36] text-[18px] leading-[22px] uppercase mt-6">
            HAITIAN INTERNATIONAL AT K-SHOW: HALL 15 / STAND A57
          </h3>
          <section className="lg:flex lg:justify-between lg:mt-8">
            <p className="text-[rgba(0,0,0,0.7)] text-[14px] px-[25px]">
              <img
                src="/assets/Zhafir_ZE-M_frontview.png"
                alt="Zhafir_ZE-M_frontview"
                className="w-full"
              />
              <em>
                Zeres M Series – the electrical multi component solution in
                R-version
              </em>
            </p>
            <p className="text-[rgba(0,0,0,0.7)] text-[14px] px-[25px] mt-5">
              <img
                src="/assets/Haitian_MA_K_frontview.png"
                alt="Haitian_MA_K_frontview"
                className="w-full"
              />
              <em>
                Haitian Mars K Series – the servo-hydraulic solution with
                injection speed up to 1000 mm/s
              </em>
            </p>
          </section>
        </div>

        <div className="mt-10 w-[92%] md:w-[80%] mx-auto border-[0.75pt] border-[rgba(0,0,0,0.4)] p-[25px]">
          <h2 className="text-[#d0a740] text-[18px] font-bold">
            RELATED POSTS
          </h2>
          <div className="xl:flex xl:justify-between">
            <div className="md:flex md:justify-between xl:w-[65%]">
              <div className="md:w-[48%]">
                <Link to="/haitian-chint">
                  <img
                    src="/assets/zhengtai_home-648x250.jpg"
                    alt="hti-china-headquarter"
                    className="my-4 min-h-[150px] object-cover w-full"
                  />
                  <p className="text-[#d0a740] text-[14px] font-bold uppercase">
                    Haitian &amp; Chint｜Thematic Exchange Meeting – Grow
                    together with Customers
                  </p>
                  <p className="text-[rgba(0,0,0,0.6)] text-[14px] pb-5">
                    2022-08-31
                  </p>
                </Link>
              </div>

              <div className="md:w-[48%]">
                <Link to="/tour">
                  <img
                    src="/assets/germany-open-house_home-648x250.jpg"
                    alt="germany-open-house_home"
                    className="my-4 min-h-[150px] object-cover w-full"
                  />
                  <p className="text-[#d0a740] text-[14px] font-bold uppercase">
                    A Tour in Haitian International Germany Open House
                  </p>
                  <p className="text-[rgba(0,0,0,0.6)] text-[14px] pb-5">
                    2022-07-27
                  </p>
                </Link>
              </div>
            </div>

            <div className="xl:w-[33%]">
              <Link to="/haitian-eastern">
                <img
                  src="/assets/huanan_1-648x250.jpg"
                  alt="huanan_1"
                  className="my-4 min-h-[150px] object-cover w-full"
                />
                <p className="text-[#d0a740] text-[14px] font-bold uppercase">
                  HAITIAN EASTERN OPERATION CENTER SOUTHEAST CHINA SETS SAIL
                </p>
                <p className="text-[rgba(0,0,0,0.6)] text-[14px]">2022-07-22</p>
              </Link>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default HaitianChint;
