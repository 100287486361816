import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import europe from "../assets/Haitian-International-Germany.jpeg";
import turkey from "../assets/Haitian-International-Turkey.jpeg";
import brasil from "../assets/Haitian-International-Brazil.jpeg";
import vietnam from "../assets/Haitian-International-Vietnam.jpeg";
import mexico from "../assets/Haitian-International-New-Mexico.jpeg";
import thailand from "../assets/Haitian-International-Thailand.jpeg";
import india from "../assets/Haitian-International-India.jpeg";
import indonesia from "../assets/Haitian-International-Indonesia.jpeg";
import japan from "../assets/Haitian-International-Japan.jpeg";

const Regional = () => {
  return (
    <div>
      <Header />
      <div className="font-sans absolute w-full">
        <div className="mt-[103px] md:mt-[152px] lg:mt-[158.5px] min-h-[40vw]">
          <div className="bg-manufacturing bg-cover bg-center h-[200px] md:h-[300px] lg:h-[400px]" />
        </div>

        <div className="w-[92%] md:w-[80%] mx-auto pt-8 md:pt-12 xl:pt-0 text-left">
          <h1 className="text-[rgba(20,58,132,0.7)] text-[15px] md:text-[24px]">
            OUR GLOBAL PRESENCE
          </h1>
          <h2 className="relative pb-[7px] text-[16px] md:text-[32px] text-[#143a84] font-semibold after:content-[''] after:w-[70px] after:h-[4px] after:absolute after:left-0 after:ml-0 after:bottom-[-10px] after:bg-[#cdcdcd]">
            REGIONAL SUBSIDIARIES
          </h2>
          <p className="text-[14px] text-[rgba(0,0,0,0.7)] mt-8 leading-[23.8px]">
            With our 9 worldwide Regional Offices coordinating and cooperating
            with about 60 sales and service partners in countries across the
            globe, Haitian International has a presence in all major world
            markets. Including manufacturing, assembling, research &amp;
            development as well as logistics, sales and service we are able to
            fulfill the often very heterogeneous market requirements. Through
            our regional concept we are able to provide individual customer
            solutions in the shortest time possible and can&nbsp;respond with
            approaches geared to the regional specifics of individual markets.
            Our premises:&nbsp;To create and extend advantage for our customers
            through the best price-performance ratio in the market.
          </p>
        </div>

        <div className="w-[92%] md:w-[80%] mx-auto pt-12 xl:pt-0 text-left text-[rgba(20,58,132,0.7)] lg:flex lg: justify-between lg:mt-6 xl:mt-16">
          <div className="lg:w-[31%]">
            <h4 className="text-[33px]">
              <strong>9</strong>
            </h4>
            <h4 className="text-[33px] mt-7">HEADQUARTERS</h4>
            <p className="text-[14px] text-[rgba(0,0,0,0.7)] mt-5 leading-[23.8px] uppercase">
              Strategically positioned to cover all areas
            </p>
          </div>

          <div className="mt-12 lg:mt-0 lg:w-[31%]">
            <h4 className="text-[33px]">
              <strong>60</strong>
            </h4>
            <h4 className="text-[33px] mt-7">PARTNERS</h4>
            <p className="text-[14px] text-[rgba(0,0,0,0.7)] mt-5 leading-[23.8px] uppercase">
              In over 60 countries and regions worldwide
            </p>
          </div>

          <div className="mt-12 lg:mt-0 lg:w-[31%]">
            <h4 className="text-[33px]">
              <strong>30,000 +</strong>
            </h4>
            <h4 className="text-[33px] mt-7">CUSTOMERS</h4>
            <p className="text-[14px] text-[rgba(0,0,0,0.7)] mt-5 leading-[23.8px] uppercase">
              Across 130 countries
            </p>
          </div>
        </div>

        <div className="regional mt-24 mb-6 p-4 md:py-12 md:px-32 lg:px-28">
          <div className="lg:flex lg:justify-between lg:mt-8">
            <div className="lg:w-[30%]">
              <img src={europe} alt="europe" />
              <p className="text-white text-[14px] mt-5">In Europe</p>
              <p className="text-[#00ccff] text-[18px]">
                Haitian International Germany
              </p>
            </div>
            <div className="mt-8 lg:mt-0 lg:w-[30%]">
              <img src={turkey} alt="turkey" />
              <p className="text-white text-[14px] mt-5">In Middle East</p>
              <p className="text-[#00ccff] text-[18px]">Haitian Turkey</p>
            </div>
            <div className="mt-8 lg:mt-0 lg:w-[30%]">
              <img src={brasil} alt="brasil" />
              <p className="text-white text-[14px] mt-5">In South America</p>
              <p className="text-[#00ccff] text-[18px]">Haitian Brasil</p>
            </div>
          </div>

          <div className="mt-8 lg:flex lg:justify-between">
            <div className="lg:w-[30%]">
              <img src={vietnam} alt="vietnam" />
              <p className="text-white text-[14px] mt-5">In Southeast Asia</p>
              <p className="text-[#00ccff] text-[18px]">Haitian Vietnam</p>
            </div>
            <div className="mt-8 lg:mt-0 lg:w-[30%]">
              <img src={thailand} alt="thailand" />
              <p className="text-white text-[14px] mt-5">In Southeast Asia</p>
              <p className="text-[#00ccff] text-[18px]">Haitian Thailand</p>
            </div>
            <div className="mt-8 lg:mt-0 lg:w-[30%]">
              <img src={mexico} alt="mexico" />
              <p className="text-white text-[14px] mt-5">North America</p>
              <p className="text-[#00ccff] text-[18px]">Haitian Mexico</p>
            </div>
          </div>

          <div className="my-8 lg:flex lg:justify-between">
            <div className="lg:w-[30%]">
              <img src={india} alt="india" />
              <p className="text-white text-[14px] mt-5">In South Asia</p>
              <p className="text-[#00ccff] text-[18px]">Haitian India</p>
            </div>
            <div className="mt-8 lg:mt-0 lg:w-[30%]">
              <img src={indonesia} alt="indonesia" />
              <p className="text-white text-[14px] mt-5">In Southeast Asia</p>
              <p className="text-[#00ccff] text-[18px]">Haitian Indonesia</p>
            </div>
            <div className="mt-8 lg:mt-0 lg:w-[30%]">
              <img src={japan} alt="japan" />
              <p className="text-white text-[14px] mt-5">In Asia</p>
              <p className="text-[#00ccff] text-[18px]">Haitian Japan</p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Regional;
