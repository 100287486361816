import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineMinus } from "react-icons/ai";
import smartOne from "../assets/Smart1.png";
import process from "../assets/Smart21.png";
import injection from "../assets/Smart2-2.png";
import packaging from "../assets/Smart4.png";
import info from "../assets/Smart5.png";
import concept from "../assets/28d32288-43c7-11e8-84cf-008cfa044124.png";
import service from "../assets/SMART-SOLUTIONS_en.png";

const SmartSolutions = () => {
  const [prepOpen, setPrepOpen] = useState(false);
  const [processOpen, setProcessOpen] = useState(false);
  const [injectionOpen, setInjectionOpen] = useState(false);
  const [packagingOpen, setPackagingOpen] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);

  const togglePrep = () => {
    setPrepOpen(!prepOpen);
  };

  const toggleProcess = () => {
    setProcessOpen(!processOpen);
  };

  const toggleInjection = () => {
    setInjectionOpen(!injectionOpen);
  };

  const togglePackaging = () => {
    setPackagingOpen(!packagingOpen);
  };

  const toggleInfo = () => {
    setInfoOpen(!infoOpen);
  };

  return (
    <div>
      <Header />
      <div className="font-sans absolute w-full">
        <div className="mt-[103px] md:mt-[152px] lg:mt-[158.5px] min-h-[40vw]">
          <div className="bg-solutions bg-cover bg-center h-[200px] md:h-[300px] lg:h-[400px]" />
        </div>

        <div className="w-[92%] md:w-[80%] mx-auto text-left pt-8 md:pt-12 xl:pt-0 ">
          <h1 className="text-[rgba(20,58,132,0.7)] text-[15px] md:text-[24px]">
            SMART SOLUTIONS
          </h1>
          <h2 className="relative pb-[7px] text-[16px] md:text-[32px] text-[#143a84] font-semibold after:content-[''] after:w-[70px] after:h-[4px] after:absolute after:left-0 after:ml-0 after:bottom-[-10px] after:bg-[#cdcdcd]">
            5 MODULES FOR MORE PRODUCTION EFFICIENCY
          </h2>
          <p className="text-[14px] text-[rgba(0,0,0,0.7)] mt-8 leading-[23.8px]">
            With five basic modular components Haitian Smart offers a whole
            series of solutions in the field of intelligent manufacturing, from
            process reliability and product processing to sorting &amp;
            logistics and monitoring. Through intelligent control of the flow of
            materials, energy, goods, and information in the operating and
            manufacturing processes, production efficiency is increased
            significantly and sustainably.
          </p>

          <div className="mt-8 xl:mt-16 text-center md:flex md:justify-between md:flex-wrap xl:px-[100px]">
            <div className="md:w-[31%] xl:w-[18%]">
              <img
                src={smartOne}
                alt="Preparation & set-up"
                className="w-[60%] mx-auto md:w-auto"
              />
              <h5 className="text-[#143a84] font-bold">PREPARATION & SET-UP</h5>
              <button onClick={togglePrep}>
                {!prepOpen ? (
                  <AiOutlinePlus className="mx-auto text-[#143a84] text-xl mt-2" />
                ) : (
                  <AiOutlineMinus className="mx-auto text-[#143a84] text-xl mt-2" />
                )}
              </button>
              {prepOpen && (
                <ul className="list-disc text-left text-[14px] pt-[10px] text-[#666]">
                  <li>Central material feed</li>
                  <li>Pretreatment of materials</li>
                  <li>Shredding &amp; mixing</li>
                  <li>Mold warm-up</li>
                  <li>Plugs in &amp; out automatically</li>
                  <li>Automatic mold change</li>
                </ul>
              )}
            </div>

            <div className="md:w-[31%] xl:w-[18%]">
              <img
                src={process}
                alt="Process reliability"
                className="w-[60%] mx-auto md:w-auto"
              />
              <h5 className="text-[#143a84] font-bold">PROCESS RELIABILITY</h5>
              <button onClick={toggleProcess}>
                {!processOpen ? (
                  <AiOutlinePlus className="mx-auto text-[#143a84] text-xl mt-2" />
                ) : (
                  <AiOutlineMinus className="mx-auto text-[#143a84] text-xl mt-2" />
                )}
              </button>
              {processOpen && (
                <ul className="list-disc text-left text-[14px] pt-[10px] text-[#666]">
                  <li>Mold temperature</li>
                  <li>Sequence valves</li>
                  <li>Hot runner systems</li>
                  <li>Displays for compressed air and water levels</li>
                  <li>Coolant control</li>
                  <li>Monitoring of mold interior</li>
                </ul>
              )}
            </div>

            <div className="md:w-[31%] xl:w-[18%]">
              <img
                src={injection}
                alt="Injection molding & assembly"
                className="w-[60%] mx-auto md:w-auto"
              />
              <h5 className="text-[#143a84] font-bold">
                INJECTION MOLDING & ASSEMBLY
              </h5>
              <button onClick={toggleInjection}>
                {!injectionOpen ? (
                  <AiOutlinePlus className="mx-auto text-[#143a84] text-xl mt-2" />
                ) : (
                  <AiOutlineMinus className="mx-auto text-[#143a84] text-xl mt-2" />
                )}
              </button>
              {injectionOpen && (
                <ul className="list-disc text-left text-[14px] pt-[10px] text-[#666]">
                  <li>Pick &amp; place</li>
                  <li>Sprue picker</li>
                  <li>Flame treatment</li>
                  <li>Die cutting</li>
                  <li>Coding &amp; labeling</li>
                </ul>
              )}
            </div>

            <div className="md:w-[31%] xl:w-[18%]">
              <img
                src={packaging}
                alt="Packaging & Logistics"
                className="w-[60%] mx-auto md:w-auto"
              />
              <h5 className="text-[#143a84] font-bold">
                PACKAGING & LOGISTICS
              </h5>
              <button onClick={togglePackaging}>
                {!packagingOpen ? (
                  <AiOutlinePlus className="mx-auto text-[#143a84] text-xl mt-2" />
                ) : (
                  <AiOutlineMinus className="mx-auto text-[#143a84] text-xl mt-2" />
                )}
              </button>
              {packagingOpen && (
                <ul className="list-disc text-left text-[14px] pt-[10px] text-[#666]">
                  <li>Conveyor-belt systems</li>
                  <li>Sorting and stacking</li>
                  <li>Packaging</li>
                  <li>AGV systems (driver-less transport)</li>
                </ul>
              )}
            </div>

            <div className="md:w-[31%] xl:w-[18%]">
              <img
                src={info}
                alt="Process information"
                className="w-[60%] mx-auto md:w-auto"
              />
              <h5 className="text-[#143a84] font-bold">PROCESS INFORMATION</h5>
              <button onClick={toggleInfo}>
                {!infoOpen ? (
                  <AiOutlinePlus className="mx-auto text-[#143a84] text-xl mt-2" />
                ) : (
                  <AiOutlineMinus className="mx-auto text-[#143a84] text-xl mt-2" />
                )}
              </button>
              {infoOpen && (
                <ul className="list-disc text-left text-[14px] pt-[10px] text-[#666]">
                  <li>Machine monitoring</li>
                  <li>Maintenance management</li>
                  <li>Quality management</li>
                  <li>Process management</li>
                  <li>Production management</li>
                  <li>Interface integration</li>
                  <li>Large real-time screens and display board</li>
                  <li>Cloud platform</li>
                  <li>Local client, mobile client, off-site client</li>
                  <li>Periphery interfaces 1-4</li>
                  <li>BUS systems</li>
                </ul>
              )}
            </div>
          </div>
        </div>

        <div className="w-[92%] md:w-[80%] mx-auto text-left pt-12 xl:pt-0 ">
          <h2 className="relative pb-[7px] text-[16px] md:text-[32px] text-[#143a84] font-semibold after:content-[''] after:w-[70px] after:h-[4px] after:absolute after:left-0 after:ml-0 after:bottom-[-10px] after:bg-[#cdcdcd]">
            THE BASIC CONCEPT AT A GLACE
          </h2>
        </div>

        <div className="bg-[#efefef] mt-12 py-5 xl:py-8">
          <div className="w-[92%] md:w-[80%] mx-auto lg:flex lg:justify-between">
            <img src={concept} alt="concept" className="w-full lg:w-[48%]" />
            <section className="mt-8 lg:mt-0 lg:w-[48%]">
              <h3 className="text-[rgba(20,58,132,0.7)]">
                <strong>SUPPORT:</strong>
              </h3>
              <p className="text-[14px] leading-[23.8px] text-[rgba(0,0,0,0.7)] mt-2 border-b border-b-[#cdcdcd]">
                Overlapping platform and internet-based remote support with
                cloud technology
              </p>

              <h3 className="text-[rgba(20,58,132,0.7)] mt-7 uppercase">
                <strong>Process reliability:</strong>
              </h3>
              <p className="text-[14px] leading-[23.8px] text-[rgba(0,0,0,0.7)] mt-2 border-b border-b-[#cdcdcd]">
                Process monitoring and machine management across all plants
              </p>

              <h3 className="text-[rgba(20,58,132,0.7)] mt-7 uppercase">
                <strong>Machines &amp; equipment:</strong>
              </h3>
              <p className="text-[14px] leading-[23.8px] text-[rgba(0,0,0,0.7)] mt-2 border-b border-b-[#cdcdcd]">
                System integration and automation solutions
              </p>

              <h3 className="text-[rgba(20,58,132,0.7)] mt-7 uppercase">
                <strong>Control technology:</strong>
              </h3>
              <p className="text-[14px] leading-[23.8px] text-[rgba(0,0,0,0.7)] mt-2">
                Flexible controls with the standard interfaces on open system
                platforms
              </p>
              <p className="text-[14px] leading-[23.8px] text-[rgba(0,0,0,0.7)] mt-2 border-b border-b-[#cdcdcd]">
                Ningbo Haitian Smart Manufacturing Technology Co. Ltd.
                understands the concept of smart manufacturing as a vision for
                shaping the future and sees the synergy of multi-technology as a
                business philosophy in order to create value for customers.
                Following the industry trend in development we offer our
                customers solutions based on market-demand over the entire
                life-cycle of injection-molded plastic products.
              </p>
            </section>
          </div>
        </div>

        <div className="w-[92%] md:w-[80%] mx-auto text-left pt-12">
          <h2 className="relative pb-[7px] text-[16px] md:text-[32px] text-[#143a84] font-semibold after:content-[''] after:w-[70px] after:h-[4px] after:absolute after:left-0 after:ml-0 after:bottom-[-10px] after:bg-[#cdcdcd]">
            OUR ENTIRE SERVICE
          </h2>
          <img src={service} alt="service" className="w-full mt-12" />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default SmartSolutions;
