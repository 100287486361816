import React, { useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { AiFillMinusCircle } from "react-icons/ai";

const Portfolios = () => {
  const [plasticsDropdownOpen, setPlasticsDropdownOpen] = useState(false);
  const [metalDropdownOpen, setMetalDropdownOpen] = useState(false);
  const [drivesDropdownOpen, setDrivesDropdownOpen] = useState(false);
  const [dieCastingDropdownOpen, setDieCastingDropdownOpen] = useState(false);
  const [smartDropdownOpen, setSmartDropdownOpen] = useState(false);

  const togglePlasticsDropdown = () => {
    setPlasticsDropdownOpen((prevState) => !prevState);
  };

  const toggleMetalDropdown = () => {
    setMetalDropdownOpen((prevState) => !prevState);
  };

  const toggleDrivesDropdown = () => {
    setDrivesDropdownOpen((prevState) => !prevState);
  };

  const toggleDieCastingDropdown = () => {
    setDieCastingDropdownOpen((prevState) => !prevState);
  };

  const toggleSmartDropdown = () => {
    setSmartDropdownOpen((prevState) => !prevState);
  };

  return (
    <div>
      <Header selected="Portfolios" />
      <div className="font-sans absolute w-full">
        <div className="mt-[103px] md:mt-[152px] lg:mt-[158.5px] min-h-[40vw] lg:bg-portfolio lg:bg-cover lg:px-[10%]">
          <img
            src="/assets/group_portfolio_teaser_mobil.jpg"
            alt="hero-social"
            className="lg:hidden"
          />
          <h1 className="hidden lg:block lg:pl-16 text-white text-[24px] uppercase font-semibold leading-[1em] pt-[180px] lg:pt-[220px]">
            THE HAITIAN GROUP
          </h1>
          <h2 className="hidden lg:block text-white lg:pl-16 text-[45px] uppercase font-semibold lg:leading-[36px] mt-2 xl:mt-4">
            FOCUS AND EXPANSION
            <br />
            THROUGH DIVERSIFICATION.
          </h2>
        </div>
        <div className="h-[30px] bg-[#E4BE70]" />

        <div className="w-[92%] md:w-[80%] mx-auto pt-10 md:pt-16 text-left">
          <h2 className="font-medium text-[20px] md:text-[24px] mb-2.5 text-[#001b36] uppercase lg:hidden">
            The Haitian Group
          </h2>
          <h3 className="text-[32px] text-[#001b36] uppercase font-semibold leading-[1em] lg:hidden">
            FOCUS AND EXPANSION
            <br />
            THROUGH DIVERSIFICATION.
          </h3>
        </div>

        <div className="w-[92%] md:w-[80%] mx-auto pt-20 pb-12 text-left text-[14px]">
          <div className="border-t-[#d9d9d9] border-t-[1px] border-b-[#d9d9d9] border-b-[1px] py-[20px]">
            <div>
              <div
                onClick={togglePlasticsDropdown}
                className="flex justify-between items-center cursor-pointer"
              >
                <section>
                  <h4 className="text-[#142f6c]">WORLD OF PLASTICS</h4>
                  <h5 className="text-[#d0a740]">HAITIAN INTERNATIONAL</h5>
                </section>
                {plasticsDropdownOpen ? (
                  <AiFillMinusCircle className="h-[16px] w-[16px] text-[#ccc]" />
                ) : (
                  <BsFillPlusCircleFill className="h-[16px] w-[16px] text-[#ccc]" />
                )}
              </div>
              {plasticsDropdownOpen ? (
                <section className="relative top-0 duration-500 ease-in transition-[top] lg:flex lg:justify-between lg:py-10">
                  <p className="text-[rgba(0,0,0,0.7)] leading-[23.8px] pt-6 lg:w-[45%] lg:p-0">
                    Haitian International is specialized in the manufacturing
                    the precise, efficient, energy saving and eco-friendly
                    injection molding machines, and currently is possessing the
                    injection molding machine production base with highest
                    production and sales volume in the world and advanced
                    technology at home. In 2006, Haitian International was
                    successfully listed in Hong Kong Stock Exchange. Haitian
                    International strengthens the enterprise with technology
                    with three brands „Zhafir“ and „Haitian“ which face the
                    high, middle and low end market respectively. At present,
                    the economic index of Haitian International ranks leading in
                    the industry all around the world, and the products show the
                    highest level of the Chinese injection molding machine.
                  </p>
                  <img
                    src="/assets/group_portfolio_world-of-plastics-01.jpg"
                    alt="World of plastics"
                    className="pt-6 pb-4 lg:p-0 lg:w-[45%]"
                  />
                </section>
              ) : (
                <section className="relative top-[-30px] duration-500 ease-in " />
              )}
            </div>
          </div>

          <div className="border-b-[#d9d9d9] border-b-[1px] py-[20px]">
            <div>
              <div
                onClick={toggleMetalDropdown}
                className="flex justify-between items-center cursor-pointer"
              >
                <section>
                  <h4 className="text-[#86200f]">WORLD OF METAL</h4>
                  <h5 className="text-[#d0a740]">HAITIAN PRECISION</h5>
                </section>
                {metalDropdownOpen ? (
                  <AiFillMinusCircle className="h-[16px] w-[16px] text-[#ccc]" />
                ) : (
                  <BsFillPlusCircleFill className="h-[16px] w-[16px] text-[#ccc]" />
                )}
              </div>
              {metalDropdownOpen ? (
                <section className="relative top-0 duration-500 ease-in transition-[top] lg:flex lg:justify-between lg:py-10">
                  <p className="text-[rgba(0,0,0,0.7)] leading-[23.8px] pt-6 lg:w-[45%] lg:p-0">
                    Haitian Precision was founded in 2002 and specializes in the
                    large, high-speed and precision numerical controlled
                    machinery. Currently, it has developed three production
                    bases including Dagang Precision, Yanshan Precision, and
                    Dalian Precision with products covering 6 series and dozens
                    of varieties and specifications including gantry machining
                    center, horizontal machining center, numerical controlled
                    cutting and milling center, vertical machining center,
                    numerical controlled large horizontal boring and milling
                    machine, numerical control vertical lathe, and so on.
                  </p>
                  <img
                    src="/assets/group_portfolio_Precision.jpg"
                    alt="World of metal"
                    className="pt-6 pb-4 lg:p-0 lg:w-[45%]"
                  />
                </section>
              ) : (
                <section className="relative top-[-30px] duration-500 ease-in " />
              )}
            </div>
          </div>

          <div className="border-b-[#d9d9d9] border-b-[1px] py-[20px]">
            <div>
              <div
                onClick={toggleDrivesDropdown}
                className="flex justify-between items-center cursor-pointer"
              >
                <section>
                  <h4 className="text-[#578324]">WORLD OF DRIVES</h4>
                  <h5 className="text-[#d0a740]">HAITIAN DRIVE SYSTEMS</h5>
                </section>
                {drivesDropdownOpen ? (
                  <AiFillMinusCircle className="h-[16px] w-[16px] text-[#ccc]" />
                ) : (
                  <BsFillPlusCircleFill className="h-[16px] w-[16px] text-[#ccc]" />
                )}
              </div>
              {drivesDropdownOpen ? (
                <section className="relative top-0 duration-500 ease-in transition-[top] lg:flex lg:justify-between lg:py-10">
                  <p className="text-[rgba(0,0,0,0.7)] leading-[23.8px] pt-6 lg:w-[45%] lg:p-0">
                    Haitian Drive Systems was established in 2006 and
                    specializes in the energy-saving, eco-friendly and new
                    energy technical products with efficient power, and the
                    business covers four fields namely control technology,
                    automatic technology, new energy technology, hydraulic
                    driving system with RD and manufacturing of over 10 products
                    such as AC servo drive system, servo manipulator, new energy
                    vehicle, and hydraulic drive with several technical patents.
                    In addition, Haitian Drive Systems is undertaking the RD and
                    manufacturing of the state-level projects, and the products
                    are able to completely substitute the imported products, and
                    have been widely applied in the industries including
                    machinery, electronics, automation, shipping, and logistics.
                  </p>
                  <img
                    src="/assets/group_portfolio_Drive-Systems.jpg"
                    alt="World of Drives"
                    className="pt-6 pb-4 lg:p-0 lg:w-[45%]"
                  />
                </section>
              ) : (
                <section className="relative top-[-30px] duration-500 ease-in " />
              )}
            </div>
          </div>

          <div className="border-b-[#d9d9d9] border-b-[1px] py-[20px]">
            <div>
              <div
                onClick={toggleDieCastingDropdown}
                className="flex justify-between items-center cursor-pointer"
              >
                <section>
                  <h4 className="text-[#956f0c]">WORLD OF DIE CASTING</h4>
                  <h5 className="text-[#d0a740]">HAITIAN DIE CASTING</h5>
                </section>
                {dieCastingDropdownOpen ? (
                  <AiFillMinusCircle className="h-[16px] w-[16px] text-[#ccc]" />
                ) : (
                  <BsFillPlusCircleFill className="h-[16px] w-[16px] text-[#ccc]" />
                )}
              </div>
              {dieCastingDropdownOpen ? (
                <section className="relative top-0 duration-500 ease-in transition-[top] lg:flex lg:justify-between lg:py-10">
                  <p className="text-[rgba(0,0,0,0.7)] leading-[23.8px] pt-6 lg:w-[45%] lg:p-0">
                    Haitian Die Casting formally came into operation in 2016.
                    Insisting on the idea of precision equipment manufacturing
                    and management, Haitian Precision specializes in various
                    series of die-casting machines. Continuing its tradition of
                    independent innovation, energy-saving, eco-friendly, and
                    sustainable development as well as serving the entire world,
                    with the industrial platform advantages of Haitian
                    International, Haitian Precision and Haitian Drive Systems,
                    Haitian Precision is contributing towards the progress of
                    the die-casting industry, and is providing customers with
                    die-casting complete set equipment and solutions with high
                    cost performance because of its advanced technology,
                    reliable quality, and efficient service, and serving clients
                    with the production capacity of producing the competitive
                    die-casting products.
                  </p>
                  <img
                    src="/assets/group_portfolio_Die-Casting.jpg"
                    alt="World of Die Casting"
                    className="pt-6 pb-4 lg:p-0 lg:w-[45%]"
                  />
                </section>
              ) : (
                <section className="relative top-[-30px] duration-500 ease-in " />
              )}
            </div>
          </div>

          <div className="border-b-[#d9d9d9] border-b-[1px] py-[20px]">
            <div>
              <div
                onClick={toggleSmartDropdown}
                className="flex justify-between items-center cursor-pointer"
              >
                <section>
                  <h4 className="text-[#5eabae]">WORLD OF SMART</h4>
                  <h5 className="text-[#d0a740]">HAITIAN SMART SOLUTIONS</h5>
                </section>
                {smartDropdownOpen ? (
                  <AiFillMinusCircle className="h-[16px] w-[16px] text-[#ccc]" />
                ) : (
                  <BsFillPlusCircleFill className="h-[16px] w-[16px] text-[#ccc]" />
                )}
              </div>
              {smartDropdownOpen ? (
                <section className="relative top-0 duration-500 ease-in transition-[top] lg:flex lg:justify-between lg:py-10">
                  <p className="text-[rgba(0,0,0,0.7)] leading-[23.8px] pt-6 lg:w-[45%] lg:p-0">
                    Haitian Smart Solutions was established based on Haitian
                    Group's profound manufacturing practice. Based on the deep
                    insight into the needs of the industry, we provide
                    manufacturing enterprises with a full-coverage intelligent
                    manufacturing and information technology solution for the
                    production process. Based on the manufacturing genetic,
                    Haitian Smart Solutions will adhere to the core concept of
                    "Technology Empowerment - Smartlink Future". The core
                    concept is to seamlessly connect and deeply integrate
                    industrial services such as automated production lines,
                    intelligent factory management systems, smart factory
                    solutions, supply collaboration, and informationization
                    units through an open and shared industrial Internet
                    platform. We provide fast access and cost-effective
                    customized solutions for informationization and intelligence
                    of manufacturing enterprises and partners to help customers
                    improve quality and efficiency, upgrade industrial
                    efficiency and jointly create a new paradigm of intelligent
                    factory.
                  </p>
                  <img
                    src="/assets/group_portfolio_world-of-smart-05.jpg"
                    alt="World of Smart"
                    className="pt-6 pb-4 lg:p-0 lg:w-[45%]"
                  />
                </section>
              ) : (
                <section className="relative top-[-30px] duration-500 ease-in " />
              )}
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default Portfolios;
