import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

const NotFound = () => {
  return (
    <div>
      <Header />
      <div className="font-sans absolute w-full">
        <div className="mt-[103px] md:mt-[152px] lg:mt-[158.5px] pt-[58px] max-w-[1240px] w-[80%] mx-auto">
          <h1 className="text-[46px] leading-[50px] text-[#333] font-semibold uppercase pb-[10px] md:mt-[46px]">
            No Results Found
          </h1>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] leading-[23.8px] pb-[28px]">
            The page you requested could not be found. Try refining your search,
            or use the navigation above to locate the post.
          </p>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default NotFound;
