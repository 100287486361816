import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";

const HaitianChint = () => {
  return (
    <div>
      <Header />
      <div className="font-sans absolute w-full">
        <div className="mt-[153px] md:mt-[202px] lg:mt-[208.5px] w-[92%] md:w-[80%] mx-auto pt-16 border-[0.75pt] border-[rgba(0,0,0,0.4)] p-[25px]">
          <h1 className="text-center text-[#d0a740] uppercase text-[23px] font-semibold leading-[50px] px-[25px]">
            Haitian &amp; Chint｜Thematic Exchange Meeting – Grow together with
            Customers
          </h1>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] text-center mt-3">
            2022-08-31
          </p>
          <img
            src="/assets/zhengtai_home.jpg"
            alt="zhengtai_home"
            className="w-full my-[25px]"
          />
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] mt-3">
            Chint Group, founded in 1984, is a world-renowned intelligent energy
            system solution provider. Under the new wave of industrial
            digitization, grasping the new development opportunities of digital
            intelligence and carbon peaking and carbon neutrality goals, Chint
            has continuously deepened the transformation and upgrading strategy.
            In order to scientifically and steadily promote the implementation
            of the digital intelligence development strategy, Chint will
            continue to build a comprehensive, high-quality highly skilled
            talent team.
          </p>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] mt-3 pb-3.5">
            On July 21, 2022 – the opening month of Haitian factory – a special
            visitor, Zhejiang Chint Electrics Co., Ltd. (hereinafter referred to
            as “Chint”), a subsidiary of Chint Group, came to Haitian
            headquarters for a special customer exchange meeting. In this
            exchange meeting, adhering to the concept of sincere service and
            focusing on the actual needs of Chint, Haitian committed to the “one
            plan for one enterprise” strategy to create zero distance
            communication and services, and realizing the upgrading of
            cooperation between the two parties.
          </p>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] mt-3 pb-3.5">
            Through continuous communication with customers in the early stage,
            Haitian deeply explored the demands of Chint and customized the
            content of this exchange session, including process technology,
            equipment operation and maintenance, lean production, production
            management, and digital construction. During the factory tour, the
            rich exhibition solutions and one-stop solutions for injection
            molding workshops left a deep impression on the guests.
          </p>
          <img src="/assets/zhengtai_1.jpg" alt="zhengtai_1" />
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] mt-5">
            At the exchange meeting, the heads of various departments of Chint
            put forward many questions and opinions on production technology and
            equipment use, while the technical experts of Haitian Group gave
            detailed answers and introductions to each question, leading to an
            enthusiastic on-site discussion atmosphere. Subsequently, the two
            sides conducted in-depth exchanges on building a full-life-cycle
            collaborative intelligent manufacturing system and a green
            manufacturing system. After deeply communicating and learning more
            about Haitian’s unique “8 + 16” intelligent manufacturing model and
            digital management platform, the leaders of Chint expressed their
            deep appreciation and expressed that it is worth learning and using
            for reference.
          </p>
          <img src="/assets/zhengtai_2.jpg" alt="zhengtai_2" className="my-4" />
          <p className="text-[rgba(0,0,0,0.7)] text-[14px]">
            Doing a good job in the transformation and upgrading of digital
            intelligence and green development requires more technological
            innovation and production practice. In the future, Haitian will
            continue to be driven by technological innovation to promote
            productivity changes in various fields, continue to output
            high-efficiency digital productivity to customers, solve practical
            problems faced by customers, and contribute to the transformation
            and upgrading of the industry and green development!
          </p>
        </div>

        <div className="mt-10 w-[92%] md:w-[80%] mx-auto border-[0.75pt] border-[rgba(0,0,0,0.4)] p-[25px]">
          <h2 className="text-[#d0a740] text-[18px] font-bold">
            RELATED POSTS
          </h2>
          <div className="xl:flex xl:justify-between">
            <div className="md:flex md:justify-between xl:w-[65%]">
              <div className="md:w-[48%]">
                <Link to="/new-developments">
                  <img
                    src="/assets/hti-china-headquarter-1024x576-1-648x250.jpg"
                    alt="hti-china-headquarter"
                    className="my-4 min-h-[150px] object-cover w-full"
                  />
                  <p className="text-[#d0a740] text-[14px] font-bold uppercase">
                    New developments and European premieres at K 2022
                  </p>
                  <p className="text-[rgba(0,0,0,0.6)] text-[14px] pb-5">
                    2022-08-05
                  </p>
                </Link>
              </div>

              <div className="md:w-[48%]">
                <Link to="/tour">
                  <img
                    src="/assets/germany-open-house_home-648x250.jpg"
                    alt="germany-open-house_home"
                    className="my-4 min-h-[150px] object-cover w-full"
                  />
                  <p className="text-[#d0a740] text-[14px] font-bold uppercase">
                    A Tour in Haitian International Germany Open House
                  </p>
                  <p className="text-[rgba(0,0,0,0.6)] text-[14px] pb-5">
                    2022-07-27
                  </p>
                </Link>
              </div>
            </div>

            <div className="xl:w-[33%]">
              <Link to="/haitian-eastern">
                <img
                  src="/assets/huanan_1-648x250.jpg"
                  alt="huanan_1"
                  className="my-4 min-h-[150px] object-cover w-full"
                />
                <p className="text-[#d0a740] text-[14px] font-bold uppercase">
                  HAITIAN EASTERN OPERATION CENTER SOUTHEAST CHINA SETS SAIL
                </p>
                <p className="text-[rgba(0,0,0,0.6)] text-[14px]">2022-07-22</p>
              </Link>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default HaitianChint;
