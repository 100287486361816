import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="text-white bg-gradient-to-r from-[#011132] via-[#0d3c84] to-[#011132] mt-[60px] py-[20px]">
      <div className="w-[92%] md:w-[80%] mx-auto">
        <div className="py-[35px] border-b-[1px] border-b-[rgba(255,255,255,0.15)]">
          <img
            src="/assets/haitian-group-logo.svg"
            alt="logo"
            className="h-[38px] brightness-0 invert-[1]"
          />
        </div>

        <ul className="py-[35px] text-[14px] flex flex-wrap font-semibold md:w-[70%] md:mx-auto xl:flex-nowrap">
          <li className="pr-[20px] basis-6/12 pb-1">
            <Link to="/company">Company</Link>
          </li>
          <li className="pr-[20px] basis-6/12 pb-1">
            <Link to="/portfolios">Portfolios</Link>
          </li>
          <li className="pr-[20px] basis-6/12 pb-1">
            <Link to="/culture">Culture</Link>
          </li>
          <li className="pr-[20px] basis-6/12 pb-1">
            <Link to="/social-value">Social Value</Link>
          </li>
          <li className="pr-[20px] basis-6/12 pb-1">
            <Link to="/newsroom">Newsroom</Link>
          </li>
          <li className="pr-[20px] basis-6/12 pb-1">
            <Link to="/careers">Careers</Link>
          </li>
          <li className="pr-[20px] basis-6/12 pb-1">
            <Link to="/contact">Contact</Link>
          </li>
        </ul>

        <div className="py-[35px] flex flex-col justify-center items-center border-t-[1px] border-t-[rgba(255,255,255,0.15)]">
          <p>OUR INDUSTRIES</p>
          <div className="md:flex md:justify-between">
            <img
              src="/assets/HTInternational_white.svg"
              alt="htinternational_white"
              className="h-[34px] mt-2 md:mt-4 md:w-[15%]"
            />
            <img
              src="/assets/haitian-precision-logo_white.svg"
              alt="haitan-precision_white"
              className="h-[34px] mt-4 md:w-[15%]"
            />
            <img
              src="/assets/haitian-drive-svg_white.svg"
              alt="haitian-drive-svg_white"
              className="h-[34px] mt-4 md:w-[15%]"
            />
            <img
              src="/assets/DieCasting_white.svg"
              alt="DieCasting_white"
              className="h-[34px] mt-4 md:w-[15%]"
            />
            <img
              src="/assets/haitian-group-inverted.svg"
              alt="haitian-group-inverted"
              className="h-[34px] mt-4 md:w-[15%]"
            />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
