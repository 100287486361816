import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import video from "../assets/video.mp4";

const Home = () => {
  return (
    <div>
      <Header selected="Home" />
      <div className="font-sans absolute w-full">
        <div className="h-[400px] md:h-[520px] bg-hero-one bg-cover bg-center mt-[103px] md:mt-[152px] lg:mt-[158.5px] relative before:bottom-[16px] before:w-[30%] before:content-[' '] before:h-[7px] before:bg-[#d0a740] before:absolute before:z-[9]">
          <div className="px-[4%] md:px-[6%] h-full flex items-end md:justify-end lg:justify-start lg:pl-[152px] border-b border-5 border-[#001b36]">
            <div className="text-white text-[14px] bg-[rgba(0,27,54,0.9)] p-[20px] uppercase md:w-[608.7px] lg:w-[620px]">
              <h1 className="text-[24px] md:text-[32px] pb-[10px] font-semibold">
                The Haitian Group
              </h1>
              <h2 className="text-[24px] md:text-[32px] mb-[18px] pb-[23.8px] leading-[1em] font-light">
                Power. Performance. Consistency.
              </h2>
            </div>
          </div>
        </div>

        <div className="w-[92%] md:w-[80%] mx-auto text-center pt-10 md:pt-16">
          <h3 className="text-[#001b36] uppercase text-[32px] font-semibold leading-[1em]">
            Equipment for China,
            <br />
            Equipment for The World
          </h3>
          <p className="text-[rgba(0,0,0,0.7)] leading-[23.8px] mt-12">
            <span>
              Founded in 1966 in Ningbo, China, the Haitian Group has developed
              to one of the leading Corporate Groups within industrial
              manufacturing.
            </span>
            <br />
            <span>
              With our 5 brands we cover and supply a wide variety of industries
              in China and around the world.
            </span>
          </p>
          <p className="text-[rgba(0,0,0,0.7)] leading-[23.8px] mt-[14px]">
            Welcome to our World of Manufacturing.
          </p>

          <div className="lg:flex lg:justify-between">
            <div className="md:flex md:justify-between md:pt-6 mr-[1rem]">
              <div className="border-b-[5px] border-b-[#d0a740] pb-[12px] md:w-[47%]">
                <h3 className="mt-10 text-[20px] text-[#093175] leading-[18px] uppercase font-medium max-w-[120px] m-auto">
                  World Of Plastics
                </h3>
                <img
                  src="/assets/HTI_Golden.png"
                  alt="hti_golden"
                  className="w-[55%] block m-auto"
                />
                <img
                  src="/assets/HTInternational.svg"
                  alt="htinternational"
                  className="w-[40%] mx-auto"
                />
              </div>

              <div className="border-b-[5px] border-b-[#d0a740] pb-[12px] md:w-[47%]">
                <h3 className="mt-10 text-[20px] text-[#093175] leading-[18px] uppercase font-medium max-w-[120px] m-auto">
                  World Of Metal
                </h3>
                <img
                  src="/assets/Metal_Golden.png"
                  alt="metal_golden"
                  className="w-[55%] block m-auto"
                />
                <img
                  src="/assets/haitian-precision-logo.svg"
                  alt="haitian-precision"
                  className="w-[40%] mx-auto"
                />
              </div>
            </div>

            <div className="md:flex md:justify-between md:pt-6 mr-[1rem]">
              <div className="border-b-[5px] border-b-[#d0a740] pb-[12px] md:w-[47%]">
                <h3 className="mt-10 text-[20px] text-[#093175] leading-[18px] uppercase font-medium max-w-[120px] m-auto">
                  World Of Drives
                </h3>
                <img
                  src="/assets/Drives_Golden.png"
                  alt="drives_golden"
                  className="w-[55%] block m-auto"
                />
                <img
                  src="/assets/haitian-drive-svg.svg"
                  alt="haitian-drive"
                  className="w-[40%] mx-auto"
                />
              </div>

              <div className="border-b-[5px] border-b-[#d0a740] pb-[12px] md:w-[47%]">
                <h3 className="mt-10 text-[20px] text-[#093175] leading-[18px] uppercase font-medium max-w-[120px] m-auto">
                  World Of Diecasting
                </h3>
                <img
                  src="/assets/DieCasting_Golden.png"
                  alt="diecasting_golden"
                  className="w-[55%] block m-auto"
                />
                <img
                  src="/assets/Diecasting.svg"
                  alt="diecasting"
                  className="w-[40%] mx-auto"
                />
              </div>
            </div>

            <div className="border-b-[5px] border-b-[#d0a740] pb-[12px] md:w-[47%] xl:w-[38%] lg:pt-6">
              <h3 className="mt-10 text-[20px] text-[#093175] leading-[18px] uppercase font-medium max-w-[120px] m-auto">
                World Of Smart
              </h3>
              <img
                src="/assets/SmartWorld_Golden.png"
                alt="smartworld_golden"
                className="w-[55%] block m-auto"
              />
              <img
                src="/assets/SmartSolutions.svg"
                alt="smartsolutions"
                className="w-[40%] mx-auto"
              />
            </div>
          </div>

          <div className="mt-12 relative hidden lg:block">
            <img
              src="/assets/Mapblue-with-BG-Zeichenfläche-1-e1522158869666-xl-e1522950995151.png"
              alt="global"
            />
            <section className="text-left absolute bottom-0 p-4">
              <h5 className="text-[#143a84] text-[35px] font-semibold pb-[5px]">
                OUR GLOBAL NETWORK
              </h5>
              <span className="text-[30px] p-[5px] bg-[rgba(255,255,255,0.8)] text-[#143a84] font-bold">
                GLOBAL PRESENCE
              </span>
              <p className="text-[#143a84] text-[14px] leading-[23.8px] mt-[8px] p-[8px] bg-[rgba(255,255,255,0.8)] w-[65%]">
                With about 60 sales and service partners including 9 locations
                for assembling and logistics, Haitian International has a
                presence in all major world markets.
              </p>
            </section>
          </div>

          <div className="mb-12 lg:flex lg:items-stretch lg:mt-20 xl:border-b-[5px] xl:border-b-[#f7d37b]">
            <video
              controls
              className="mt-24 lg:w-[53%] xl:w-[65%] lg:mt-0"
              poster="/assets/Schriftzeichen-Group.jpeg"
            >
              <source src={video} type="video/mp4" />
            </video>
            <div className="lg:w-[47%] xl:w-[35%] bg-[#001b36] text-white text-left p-[10px] border-t-[5px] border-t-[#f7d37b] border-b-[5px] border-b-[#f7d37b] lg:border-0">
              <h4 className="text-[28px] leading-[1.2em] font-light uppercase px-[20px] pb-[10px] mt-[11.2px] mb-[8.4px]">
                50 Years Of Experience
              </h4>
              <p className="px-[20px] leading-[1.4em] text-[14px] pb-[1em]">
                Entrepreneurial spirit, cost awareness, willingness to invest
                and full respect for&nbsp;employees, partners and customers are
                the cornerstones of the Haitian Group corporate
                culture.&nbsp;Because high-quality and internationally
                competitive products are created&nbsp;in the minds and with the
                hands of motivated employees.
              </p>
              <p className="px-[20px] text-[#2ea3f2]">
                <Link to="/company">More &#62;&#62;</Link>
              </p>
            </div>
          </div>

          <div className="md:grid md:gap-5 md:grid-cols-[auto_auto] xl:grid-cols-[auto_auto_auto] lg:pb-16">
            <div className="border-b-[5px] border-b-[#d0a740] relative text-white">
              <img
                src="/assets/zhengtai_home-412x250.jpg"
                alt="zhengtai_home"
                className="hover:scale-105 transition-transform w-full"
              />
              <div className="bg-[rgba(0,27,54,0.8)] hover:bg-[#001b36] p-[10px] pb-[20px] absolute bottom-0 left-0 max-w-[95%] transition-colors">
                <h4 className="uppercase text-[22px] mr-[20px] pb-3 font-light whitespace-nowrap max-w-full overflow-hidden text-ellipsis">
                  <Link className="p-[5px]" to="/haitian-chint">
                    Haitian &amp; Chint｜Thematic Exchange Meeting – Grow
                    together with Customers
                  </Link>
                </h4>
                <Link
                  to="/haitian-chint"
                  className="absolute bottom-[5px] right-[5px] text-[14px]"
                >
                  More &#62;
                </Link>
              </div>
            </div>

            <div className="border-b-[5px] border-b-[#d0a740] relative text-white mt-5 md:mt-0">
              <img
                src="/assets/hti-china-headquarter-1024x576-1-412x250.jpg"
                alt="hti-china-headquarter"
                className="hover:scale-105 transition-transform w-full"
              />
              <div className="bg-[rgba(0,27,54,0.8)] hover:bg-[#001b36] p-[10px] pb-[20px] absolute bottom-0 left-0 max-w-[95%] transition-colors">
                <h4 className="uppercase text-[22px] mr-[20px] pb-3 font-light whitespace-nowrap max-w-full overflow-hidden text-ellipsis">
                  <Link className="p-[5px]" to="/new-developments">
                    New developments and European premieres at K 2022
                  </Link>
                </h4>
                <Link
                  to="/new-developments"
                  className="absolute bottom-[5px] right-[5px] text-[14px]"
                >
                  More &#62;
                </Link>
              </div>
            </div>

            <div className="border-b-[5px] border-b-[#d0a740] relative text-white mt-5 md:mt-0">
              <img
                src="/assets/germany-open-house_home-412x250.jpg"
                alt="germany-open-house_home"
                className="hover:scale-105 transition-transform w-full"
              />
              <div className="bg-[rgba(0,27,54,0.8)] hover:bg-[#001b36] p-[10px] pb-[20px] absolute bottom-0 left-0 max-w-[95%] transition-colors">
                <h4 className="uppercase text-[22px] mr-[20px] pb-3 font-light whitespace-nowrap max-w-full overflow-hidden text-ellipsis">
                  <Link className="p-[5px]" to="/tour">
                    A Tour in Haitian International Germany Open House
                  </Link>
                </h4>
                <Link
                  to="/tour"
                  className="absolute bottom-[5px] right-[5px] text-[14px]"
                >
                  More &#62;
                </Link>
              </div>
            </div>

            <div className="border-b-[5px] border-b-[#d0a740] relative text-white mt-5 md:mt-0">
              <img
                src="/assets/huanan_1-412x250.jpg"
                alt="huanan_1"
                className="hover:scale-105 transition-transform w-full"
              />
              <div className="bg-[rgba(0,27,54,0.8)] hover:bg-[#001b36] p-[10px] pb-[20px] absolute bottom-0 left-0 max-w-[95%] transition-colors">
                <h4 className="uppercase text-[22px] mr-[20px] pb-3 font-light whitespace-nowrap max-w-full overflow-hidden text-ellipsis">
                  <Link className="p-[5px]" to="/haitian-eastern">
                    HAITIAN EASTERN OPERATION CENTER SOUTHEAST CHINA SETS SAIL
                  </Link>
                </h4>
                <Link
                  to="/haitian-eastern"
                  className="absolute bottom-[5px] right-[5px] text-[14px]"
                >
                  More &#62;
                </Link>
              </div>
            </div>

            <div className="border-b-[5px] border-b-[#d0a740] relative text-white mt-5 md:mt-0">
              <img
                src="/assets/guangdao_1-412x250.jpg"
                alt="guangdao_1"
                className="hover:scale-105 transition-transform w-full"
              />
              <div className="bg-[rgba(0,27,54,0.8)] hover:bg-[#001b36] p-[10px] pb-[20px] absolute bottom-0 left-0 max-w-[95%] transition-colors">
                <h4 className="uppercase text-[22px] mr-[20px] pb-3 font-light whitespace-nowrap max-w-full overflow-hidden text-ellipsis">
                  <Link className="p-[5px]" to="/haitian-lighting">
                    HAITIAN LIGHTING SOLUTION FOR THE AUTOMOTIVE INDUSTRY –
                    EFFICIENT AND RELIABLE SOURCE OF LIGHT
                  </Link>
                </h4>
                <Link
                  to="/haitian-lighting"
                  className="absolute bottom-[5px] right-[5px] text-[14px]"
                >
                  More &#62;
                </Link>
              </div>
            </div>

            <div className="border-b-[5px] border-b-[#d0a740] relative text-white mt-5 md:mt-0">
              <img
                src="/assets/biyadi_home-412x250.jpg"
                alt="biyadi_home"
                className="hover:scale-105 transition-transform w-full"
              />
              <div className="bg-[rgba(0,27,54,0.8)] hover:bg-[#001b36] p-[10px] pb-[20px] absolute bottom-0 left-0 max-w-[95%] transition-colors">
                <h4 className="uppercase text-[22px] mr-[20px] pb-3 font-light whitespace-nowrap max-w-full overflow-hidden text-ellipsis">
                  <Link className="p-[5px]" to="/haitian-multi-component">
                    HAITIAN’S MULTI-COMPONENT LAMP MOLDING SOLUTION HELPS THE
                    DEVELOPMENT OF NEW ENERGY VEHICLES
                  </Link>
                </h4>
                <Link
                  to="/haitian-multi-component"
                  className="absolute bottom-[5px] right-[5px] text-[14px]"
                >
                  More &#62;
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Home;
