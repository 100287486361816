import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import video from "..//assets/video.mp4";

const Company = () => {
  return (
    <div>
      <Header selected="Company" />
      <div className="font-sans absolute w-full">
        <div className="mt-[103px] md:mt-[152px] lg:mt-[158.5px] min-h-[40vw] lg:bg-company lg:bg-cover lg:px-[6%]">
          <img
            src="/assets/group_company_teaser02_mobil.jpg"
            alt="hero-group_company_teaser02_mobil"
            className="lg:hidden"
          />

          <h2 className="hidden lg:block text-white lg:pl-20 text-[45px] uppercase font-semibold leading-[1em] pt-[180px]">
            <span className="text-[24px]">The Haitian Group</span>
            <br />
            Power. Performance.
            <br />
            Consistency.
          </h2>
        </div>
        <div className="h-[30px] bg-[#E4BE70]" />

        <div className="w-[92%] md:w-[80%] mx-auto pt-10 md:pt-9 text-left lg:hidden">
          <h2 className="font-medium text-[24px] mb-2.5 text-[#001b36] uppercase">
            The Haitian Group
          </h2>
          <h3 className="text-[32px] text-[#001b36] uppercase font-semibold leading-[1em]">
            Power. Performance.
            <br />
            Consistency.
          </h3>
        </div>

        <div className="w-[92%] md:w-[80%] mx-auto pt-10 md:pt-16 lg:flex lg:justify-between">
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] mt-10 pb-7 lg:w-[46%] lg:p-0 lg:m-0">
            Founded in 1966, with over 50 years of growth through
            entrepreneurial brilliance, Haitian Group has developed into a
            holding company with multi-national operation and total assets
            exceeding RMB21.1 billion. The Group now has mainly covered five
            industries, namely plastics machinery (“Haitian International
            Holdings Limited” listed in Hong Kong Stock Exchange), CNC machines
            (“Ningbo Haitian Precision Holdings Limited” listed in Shanghai
            Stock Exchange), drive and control systems (“Haitian Drive”) and die
            casting machines (“Haitian Die Casting”), smart solutions (“Haitian
            Smart”).
          </p>
          <div className="lg:w-[46%]">
            <p className="text-[rgba(0,0,0,0.7)] text-[14px] pb-3.5">
              The five companies are affiliated with over 70 subsidiaries
              throughout the world. In 2021, the Group has generated total
              output value exceeding RMB21.1 billion, with products sold to
              customer over 130 countries and regions.
            </p>
            <p className="text-[rgba(0,0,0,0.7)] text-[14px] pb-3.5">
              In recent years, the companies under Haitian Group has won a
              variety of honors of the national, provincial&nbsp;and municipal
              levels, including “National Innovative&nbsp;Enterprise”, “National
              Key High-Tech Enterprise”, “Famous Chinese Brand” and “State
              Science and Technology Awards” etc.
            </p>
          </div>
        </div>

        <div className="mb-12 lg:flex lg:items-stretch lg:mt-20 xl:border-b-[5px] xl:border-b-[#f7d37b] w-[92%] md:w-[80%] mx-auto">
          <video
            controls
            className="mt-12 lg:w-[53%] xl:w-[65%] lg:mt-0"
            poster="/assets/Teaser_Video_Group.jpeg"
          >
            <source src={video} type="video/mp4" />
          </video>
          <div className="lg:w-[47%] xl:w-[35%] bg-[#c6a765] text-white text-left p-[10px] border-t-[10px] border-t-[#f7d37b] border-b-[10px] border-b-[#f7d37b] lg:border-0">
            <h4 className="text-[23px] leading-[1em] font-semibold uppercase px-[20px] pb-[10px] mt-[11.2px] mb-[8.4px] pt-4">
              50 Years
              <br />
              Of Experience
            </h4>
            <p className="px-[20px] leading-[23.8px] text-[14px] pb-[1em]">
              Entrepreneurial spirit, cost awareness, willingness to invest and
              full respect for&nbsp;employees, partners and customers are the
              cornerstones of the Haitian Group corporate culture.&nbsp;Because
              high-quality and internationally competitive products are
              created&nbsp;in the minds and with the hands of motivated
              employees.
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Company;
