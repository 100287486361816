import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import video from "../assets/video.mp4";
import videoTwo from "../assets/Haitian-International-2019-web.mp4";
import posterOne from "../assets/HTGroup-Image-Thumbnail-2019-1.jpeg";
import posterTwo from "../assets/HTI-Image-Thumbnail-2019.jpeg";

const OurCulture = () => {
  return (
    <div>
      <Header />
      <div className="font-sans absolute w-full">
        <div className="mt-[103px] md:mt-[152px] lg:mt-[158.5px] min-h-[40vw]">
          <div className="bg-our-culture bg-cover bg-center h-[200px] md:h-[300px] lg:h-[400px]" />
        </div>

        <div className="w-[92%] md:w-[80%] mx-auto pt-8 md:pt-12 xl:pt-0 text-left">
          <h1 className="text-[rgba(20,58,132,0.7)] text-[24px]">
            OUR CULTURE
          </h1>
          <h2 className="relative pb-[7px] uppercase text-[16px] md:text-[32px] text-[#143a84] font-semibold after:content-[''] after:w-[70px] after:h-[4px] after:absolute after:left-0 after:ml-0 after:bottom-[-10px] after:bg-[#cdcdcd]">
            Haitian is more than a business
          </h2>

          <div className="lg:flex lg:justify-between lg:mt-8 xl:mt-12">
            <p className="text-[14px] text-[rgba(0,0,0,0.7)] mt-8 lg:mt-0 leading-[23.8px] lg:w-[47%]">
              Haitian in the Chinese language and culture, means “sea (hai) and
              sky (tian)”. Right from the beginning we have incorporated our
              vision into this concept. For us, water typifies dignity – it
              stands for the diversity of technological potential. The sky, on
              the other hand, knows no limitations – it allows every imaginable
              step forward. Over the last five decades this ideal has always
              been the motivation for us to invest&nbsp;a great deal of energy
              and capital into our product improvement. &nbsp;
            </p>
            <p className="text-[14px] text-[rgba(0,0,0,0.7)] mt-8 lg:mt-0 leading-[23.8px] lg:w-[47%]">
              Today Haitian enjoys an excellent image worldwide as a brand name.
              The business has succeeded, according to our understanding, only
              because in the business structure, teamwork has played a decisive
              role right from the beginning, including active recreation of the
              daily work schedule. Therefore, joint activities in sport and
              culture accompany the daily process of creativity of Haitian.
            </p>
          </div>
        </div>

        <div className="bg-[#113375] mt-16 p-3 lg:py-16">
          <div className="md:w-[80%] mx-auto lg:flex lg:justify-between">
            <div className="lg:w-[48%]">
              <video controls poster={posterOne} className="w-full">
                <source src={video} type="video/mp4" />
              </video>
              <p className="text-white text-[18px] my-6">
                <strong>HAITIAN GROUP-IMAGEVIDEO</strong>
              </p>
            </div>

            <div className="mt-12 lg:mt-0 lg:w-[48%]">
              <video controls poster={posterTwo} className="w-full">
                <source src={videoTwo} type="video/mp4" />
              </video>
              <p className="text-white text-[18px] my-6">
                <strong>HAITIAN INTERNATIONAL-IMAGEVIDEO</strong>
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default OurCulture;
