import React, { useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import hq from "../assets/Haitian-International-Headquarter-China.jpeg";
import chinaOne from "../assets/Factory-1-01.jpeg";
import chinaTwo from "../assets/zhafirnew-1.jpeg";
import chinaThree from "../assets/Huayuan_07_01.jpeg";
import chinaFour from "../assets/Dagang_10_01.jpeg";
import chinaFive from "../assets/Factory_11_02.jpeg";
import chinaSix from "../assets/tongtuload.jpeg";
import chinaSeven from "../assets/Wuxi.jpeg";
import chinaEight from "../assets/Guangzhou_08_01.jpeg";
import germany from "../assets/Haitian-International-Germany.jpeg";
import india from "../assets/Haitian-International-India.jpeg";
import vietnam from "../assets/Haitian-International-Vietnam.jpeg";
import turkey from "../assets/Haitian-International-Turkey.jpeg";
import brasil from "../assets/Haitian-International-Brazil.jpeg";
import mexico from "../assets/Haitian-International-New-Mexico.jpeg";
import thailand from "../assets/Haitian-International-Thailand.jpeg";
import indonesia from "../assets/Haitian-International-Indonesia.jpeg";
import japan from "../assets/Haitian-International-Japan.jpeg";

const Manufacturing = () => {
  const [hqSelected, setHqSelected] = useState(true);
  const [mChina, setMChina] = useState(false);
  const [mWorld, setMWorld] = useState(false);

  return (
    <div>
      <Header />
      <div className="font-sans absolute w-full">
        <div className="mt-[103px] md:mt-[152px] lg:mt-[158.5px] min-h-[40vw]">
          <div className="bg-manufacturing bg-cover bg-center h-[200px] md:h-[300px] lg:h-[400px]" />
        </div>

        <div className="w-[92%] md:w-[80%] mx-auto pt-8 md:pt-12 xl:pt-0 text-left">
          <h1 className="text-[rgba(20,58,132,0.7)] text-[15px] md:text-[24px]">
            OUR GLOBAL PRESENCE
          </h1>
          <h2 className="relative pb-[7px] text-[16px] md:text-[32px] text-[#143a84] font-semibold after:content-[''] after:w-[70px] after:h-[4px] after:absolute after:left-0 after:ml-0 after:bottom-[-10px] after:bg-[#cdcdcd]">
            WORLDWIDE MANUFACTURING
          </h2>
          <p className="text-[14px] text-[rgba(0,0,0,0.7)] mt-8 leading-[23.8px]">
            With about 60 sales and service partners including 9 locations for
            assembling and logistics, Haitian International has a presence in
            all major world markets. Our Research &amp; Development team works
            pro-actively with plastics processors of different industries.We
            develop individual customer solutions in the shortest time possible
            and can respond with approaches geared to the regional specifics of
            individual markets.
          </p>
        </div>

        <div className="w-[92%] md:w-[80%] lg:flex mt-4 mx-auto pt-8 md:pt-16 font-light text-center text-[16pt] text-[#808080]">
          <button
            className={`w-full border-b-[2px] ${
              hqSelected
                ? "text-[#333333] border-b-[#0d83d8] font-normal"
                : "text-[#808080] border-b-[#808080]"
            } `}
            onClick={() => {
              setHqSelected(true);
              setMChina(false);
              setMWorld(false);
            }}
          >
            HEADQUARTER
          </button>
          <button
            className={`w-full mt-3 lg:mt-0 border-b-[2px] ${
              mChina
                ? "text-[#333333] border-b-[#0d83d8] font-normal"
                : "text-[#808080] border-b-[#808080]"
            } `}
            onClick={() => {
              setMChina(true);
              setHqSelected(false);
              setMWorld(false);
            }}
          >
            MANUFACTURING IN CHINA
          </button>
          <button
            className={`w-full mt-3 lg:mt-0 border-b-[2px] ${
              mWorld
                ? "text-[#333333] border-b-[#0d83d8] font-normal"
                : "text-[#808080] border-b-[#808080]"
            } `}
            onClick={() => {
              setMWorld(true);
              setMChina(false);
              setHqSelected(false);
            }}
          >
            MANUFACTURING WORLDWIDE
          </button>
        </div>

        <div className="w-[92%] md:w-[80%] mx-auto mt-8 pt-8 md:pt-12 xl:pt-0 text-left">
          {hqSelected && (
            <div className="lg:flex lg:justify-between lg:mb-32 xl:mt-20">
              <img src={hq} alt="headquarter" className="lg:w-[52%]" />
              <div className="lg:w-[43%]">
                <h3 className="text-[rgba(20,58,132,0.7)] text-[20px] mt-7">
                  HEADQUARTER
                </h3>
                <ul className="list-disc list-inside text-[14px] text-[#666] leading-[26px] mt-1">
                  <li>Located in Xiaogang, Ningbo</li>
                  <li>Next to Haitian Road Plant Area</li>
                </ul>
                <p className="text-[14px] text-[rgba(0,0,0,0.7)] mt-6 leading-[23.8px]">
                  On 23 floors at the headquarters building of Haitian Group,
                  Haitian International is presented with key functional
                  departments like sales, R&amp;D, administrative departments,
                  etc.
                  <br />
                  It is adjacent to Haitian Road Plant Area for medium and large
                  size injection molding machines and not far away from Tongtu
                  Road Plant Area and Jiangnan Road Plant Area. The excellent
                  location facilitates the most efficient communication between
                  most important functional departments as well as short and
                  convenient transportation routes.
                </p>
              </div>
            </div>
          )}

          {mChina && (
            <div className="xl:mt-24">
              <div className="lg:flex lg:justify-between">
                <div className="lg:w-[30%]">
                  <img
                    src={chinaOne}
                    alt="factory"
                    className="border-[#00208a] border-[1px] w-full"
                  />
                  <div className="p-2 border-[1px] border-t-[5px] border-[#030047]">
                    <h5 className="text-[rgba(20,58,132,0.7)]">
                      JIANGNAN ROAD PLANT AREA
                    </h5>
                    <ul className="list-disc list-inside text-[14px] text-[#666] leading-[26px] mt-1">
                      <li>Big machine, parts and components manufacturing</li>
                      <li>Covering an area of about 241,000 sqm</li>
                      <li>Located in Xiaogang, Ningbo</li>
                      <li>Centralized production of parts and components</li>
                      <li>
                        6 workshops to cover machining and production of parts
                        and components such as casting parts, manifolds,
                        plasticizing parts, screws and barrels, sheet metals
                        etc.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mt-8 lg:mt-0 lg:w-[30%]">
                  <img
                    src={chinaTwo}
                    alt="factory"
                    className="border-[#00208a] border-[1px] w-full"
                  />
                  <div className="p-2 border-[1px] border-t-[5px] border-[#030047] lg:h-[388px] xl:h-[310px]">
                    <h5 className="text-[rgba(20,58,132,0.7)]">
                      ZHAFIR PLANT AREA
                    </h5>
                    <ul className="list-disc list-inside text-[14px] text-[#666] leading-[26px] mt-1">
                      <li>Covering an area of about 226,000 sqm</li>
                      <li>Located in Chunxiao, Ningbo</li>
                      <li>
                        Production of electric machines and multi-component
                        machines
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mt-8 lg:mt-0 lg:w-[30%]">
                  <img
                    src={chinaThree}
                    alt="factory"
                    className="border-[#00208a] border-[1px] w-full"
                  />
                  <div className="p-2 border-[1px] border-t-[5px] border-[#030047] lg:h-[388px] xl:h-[310px]">
                    <h5 className="text-[rgba(20,58,132,0.7)]">
                      HUAYUAN PLANT AREA
                    </h5>
                    <ul className="list-disc list-inside text-[14px] text-[#666] leading-[26px] mt-1">
                      <li>Covering an area of about 97,000 sqm</li>
                      <li>Located in tax-bonded zone of Ningbo</li>
                      <li>Production of machines for export</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="lg:flex lg:justify-between mt-8">
                <div className="lg:w-[30%]">
                  <img
                    src={chinaFour}
                    alt="factory"
                    className="border-[#00208a] border-[1px] w-full"
                  />
                  <div className="p-2 border-[1px] border-t-[5px] border-[#030047] xl:h-[168px]">
                    <h5 className="text-[rgba(20,58,132,0.7)]">
                      DAGANG PLANT AREA
                    </h5>
                    <ul className="list-disc list-inside text-[14px] text-[#666] leading-[26px] mt-1">
                      <li>Covering an area of about 91,000 sqm</li>
                      <li>Located in Dagang, Ningbo</li>
                      <li>Production of small tonnage machines</li>
                    </ul>
                  </div>
                </div>
                <div className="mt-8 lg:mt-0 lg:w-[30%]">
                  <img
                    src={chinaFive}
                    alt="factory"
                    className="border-[#00208a] border-[1px] w-full"
                  />
                  <div className="p-2 border-[1px] border-t-[5px] border-[#030047] lg:h-[180px] xl:h-[168px]">
                    <h5 className="text-[rgba(20,58,132,0.7)]">
                      HAITIAN ROAD PLANT AREA
                    </h5>
                    <ul className="list-disc list-inside text-[14px] text-[#666] leading-[26px] mt-1">
                      <li>Covering an area of about 200,000 sqm</li>
                      <li>Located in Xiaogang, Ningbo</li>
                      <li>Production of medium and small tonnage machines</li>
                    </ul>
                  </div>
                </div>
                <div className="mt-8 lg:mt-0 lg:w-[30%]">
                  <img
                    src={chinaSix}
                    alt="factory"
                    className="border-[#00208a] border-[1px] w-full"
                  />
                  <div className="p-2 border-[1px] border-t-[5px] border-[#030047] lg:h-[180px] xl:h-[168px]">
                    <h5 className="text-[rgba(20,58,132,0.7)]">
                      TONGTU ROAD PLANT AREA
                    </h5>
                    <ul className="list-disc list-inside text-[14px] text-[#666] leading-[26px] mt-1">
                      <li>Covering an area of about 280,000 sqm</li>
                      <li>Located in Xiaogang, Ningbo</li>
                      <li>
                        Production of two-platen machines &amp; big tonnage
                        machines
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="lg:flex mt-8">
                <div className="mt-8 lg:mt-0 lg:w-[30%] lg:mr-10 xl:mr-14">
                  <img
                    src={chinaSeven}
                    alt="factory"
                    className="border-[#00208a] border-[1px] w-full"
                  />
                  <div className="p-2 border-[1px] border-t-[5px] border-[#030047] lg:h-[220px] xl:h-[168px]">
                    <h5 className="text-[rgba(20,58,132,0.7)]">
                      WUXI PLANT AREA
                    </h5>
                    <ul className="list-disc list-inside text-[14px] text-[#666] leading-[26px] mt-1">
                      <li>Covering an area of about 216,000 sqm</li>
                      <li>Located in Wuxi, Jiangsu</li>
                      <li>Production of hydraulic machines</li>
                    </ul>
                  </div>
                </div>
                <div className="mt-8 lg:mt-0 lg:w-[30%]">
                  <img
                    src={chinaEight}
                    alt="factory"
                    className="border-[#00208a] border-[1px] w-full"
                  />
                  <div className="p-2 border-[1px] border-t-[5px] border-[#030047] lg:h-[220px] xl:h-[168px]">
                    <h5 className="text-[rgba(20,58,132,0.7)]">
                      HAITIAN ROAD PLANT AREA
                    </h5>
                    <ul className="list-disc list-inside text-[14px] text-[#666] leading-[26px] mt-1">
                      <li>Covering an area of about 23,000 sqm</li>
                      <li>Located in Panyu, Guangzhou</li>
                      <li>
                        Production of small tonnage machines for southern area
                        of China
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}

          {mWorld && (
            <div className="xl:mt-24">
              <div className="lg:flex lg:justify-between">
                <div className="lg:w-[30%]">
                  <img
                    src={germany}
                    alt="factory"
                    className="border-[#00208a] border-[1px] w-full"
                  />
                  <div className="p-2 border-[1px] border-t-[5px] border-[#030047]">
                    <h5 className="text-[rgba(20,58,132,0.7)]">
                      HAITIAN INTERNATIONAL GERMANY
                    </h5>
                    <ul className="list-disc list-inside text-[14px] text-[#666] leading-[26px] mt-1">
                      <li>Located in Ebermannsdorf, Germany</li>
                      <li>Sales and service for European market</li>
                      <li>
                        Training and technical support for customers and
                        distributors in 18 countries
                      </li>
                      <li>Covering an area of about​ 25,000 sqm</li>
                      <li>Application center for mold testing</li>
                    </ul>
                  </div>
                </div>
                <div className="mt-8 lg:mt-0 lg:w-[30%]">
                  <img
                    src={india}
                    alt="factory"
                    className="border-[#00208a] border-[1px] w-full"
                  />
                  <div className="p-2 border-[1px] border-t-[5px] border-[#030047] lg:h-[360px] xl:h-[206px]">
                    <h5 className="text-[rgba(20,58,132,0.7)]">
                      HAITIAN INDIA
                    </h5>
                    <ul className="list-disc list-inside text-[14px] text-[#666] leading-[26px] mt-1">
                      <li>Located in Gujarat, India</li>
                      <li>Sales and service organisation</li>
                      <li>Covering an area of about​ 51,800 sqm</li>
                      <li>Application center for mold testing</li>
                    </ul>
                  </div>
                </div>
                <div className="mt-8 lg:mt-0 lg:w-[30%]">
                  <img
                    src={vietnam}
                    alt="factory"
                    className="border-[#00208a] border-[1px] w-full"
                  />
                  <div className="p-2 border-[1px] border-t-[5px] border-[#030047] lg:h-[360px] xl:h-[206px]">
                    <h5 className="text-[rgba(20,58,132,0.7)]">
                      HAITIAN VIETNAM
                    </h5>
                    <ul className="list-disc list-inside text-[14px] text-[#666] leading-[26px] mt-1">
                      <li>Located in Binh Duong Province, Vietnam</li>
                      <li>Sales and service organisation</li>
                      <li>Covering an area of about​ 25,000 sqm</li>
                      <li>80 sqm application center for mold testing</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="lg:flex lg:justify-between mt-8">
                <div className="lg:w-[30%]">
                  <img
                    src={turkey}
                    alt="factory"
                    className="border-[#00208a] border-[1px] w-full"
                  />
                  <div className="p-2 border-[1px] border-t-[5px] border-[#030047]">
                    <h5 className="text-[rgba(20,58,132,0.7)]">
                      HAITIAN TURKEY
                    </h5>
                    <ul className="list-disc list-inside text-[14px] text-[#666] leading-[26px] mt-1">
                      <li>Located in Istanbul, Turkey</li>
                      <li>Sales and service organisation</li>
                      <li>Covering an area of about​ 11,000 sqm</li>
                      <li>For spare parts and stock machines</li>
                      <li>Application center for mold testing</li>
                    </ul>
                  </div>
                </div>
                <div className="mt-8 lg:mt-0 lg:w-[30%]">
                  <img
                    src={brasil}
                    alt="factory"
                    className="border-[#00208a] border-[1px] w-full"
                  />
                  <div className="p-2 border-[1px] border-t-[5px] border-[#030047] lg:h-[258px] xl:h-[180px]">
                    <h5 className="text-[rgba(20,58,132,0.7)]">
                      HAITIAN BRASIL
                    </h5>
                    <ul className="list-disc list-inside text-[14px] text-[#666] leading-[26px] mt-1">
                      <li>Located in São Paulo, Brazil</li>
                      <li>Sales and service organisation</li>
                      <li>Covering an area of about 12,000 sqm</li>
                      <li>For spare parts and stock machines</li>
                    </ul>
                  </div>
                </div>
                <div className="mt-8 lg:mt-0 lg:w-[30%]">
                  <img
                    src={mexico}
                    alt="factory"
                    className="border-[#00208a] border-[1px] w-full"
                  />
                  <div className="p-2 border-[1px] border-t-[5px] border-[#030047] lg:h-[258px] xl:h-[180px]">
                    <h5 className="text-[rgba(20,58,132,0.7)]">
                      HAITIAN MEXICO
                    </h5>
                    <ul className="list-disc list-inside text-[14px] text-[#666] leading-[26px] mt-1">
                      <li>Located in Jalisco, Mexico</li>
                      <li>Sales and service organisation</li>
                      <li>92,000 sqm for production and assembly</li>
                      <li>Application center for mold testing</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="lg:flex lg:justify-between mt-8">
                <div className="lg:w-[30%]">
                  <img
                    src={thailand}
                    alt="factory"
                    className="border-[#00208a] border-[1px] w-full"
                  />
                  <div className="p-2 border-[1px] border-t-[5px] border-[#030047] lg:h-[280px] xl:h-[180px]">
                    <h5 className="text-[rgba(20,58,132,0.7)]">
                      HAITIAN THAILAND
                    </h5>
                    <ul className="list-disc list-inside text-[14px] text-[#666] leading-[26px] mt-1">
                      <li>Located in Bangkok, Thailand</li>
                      <li>Sales and service organisation</li>
                      <li>1,400 sqm for spare parts and stock machines</li>
                      <li>200 sqm application center for mold testing</li>
                    </ul>
                  </div>
                </div>
                <div className="mt-8 lg:mt-0 lg:w-[30%]">
                  <img
                    src={indonesia}
                    alt="factory"
                    className="border-[#00208a] border-[1px] w-full"
                  />
                  <div className="p-2 border-[1px] border-t-[5px] border-[#030047] lg:h-[280px] xl:h-[180px]">
                    <h5 className="text-[rgba(20,58,132,0.7)]">
                      HAITIAN INDONESIA
                    </h5>
                    <ul className="list-disc list-inside text-[14px] text-[#666] leading-[26px] mt-1">
                      <li>Located in North Jakarta, Indonesia</li>
                      <li>Sales and service organisation</li>
                      <li>3,000 sqm for spare parts and stock machines</li>
                      <li>
                        Application center for mold testing, training and
                        technical support
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mt-8 lg:mt-0 lg:w-[30%]">
                  <img
                    src={japan}
                    alt="factory"
                    className="border-[#00208a] border-[1px] w-full"
                  />
                  <div className="p-2 border-[1px] border-t-[5px] border-[#030047] lg:h-[280px] xl:h-[180px]">
                    <h5 className="text-[rgba(20,58,132,0.7)]">
                      HAITIAN JAPAN
                    </h5>
                    <ul className="list-disc list-inside text-[14px] text-[#666] leading-[26px] mt-1">
                      <li>Located in Odawara Kanagawa, Japan</li>
                      <li>Sales and service organisation</li>
                      <li>300 sqm for spare parts and stock machines</li>
                      <li>Application center for mold testing</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Manufacturing;
