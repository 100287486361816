import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";

const HaitianLighting = () => {
  return (
    <div>
      <Header />
      <div className="font-sans absolute w-full">
        <div className="mt-[153px] md:mt-[202px] lg:mt-[208.5px] w-[92%] md:w-[80%] mx-auto pt-16 pb-10 border-[0.75pt] border-[rgba(0,0,0,0.4)]">
          <h1 className="text-center text-[#d0a740] uppercase text-[23px] font-semibold leading-[50px] px-[25px]">
            HAITIAN LIGHTING SOLUTION FOR THE AUTOMOTIVE INDUSTRY – EFFICIENT
            AND RELIABLE SOURCE OF LIGHT
          </h1>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] text-center mt-3">
            2022-07-22
          </p>
          <img
            src="/assets/guangdao_1.jpg"
            alt="guangdao_1"
            className="w-full my-[25px]"
          />
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] mt-10 pb-3.5 px-[25px]">
            <strong>
              The electric vehicle market for passenger cars is growing quickly
              with increasing consumer demand. As a highlight of the electric
              vehicles, optical parts including long light guides have become a
              design trend in the automotive industry. Coming in many variations
              and with different visual effects, the lamps have tremendous
              design flexibility and also enhance the safety advantage.
            </strong>
          </p>
          <h2 className="text-[#001b36] px-[25px] text-[18px] leading-[22px] my-2">
            <strong>LIGHT GUIDES: A HIGH-TECH PRODUCT</strong>
          </h2>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] pb-3.5 px-[25px]">
            The light guides – made of transparent plastics – is the
            light-emitting part of the car light. It has the advantages of high
            energy efficiency, a long lifespan, dimming capability, etc. It can
            effectively reduce the number of lamp bulbs and further achieve the
            goal of automotive light-weighting.
          </p>
          <section className="lg:flex lg:justify-between lg:px-[25px]">
            <img
              src="/assets/guangdao_2.jpg"
              alt="guangdao_2"
              className="w-full px-[25px] mt-6 lg:w-[47%] lg:px-0"
            />
            <img
              src="/assets/guangdao_4.jpg"
              alt="guangdao_4"
              className="w-full px-[25px] mt-6 lg:w-[47%] lg:px-0"
            />
          </section>
          <section className="lg:flex lg:justify-between lg:px-[25px]">
            <img
              src="/assets/guangdao_3.jpg"
              alt="guangdao_3"
              className="w-full px-[25px] mt-6 lg:w-[47%] lg:px-0"
            />
            <img
              src="/assets/guangdao_5.jpg"
              alt="guangdao_5"
              className="w-full px-[25px] mt-6 lg:w-[47%] lg:px-0"
            />
          </section>
          <h2 className="text-[#001b36] px-[25px] text-[18px] leading-[22px] mb-3 mt-10">
            <strong>TECHNICAL CHALLENGES</strong>
          </h2>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] mt-2 pb-3.5 px-[25px]">
            Being an optical product, the manufacture of light guide strips for
            headlights has strict optical standards and appearance requirements.
            To meet the conditions like light transmission, thermal stability,
            shape accuracy, surface gloss, and haze, the material of the optical
            part issues high viscosity and moisture sensitivity as the
            disadvantages to overcome. Advanced injection molding technology
            during manufacturing, in other words, the stabilization of
            processing, is essential for those optical parts. Otherwise, defects
            may appear. It must also be mentioned that the suitable combination
            of the clamping and injection components is a predominant factor for
            the technical challenge caused by the thick mold and low filling
            volume.
          </p>
          <h2 className="text-[#001b36] px-[25px] text-[18px] leading-[22px] mb-3 mt-5">
            <strong>HAITIANS’ SOLUTION FOR LIGHT GUIDES</strong>
          </h2>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] mt-2 pb-3.5 px-[25px]">
            In view of the characteristics and production difficulties of light
            guide strips, Haitians special machine solution can meet the
            production of long light guide strips (headlight guide, through-tail
            light guide, ambient light) and at the same time, can be compatible
            with other products to the greatest extent. Key components have been
            upgraded to reduce manufacturing risk.
          </p>
          <section className="lg:flex lg:justify-between lg:px-[25px]">
            <img
              src="/assets/guangdao_6.jpg"
              alt="guangdao_6"
              className="w-full mt-6 lg:w-[47%] px-[25px] lg:px-0"
            />
            <img
              src="/assets/guangdao_7.jpg"
              alt="guangdao_7"
              className="w-full mt-6 lg:w-[47%] px-[25px] lg:px-0"
            />
          </section>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] mt-8 pb-4 px-[25px]">
            Machines are equipped with PC special plasticizing components.
            Screw, barrel and other core parts are specially treated to meet the
            application requirements. Based on our Jupiter machine platform, the
            mold capacity and thickness have been increased.
          </p>

          <p className="text-[rgba(0,0,0,0.7)] text-[14px] pb-6 px-[25px]">
            The full closed-loop injection function helps low-speed injection
            molding and solves the problem of stability in low-speed condition.
            According to the physical laws of the light transmission, the
            plastics in the cavities of the thick-walled mold has to be not only
            filled continuously but also be smooth on the surface to make light
            transmission successful.
          </p>
          <h2 className="text-[#001b36] px-[25px] text-[18px] leading-[22px] mb-3">
            <strong>LONG LIGHT GUIDE</strong>
          </h2>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] mt-3 pb-4 px-[25px]">
            As a special optical part, the long light guide is lightweight with
            a length exceeding 1500mm. There have been several issues, such as
            the combination of clamping and injection components, plasticizing
            unit, and molding technology. It is generally believed that molding
            technology plays an important role which maintaining stability
            during the process based on the relevant tests and analysis.
          </p>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] pb-1 px-[25px]">
            So far, most vehicles on the road are equipped with LED lighting and
            light guides. With the popularity of vehicle intelligent driving
            technology, light guides will be more frequently used in the
            automotive exterior decoration. Haitian will continue to take
            customers and the market as the center, continue to empower the
            automotive industry, consolidate the core competitiveness of
            high-quality development, and jointly enter a new stage of
            development.
          </p>
        </div>

        <div className="mt-10 w-[92%] md:w-[80%] mx-auto border-[0.75pt] border-[rgba(0,0,0,0.4)] p-[25px]">
          <h2 className="text-[#d0a740] text-[18px] font-bold">
            RELATED POSTS
          </h2>
          <div className="xl:flex xl:justify-between">
            <div className="md:flex md:justify-between xl:w-[65%]">
              <div className="md:w-[48%]">
                <Link to="/haitian-chint">
                  <img
                    src="/assets/zhengtai_home-648x250.jpg"
                    alt="hti-china-headquarter"
                    className="my-4 min-h-[150px] object-cover w-full"
                  />
                  <p className="text-[#d0a740] text-[14px] font-bold uppercase">
                    Haitian &amp; Chint｜Thematic Exchange Meeting – Grow
                    together with Customers
                  </p>
                  <p className="text-[rgba(0,0,0,0.6)] text-[14px] pb-5">
                    2022-08-31
                  </p>
                </Link>
              </div>

              <div className="md:w-[48%]">
                <Link to="/new-developments">
                  <img
                    src="/assets/hti-china-headquarter-1024x576-1-648x250.jpg"
                    alt="hti-china-headquarter"
                    className="my-4 min-h-[150px] object-cover w-full"
                  />
                  <p className="text-[#d0a740] text-[14px] font-bold uppercase">
                    New developments and European premieres at K 2022
                  </p>
                  <p className="text-[rgba(0,0,0,0.6)] text-[14px] pb-5">
                    2022-08-05
                  </p>
                </Link>
              </div>
            </div>

            <div className="xl:w-[33%]">
              <Link to="/tour">
                <img
                  src="/assets/germany-open-house_home-648x250.jpg"
                  alt="germany-open-house_home"
                  className="my-4 min-h-[150px] object-cover w-full"
                />
                <p className="text-[#d0a740] text-[14px] font-bold uppercase">
                  A Tour in Haitian International Germany Open House
                </p>
                <p className="text-[rgba(0,0,0,0.6)] text-[14px] pb-5">
                  2022-07-27
                </p>
              </Link>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default HaitianLighting;
