import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Strategy.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import cost from "../assets/StrategyIcons_Cost_Haitian.png";
import lowCost from "../assets/StrategyIcons_LowCost_Haitian.png";
import delivery from "../assets/StrategyIcons_Delivery_Haitian.png";
import availability from "../assets/StrategyIcons_Reliability_Haitian.png";
import savings from "../assets/StrategyIcons_Green_Haitian.png";
import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineMinus } from "react-icons/ai";
import communication from "../assets/communication_icon_400x400_Schatten.png";
import innovation from "../assets/innovation_icon_400x400_Schatten.png";
import efficiency from "../assets/efficiency_icon_400x400_Schatten.png";
import solution from "../assets/haitian_group_hierachy_mobile_new.jpeg";
import zhafir from "../assets/Zhafir-Plastics-Machinery_Logo.png";
import haitian from "../assets/Haitian-Plastics-Machinery_Logo.png";

const Strategy = () => {
  const [lppOpen, setLppOpen] = useState(false);
  const [lscOpen, setLscOpen] = useState(false);
  const [sdtOpen, setSdtOpen] = useState(false);
  const [hmaOpen, setHmaOpen] = useState(false);
  const [hesOpen, setHesOpen] = useState(false);

  const toggleLpp = () => {
    setLppOpen(!lppOpen);
  };

  const toggleLsc = () => {
    setLscOpen(!lscOpen);
  };

  const toggleSdt = () => {
    setSdtOpen(!sdtOpen);
  };

  const toggleHma = () => {
    setHmaOpen(!hmaOpen);
  };

  const toggleHes = () => {
    setHesOpen(!hesOpen);
  };

  return (
    <div>
      <Header selected="Company" />
      <div className="font-sans absolute w-full">
        <div className="mt-[103px] md:mt-[152px] lg:mt-[158.5px] min-h-[40vw]">
          <div className="bg-strategy bg-cover bg-center h-[200px] md:h-[300px] lg:h-[400px]" />
        </div>

        <div className="w-[92%] md:w-[80%] mx-auto text-left pt-8 md:pt-12 xl:pt-0 ">
          <h1 className="text-[rgba(20,58,132,0.7)] text-[15px] md:text-[24px]">
            OUR STRATEGY
          </h1>
          <h2 className="relative pb-[7px] text-[16px] md:text-[32px] text-[#143a84] font-semibold after:content-[''] after:w-[70px] after:h-[4px] after:absolute after:left-0 after:ml-0 after:bottom-[-10px] after:bg-[#cdcdcd]">
            CLEAR COMPETITIVE ADVANTAGES.
          </h2>
          <p className="text-[14px] text-[rgba(0,0,0,0.7)] mt-8 leading-[23.8px]">
            The excellent price-performance ratio of our machines and the
            flexibly adjustable balancing of the most important purchasing
            criteria create the best basis for the highest possible production
            efficiency and a competitive edge right from the start.
          </p>

          <div className="mt-8 xl:mt-16 text-center md:flex md:justify-between md:flex-wrap">
            <div className="md:w-[31%] xl:w-[18%]">
              <img
                src={cost}
                alt="Low Purchase Price"
                className="w-[60%] mx-auto md:w-auto"
              />
              <h5 className="text-[#143a84] font-bold">LOW PURCHASE PRICE</h5>
              <button onClick={toggleLpp}>
                {!lppOpen ? (
                  <AiOutlinePlus className="mx-auto text-[#143a84] mt-2" />
                ) : (
                  <AiOutlineMinus className="mx-auto text-[#143a84] mt-2" />
                )}
              </button>
              {lppOpen && (
                <p className="text-[rgba(0,0,0,0.7)] text-[14px] leading-[23.8px]">
                  50 years of experience in production bring not only expertise
                  and efficiency but also an excellent price-performance ratio
                  for our customers.
                </p>
              )}
            </div>

            <div className="md:w-[31%] xl:w-[18%]">
              <img
                src={lowCost}
                alt="Low Service Costs"
                className="w-[60%] mx-auto md:w-auto"
              />
              <h5 className="text-[#143a84] font-bold">LOW SERVICE COSTS</h5>
              <button onClick={toggleLsc}>
                {!lscOpen ? (
                  <AiOutlinePlus className="mx-auto text-[#143a84] mt-2" />
                ) : (
                  <AiOutlineMinus className="mx-auto text-[#143a84] mt-2" />
                )}
              </button>
              {lscOpen && (
                <p className="text-[rgba(0,0,0,0.7)] text-[14px] leading-[23.8px]">
                  Low-maintenance machine designs, flexible service teams, and a
                  transparent policy on replacement parts: That’s our precept.
                </p>
              )}
            </div>

            <div className="md:w-[31%] xl:w-[18%]">
              <img
                src={delivery}
                alt="Short Delivery Times"
                className="w-[60%] mx-auto md:w-auto"
              />
              <h5 className="text-[#143a84] font-bold">SHORT DELIVERY TIMES</h5>
              <button onClick={toggleSdt}>
                {!sdtOpen ? (
                  <AiOutlinePlus className="mx-auto text-[#143a84] mt-2" />
                ) : (
                  <AiOutlineMinus className="mx-auto text-[#143a84] mt-2" />
                )}
              </button>
              {sdtOpen && (
                <p className="text-[rgba(0,0,0,0.7)] text-[14px] leading-[23.8px]">
                  Assembly plants in all key markets, with machines in stock for
                  modifications tailored to the customer. We are ready
                  worldwide.
                </p>
              )}
            </div>

            <div className="md:w-[31%] xl:w-[18%]">
              <img
                src={availability}
                alt="High Machine Availability"
                className="w-[60%] mx-auto md:w-auto"
              />
              <h5 className="text-[#143a84] font-bold">
                HIGH MACHINE AVAILABILITY
              </h5>
              <button onClick={toggleHma}>
                {!hmaOpen ? (
                  <AiOutlinePlus className="mx-auto text-[#143a84] mt-2" />
                ) : (
                  <AiOutlineMinus className="mx-auto text-[#143a84] mt-2" />
                )}
              </button>
              {hmaOpen && (
                <p className="text-[rgba(0,0,0,0.7)] text-[14px] leading-[23.8px]">
                  Solid construction, sophisticated technology, user-friendly
                  controls. And clear competitive advantages – right from the
                  start.
                </p>
              )}
            </div>

            <div className="md:w-[31%] xl:w-[18%]">
              <img
                src={savings}
                alt="High Energy Savings"
                className="w-[60%] mx-auto md:w-auto"
              />
              <h5 className="text-[#143a84] font-bold">HIGH ENERGY SAVINGS</h5>
              <button onClick={toggleHes}>
                {!hesOpen ? (
                  <AiOutlinePlus className="mx-auto text-[#143a84] mt-2" />
                ) : (
                  <AiOutlineMinus className="mx-auto text-[#143a84] mt-2" />
                )}
              </button>
              {hesOpen && (
                <p className="text-[rgba(0,0,0,0.7)] text-[14px] leading-[23.8px]">
                  Our patented servo-hydraulic drive systems lower energy costs
                  by up to 60%. Guaranteed.
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="grad py-[30px] md:py-[40px] mt-10">
          <div className="w-[92%] md:w-[80%] mx-auto">
            <h3 className="text-[rgba(20,58,132,0.7)] text-[15px] md:text-[24px]">
              OUR WAY
            </h3>
            <h3 className="text-[#143a84] md:text-[32px] uppercase font-semibold mt-1 mb-8 relative after:absolute after:content-[''] after:bg-[#cdcdcd] after:h-[4px] after:w-[70px] after:left-0 after:bottom-[-10px]">
              Technology to the Point. At Best Costs.
            </h3>
            <div className="lg:flex lg:justify-between lg:mt-16 lg:pb-12">
              <div className="lg:w-[30%]">
                <img
                  src={communication}
                  alt="communication"
                  className="max-w-[65%] lg:max-w-[90%] mx-auto"
                />
                <h3 className="text-[rgba(20,58,132,0.7)] text-center font-bold text-[20px] mt-1.5">
                  COMMUNICATION.
                </h3>
                <p className="mt-6 lg:mt-12 text-white text-[14px] leading-[23.8px]">
                  <strong>
                    Faster, better, cheaper – and more variably: Flexibility is
                    one of the key factors for the future in plastics
                    processing. Haitian’s versatility and its efficient
                    solutions are very convincing, with 50 years of experience
                    in research, development, and injection-molding engineering.
                  </strong>
                </p>
              </div>
              <div className="mt-8 lg:mt-0 lg:w-[30%]">
                <img
                  src={innovation}
                  alt="communication"
                  className="max-w-[65%] lg:max-w-[90%] mx-auto"
                />
                <h3 className="text-[rgba(20,58,132,0.7)] text-center font-bold text-[20px] mt-1.5">
                  INNOVATION.
                </h3>
                <p className="mt-6 lg:mt-12 text-white text-[14px] leading-[23.8px]">
                  <strong>
                    We will continue to systematically improve machines,
                    processes, and services in order to meet new challenges
                    effectively with technology to the point that does precisely
                    what our customers truly need. With great integration
                    capability through the use of international standard
                    interfaces for every conceivable production cells and for
                    all networked manufacturing processes. For the best balance
                    between productivity and flexibility at best cost.
                  </strong>
                </p>
              </div>
              <div className="mt-8 lg:mt-0 lg:w-[30%]">
                <img
                  src={efficiency}
                  alt="communication"
                  className="max-w-[65%] lg:max-w-[90%] mx-auto"
                />
                <h3 className="text-[rgba(20,58,132,0.7)] text-center font-bold text-[20px] mt-1.5">
                  EFFICIENCY.
                </h3>
                <p className="mt-6 lg:mt-12 text-white text-[14px] leading-[23.8px]">
                  <strong>
                    We will concentrate on injection molding’s archetypal
                    strengths and invest in appropriate solutions that both
                    expand the performance capabilities of standardized
                    processes and also optimize the advantages of cost-benefit
                    ratio. Solutions that match the available budgets and
                    advance our customers’ business. This is what counts.
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-[92%] md:w-[80%] mx-auto py-8 md:py-16">
          <h3 className="text-[rgba(20,58,132,0.7)] text-[15px] md:text-[24px]">
            OUR SOLUTION
          </h3>
          <h3 className="text-[#143a84] md:text-[32px] leading-[1em] font-semibold mt-1 mb-8 relative after:absolute after:content-[''] after:bg-[#cdcdcd] after:h-[4px] after:w-[70px] after:left-0 after:bottom-[-20px]">
            EFFICIENT MACHINES. FOR EVERY MARKET SEGMENT.
          </h3>
          <p className="text-[14px] text-[rgba(0,0,0,0.7)] mt-8 leading-[23.8px]">
            Haitian International focuses its multi-brand strategy on the
            demands of the widest range of markets. Our machine portfolio offers
            optimal solutions for every demand from 400 to 88,000 kN.
          </p>
          <img src={solution} alt="solution" className="w-full py-7" />
          <div className="lg:flex">
            <div className="lg:w-[31.8%] lg:mr-12">
              <img
                src={zhafir}
                alt="zhafir"
                className="max-w-[90%] mx-auto py-3 lg:hidden"
              />
              <p className="text-[14px] text-[rgba(0,0,0,0.7)] mt-8 lg:mt-0 leading-[23.8px]">
                With its electric machines{" "}
                <Link
                  to="/company/zhafir-plastics-machinery"
                  className="text-[#123b86]"
                >
                  Zhafir Plastics Machinery
                </Link>{" "}
                covers all production ranges in the dynamically expanding
                high-tech markets.
              </p>
            </div>
            <div className="lg:w-[30%]">
              <img
                src={haitian}
                alt="haitian"
                className="max-w-[90%] mx-auto pt-7 lg:hidden"
              />
              <p className="text-[14px] text-[rgba(0,0,0,0.7)] mt-8 lg:mt-0 leading-[23.8px]">
                With its hydraulic machines the{" "}
                <Link
                  to="/company/haitian-plastics-machinery"
                  className="text-[#123b86]"
                >
                  Haitian Plastics Machinery
                </Link>{" "}
                product range covers all applications in the standard segment
                and meets international quality standards.
              </p>
            </div>
          </div>
        </div>

        <div className="w-[92%] mt-7 lg:mb-32 md:w-[80%] mx-auto px-[30px] border-l-[4px] border-l-[rgba(0,0,0,0.7)] italic text-[rgba(0,0,0,0.7)]">
          <h3 className="text-[1em] md:text-[32px] leading-[1.3em] font-semibold mt-1 mb-8 relative after:absolute after:content-[''] after:bg-[#cdcdcd] after:h-[4px] after:w-[70px] after:left-0 after:bottom-[-20px]">
            This sophisticated range of injection-molding machines covers the
            full performance spectrum of the plastics processing industry and
            meets the widest range of customer requirements in the manufacture
            of standard and precision plastic parts with a focus on cost
            efficiency.
          </h3>
          <p className="text-[14px] font-bold">Zhang Jianming</p>
          <p className="text-[14px]">CEO, Haitian International</p>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Strategy;
