import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";

const Careers = () => {
  return (
    <div>
      <Header selected="Careers" />
      <div className="font-sans absolute w-full">
        <div className="mt-[103px] md:mt-[152px] lg:mt-[158.5px] min-h-[40vw]">
          <div className="bg-careers bg-cover bg-center h-[200px] md:h-[300px] lg:h-[400px]" />
        </div>

        <div className="w-[92%] md:w-[80%] mx-auto pt-8 md:pt-12 xl:pt-0 text-left">
          <h1 className="text-[rgba(20,58,132,0.7)] text-[15px] md:text-[24px]">
            START AS OUR EMPLOYEE
          </h1>
          <h2 className="relative pb-[7px] text-[16px] md:text-[32px] text-[#143a84] font-semibold after:content-[''] after:w-[70px] after:h-[4px] after:absolute after:left-0 after:ml-0 after:bottom-[-10px] after:bg-[#cdcdcd]">
            NEW SOLUTIONS FOR NEW TIMES
          </h2>
          <p className="text-[14px] text-[rgba(0,0,0,0.7)] mt-8 leading-[23.8px]">
            We are the world’s leading manufacturer of injection-molding
            machines for plastic products. Here, our brightest minds develop
            innovative technologies and systems solutions for the key markets of
            the future. Become a part of the team!
          </p>
        </div>

        <div className="w-[92%] md:w-[80%] mx-auto pt-16 text-left lg:flex">
          <section className="flex justify-between lg:w-[40%]">
            <div className="text-center w-[50%] md:w-[48%]">
              <img
                src="/assets/HTI_Icons_WEB_F-20-INSPIRATION.png"
                alt="icon"
                className="w-[88.31px] h-[88.31px] mx-auto lg:w-[76.66px] lg:h-[76.66px] xl:h-[101px] xl:w-[101px]"
              />
              <p className="font-semibold text-[rgba(0,0,0,0.7)] text-[14px] leading-[23.8px]">
                A modern and inspiring work environment
              </p>
            </div>
            <div className="text-center w-[50%] md:w-[48%]">
              <img
                src="/assets/HTI_Icons_WEB_F-21-CAREER.png"
                alt="icon"
                className="w-[88.31px] h-[88.31px] mx-auto lg:w-[76.66px] lg:h-[76.66px] xl:h-[101px] xl:w-[101px]"
              />
              <p className="font-semibold text-[rgba(0,0,0,0.7)] text-[14px] leading-[23.8px]">
                Teamwork and professional development are firmly anchored in our
                corporate culture
              </p>
            </div>
          </section>

          <section className="flex justify-between mt-8 lg:mt-0 lg:w-[40%]">
            <div className="text-center w-[50%] md:w-[48%]">
              <img
                src="/assets/HTI_Icons_WEB_F-22-CHANCES.png"
                alt="icon"
                className="w-[88.31px] h-[88.31px] mx-auto lg:w-[76.66px] lg:h-[76.66px] xl:h-[101px] xl:w-[101px]"
              />
              <p className="font-semibold text-[rgba(0,0,0,0.7)] text-[14px] leading-[23.8px]">
                International career opportunities on all continents
              </p>
            </div>
            <div className="text-center w-[50%] md:w-[48%]">
              <img
                src="/assets/HTI_Icons_WEB_F-23-SCIENCE.png"
                alt="icon"
                className="w-[88.31px] h-[88.31px] mx-auto lg:w-[76.66px] lg:h-[76.66px] xl:h-[101px] xl:w-[101px]"
              />
              <p className="font-semibold text-[rgba(0,0,0,0.7)] text-[14px] leading-[23.8px]">
                Powerful Research and Development Departments
              </p>
            </div>
          </section>

          <div className="text-center w-[50%] md:w-[48%] lg:w-[17%] mt-8 lg:mt-0">
            <img
              src="/assets/HTI_Icons_WEB_F-19-EXPANSION.png"
              alt="icon"
              className="w-[88.31px] h-[88.31px] mx-auto lg:w-[76.66px] lg:h-[76.66px] xl:h-[101px] xl:w-[101px]"
            />
            <p className="font-semibold text-[rgba(0,0,0,0.7)] text-[14px] leading-[23.8px]">
              High-level innovative on a global expansion course
            </p>
          </div>
        </div>

        <div className="w-[92%] md:w-[80%] mx-auto pt-12 xl:pt-24 text-left">
          <h2 className="text-[rgba(20,58,132,0.7)] text-[15px] md:text-[24px]">
            WORLDWIDE PERSPECTIVE
          </h2>
          <h3 className="relative pb-[7px] text-[16px] md:text-[32px] text-[#143a84] font-semibold after:content-[''] after:w-[70px] after:h-[4px] after:absolute after:left-0 after:ml-0 after:bottom-[-10px] after:bg-[#cdcdcd]">
            BECOME OUR EMPLOYEE
          </h3>
          <section className="lg:flex lg:justify-between lg:mt-12">
            <p className="text-[14px] text-[rgba(0,0,0,0.7)] mt-8 leading-[23.8px] lg:w-[47%] lg:mt-0">
              From the engineering office to world champion in production –
              growth is in our DNA. With around 56,000 machines produced per
              year, Haitian International is the world’s largest manufacturer of
              injection-molding machines for plastics. We are drivers of
              innovation for energy-saving drives, we deliver systems for
              integrated manufacturing – Industry 4.0 is the catchword – and we
              set the pace in the development of application-based technologies
              at best cost. Employees with the highest qualifications are our
              greatest potential and exemplary personnel management is the
              foundation for our success.
            </p>
            <p className="text-[14px] text-[rgba(0,0,0,0.7)] mt-5 leading-[23.8px] lg:w-[47%] lg:mt-0">
              Our economically stable company offers secure employment to around
              6,000 employees worldwide – at the head office and various plants
              in China, as well as in North and South America, both in the
              Middle East and in Europe. Subsidiaries and service partners in
              more than 130 countries support our direct contact withcustomers
              in all important markets and thus contributing towards our ability
              to successfully continue our dynamic course of sustained growth by
              giving clear competitive advantages to the customer.
            </p>
          </section>
        </div>

        <div className="w-[92%] md:w-[80%] mx-auto pt-12 xl:pt-24 text-left">
          <h2 className="text-[rgba(20,58,132,0.7)] text-[15px] md:text-[24px]">
            GET IN CONTACT WITH US
          </h2>
          <h3 className="relative pb-[7px] text-[16px] md:text-[32px] text-[#143a84] font-semibold after:content-[''] after:w-[70px] after:h-[4px] after:absolute after:left-0 after:ml-0 after:bottom-[-10px] after:bg-[#cdcdcd]">
            YOUR NEXT STEPS
          </h3>

          <div className="bg-[#005f9d] mt-10 text-white flex flex-col lg:flex-col-reverse xl:flex-row-reverse">
            <div className="p-[15px] md:flex md:p-[52px] 'xl:w-1/2 xl:pt-[10rem]">
              <img
                src="/assets/rocket.png"
                alt="rocket"
                className="w-[75px] h-[75px] mx-auto md:mr-5"
              />
              <div>
                <h3 className="text-black text-[18px]">01</h3>
                <h4 className="relative text-[24px] font-semibold after:content-[''] after:w-[70px] after:h-[4px] after:absolute after:left-0 after:ml-0 after:bottom-[-10px] after:bg-white">
                  START YOUR FUTURE
                </h4>
                <p className="text-[14px] leading-[23.8px] mt-5">
                  Delight in success ties us together. Here you are in the very
                  best company. Our team is composed of entry-level personnel,
                  young professionals, and long-serving employees in the widest
                  range of fields.
                </p>
              </div>
            </div>
            <img
              src="/assets/HTI_employees_02.jpg"
              alt="employees"
              className="xl:w-1/2"
            />
          </div>

          <div className="bg-[#0e3b85] text-white flex flex-col xl:flex-row">
            <div className="p-[15px] md:flex md:p-[52px] md:py-[100px] xl:w-1/2">
              <img
                src="/assets/musles.png"
                alt="musles"
                className="w-[75px] h-[75px] mx-auto md:mr-5"
              />
              <div>
                <h3 className="text-black text-[18px]">02</h3>
                <h4 className="relative text-[24px] font-semibold after:content-[''] after:w-[70px] after:h-[4px] after:absolute after:left-0 after:ml-0 after:bottom-[-10px] after:bg-white">
                  MAKE OUR COMPANY STRONGER
                </h4>
                <p className="text-[14px] leading-[23.8px] mt-5">
                  We need bright minds and strong team players, technicians and
                  sales professionals, customer service people, production
                  managers, pragmatists and visionaries. There’s a lot to do and
                  even more ideas needed. We always welcome personnel
                  reinforcement!
                </p>
              </div>
            </div>
            <img
              src="/assets/company_employee_-3.jpg"
              alt="company_employee_-3"
              className="px-[15px] py-12 md:pl-0 md:pr-[20px] xl:w-1/2 xl:pt-0"
            />
          </div>

          <div className="bg-[#5cc2ef] text-white flex flex-col lg:flex-col-reverse xl:flex-row-reverse">
            <div className="p-[15px] md:flex md:p-[52px] md:py-[100px] xl:w-1/2">
              <img
                src="/assets/pen.png"
                alt="pen"
                className="w-[75px] h-[75px] mx-auto md:mr-5"
              />
              <div>
                <h3 className="text-black text-[18px]">03</h3>
                <h4 className="relative text-[24px] font-semibold after:content-[''] after:w-[70px] after:h-[4px] after:absolute after:left-0 after:ml-0 after:bottom-[-10px] after:bg-white">
                  <Link to="/apply">APPLY NOW</Link>
                </h4>
                <p className="text-[14px] leading-[23.8px] mt-5">
                  Apply now – on your own initiative, we’d love it! Show us what
                  you need and why we should get to know each other on a
                  personal level. We look forward to meeting you!
                </p>
                <button className="bg-[#edbb3e] px-5 py-4 rounded-md mt-5 font-semibold">
                  <a
                    href="https://careers.thehaitiangroup.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    VIEW JOB POSTINGS
                  </a>
                </button>
              </div>
            </div>
            <img
              src="/assets/company_employee_3-e1523638133965.jpg"
              alt="company_employee_3-e1523638133965"
              className="px-[15px] py-12 md:pl-0 md:pr-[20px] xl:w-1/2 xl:pb-2"
            />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Careers;
