import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";

const HaitianMultiComponent = () => {
  return (
    <div>
      <Header />
      <div className="font-sans absolute w-full">
        <div className="mt-[153px] md:mt-[202px] lg:mt-[208.5px] w-[92%] md:w-[80%] mx-auto pt-16 pb-10 border-[0.75pt] border-[rgba(0,0,0,0.4)]">
          <h1 className="text-center text-[#d0a740] uppercase text-[23px] font-semibold leading-[50px] px-[25px]">
            HAITIAN’S MULTI-COMPONENT LAMP MOLDING SOLUTION HELPS THE
            DEVELOPMENT OF NEW ENERGY VEHICLES
          </h1>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] text-center mt-3">
            2022-07-22
          </p>
          <img
            src="/assets/biyadi_home.jpg"
            alt="biyadi_home"
            className="w-full my-[25px]"
          />
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] mt-10 pb-3.5 px-[25px]">
            <strong>Accelerated growth of new energy vehicles</strong>
          </p>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] pb-3.5 px-[25px]">
            In recent years, as the market demand for new energy vehicles has
            exploded and sales have continued to reach record highs. In 2022,
            the new energy vehicle industry is still developing rapidly. In the
            first quarter, the total sales volume reached 1,004.2 million RMB,
            and the penetration rate increased to 21.1%, showing an accelerating
            upward trend.
          </p>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] pb-3.5 px-[25px]">
            According to the statistics of the Ministry of Industry and
            Information Technology of the People’s Republic of China, 10.33
            million new energy vehicles have been promoted in China, making
            China an important force leading the development of the global new
            energy vehicle industry. The automotive industry is ecologically
            booming, and the competitive market among major new energy vehicle
            brands will expand.
          </p>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] pb-4.5 px-[25px]">
            <strong>Haitian helps new energy vehicle brands to grow</strong>
          </p>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] py-3.5 px-[25px]">
            New industries are changing day by day and the formation of new
            markets is accelerating. Under the wave of new energy vehicles, also
            the injection molding industry has seen a new development direction.
            Plastic injection-molded products are widely used in the field of
            new energy vehicle manufacturing because of their light weight, good
            electrical insulation, corrosion resistance and other
            characteristics.
          </p>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] pb-3.5 px-[25px]">
            While demand is growing, the market puts forward higher requirements
            for the quality of parts. Facing the increasingly fierce competition
            in the market, how can injection molding machines help car companies
            to further strengthen their advantages in the manufacturing end?
          </p>
          <img
            src="/assets/biyadi_1-1.jpg"
            alt="biyadi_1-1"
            className="w-full my-[10px] px-[25px]"
          />
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] mt-4 pb-3.5 px-[25px]">
            As the industry leader and technology pioneer of domestic plastic
            machines, Haitian has achieved great results in the process of
            cultivating the field of new energy vehicles and obtained a number
            of national invention patents and utility model patents, filling the
            gap in the industry and exploring more ways for new energy vehicle
            enterprises to improve quality and efficiency.
          </p>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] pb-3.5 px-[25px]">
            In the multi-component field, Haitian continues to increase
            technology research and development in response to the lightweight
            development trend of new energy vehicles and takes the lead in
            launching the industry-leading two-color lamp bi-injection molding
            solution to create a more competitive lighting solution with leading
            quality, and help new energy vehicle brands to develop and grow.
          </p>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] pb-3.5 px-[25px]">
            <strong>
              Haitian multi-component vehicle lamp bi-injection molding solution
            </strong>
          </p>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] pb-3.5 px-[25px]">
            Based on the popular JUIII platform of Haitian and relying on the
            profound accumulation of multi-component molding technology, the{" "}
            <strong>bi-injection molding solution</strong> provided for the new
            energy vehicle industry is more flexible and efficient. It can
            easily handle the injection molding of high-quality two-color lamps,
            effectively improve the quality and appearance conditions of lamps,
            realize cost reduction and efficiency increase and bring significant
            cost and technical advantages to new energy vehicle manufacturers.
          </p>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] pb-3.5 px-[25px] mt-3">
            Haitian JU24000M/6730-A6730, with{" "}
            <strong>
              a mold locking force of 2,400T, is the first 2,400T bi-injection
              model in China
            </strong>
            . The whole machine is designed according to the horizontal rotary
            table technology. The main/auxiliary injection device is installed
            on the side of the movable and fixed mold plates respectively, where
            the movable horizontal rotary table or the rotating mold is
            supported by its own base frame, making the mold more compact. It
            can be used as a multi-purpose machine or as two single-color
            injection molding machines to meet the customer’s demand of
            producing large two-color products.
          </p>
          <section className="lg:flex lg:justify-between lg:px-[25px]">
            <img
              src="/assets/biyadi_2.jpg"
              alt="biyadi_2"
              className="w-full px-[25px] mt-6 lg:w-[47%] lg:px-0"
            />
            <img
              src="/assets/biyadi_4.jpg"
              alt="biyadi_4"
              className="w-full px-[25px] mt-6 lg:w-[47%] lg:px-0"
            />
          </section>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] px-[25px] mt-7 lg:hidden">
            <em>JU24000M/6730-A6730</em>
          </p>
          <section className="lg:flex lg:justify-between lg:px-[25px]">
            <img
              src="/assets/biyadi_3.jpg"
              alt="biyadi_3"
              className="w-full px-[25px] mt-6 lg:w-[47%] lg:px-0"
            />
            <img
              src="/assets/biyadi_5.jpg"
              alt="biyadi_5"
              className="w-full px-[25px] mt-6 lg:w-[47%] lg:px-0"
            />
          </section>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] px-[25px] mt-7 hidden lg:block">
            <em>JU24000M/6730-A6730</em>
          </p>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] mt-10 pb-3.5 px-[25px]">
            <strong>Equipment features:</strong>
          </p>
          <ol className="text-[rgba(0,0,0,0.7)] text-[14px] pb-3.5 px-[25px] list-decimal list-inside">
            <li className="mb-1">
              The advanced horizontal rotation technology can achieve double
              capacity in a smaller mold frame and significantly shorten
              production cycle time and reduce electricity and water costs.
            </li>
            <li className="mb-1">
              Larger mold holding space and stronger load-bearing capacity of
              rotary table facilitate stable production of large molds.
            </li>
            <li className="mb-1">
              Reasonable mold cavity distribution is beneficial to mold
              structure design, and is suitable for the production of each
              large-scale multi-component parts.
            </li>
            <li className="mb-1">
              Flexible design realizes more freedom and functional integration
              in the field of new energy vehicle manufacturing.
            </li>
          </ol>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] pb-3.5 px-[25px]">
            <strong>Applications</strong>
          </p>
          <img
            src="/assets/biyadi_6.jpg"
            alt="biyadi_6"
            className="w-full my-[5px] px-[25px]"
          />
          <ul className="text-[rgba(0,0,0,0.7)] text-[14px] pb-3.5 px-[25px] list-disc list-inside mt-6">
            <li className="mb-1">Model: JU24000M/6730-A6730</li>
            <li className="mb-1">Product: Headlight mask (SA3F)</li>
            <li className="mb-1">Single weight: 690 g</li>
            <li className="mb-1">Number of cavities: 2</li>
            <li className="mb-1">Raw material: PC</li>
            <li className="mb-1">
              Industry: Automotive industry – lamp components
            </li>
            <li className="mb-1">Features: Two-color bi-injection process</li>
          </ul>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] pb-3.5 px-[25px]">
            <strong>Moulding the future together</strong>
          </p>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] pb-3.5 px-[25px]">
            Recently, a number of Haitian multi-component bi-injection molding
            machines have been successively installed in domestic new energy
            vehicle manufacturing plants and put into production. In the future,
            Haitian will continue to cooperate with major new energy vehicle
            manufacturers to continuously meet the needs of the industry,
            jointly build a more sustainable industrial ecology, and drive the
            new energy vehicle industry onto the fast lane of green development.
          </p>
        </div>

        <div className="mt-10 w-[92%] md:w-[80%] mx-auto border-[0.75pt] border-[rgba(0,0,0,0.4)] p-[25px]">
          <h2 className="text-[#d0a740] text-[18px] font-bold">
            RELATED POSTS
          </h2>
          <div className="xl:flex xl:justify-between">
            <div className="md:flex md:justify-between xl:w-[65%]">
              <div className="md:w-[48%]">
                <Link to="/haitian-chint">
                  <img
                    src="/assets/zhengtai_home-648x250.jpg"
                    alt="hti-china-headquarter"
                    className="my-4 min-h-[150px] object-cover w-full"
                  />
                  <p className="text-[#d0a740] text-[14px] font-bold uppercase">
                    Haitian &amp; Chint｜Thematic Exchange Meeting – Grow
                    together with Customers
                  </p>
                  <p className="text-[rgba(0,0,0,0.6)] text-[14px] pb-5">
                    2022-08-31
                  </p>
                </Link>
              </div>

              <div className="md:w-[48%]">
                <Link to="/new-developments">
                  <img
                    src="/assets/hti-china-headquarter-1024x576-1-648x250.jpg"
                    alt="hti-china-headquarter"
                    className="my-4 min-h-[150px] object-cover w-full"
                  />
                  <p className="text-[#d0a740] text-[14px] font-bold uppercase">
                    New developments and European premieres at K 2022
                  </p>
                  <p className="text-[rgba(0,0,0,0.6)] text-[14px] pb-5">
                    2022-08-05
                  </p>
                </Link>
              </div>
            </div>

            <div className="xl:w-[33%]">
              <Link to="/tour">
                <img
                  src="/assets/germany-open-house_home-648x250.jpg"
                  alt="germany-open-house_home"
                  className="my-4 min-h-[150px] object-cover w-full"
                />
                <p className="text-[#d0a740] text-[14px] font-bold uppercase">
                  A Tour in Haitian International Germany Open House
                </p>
                <p className="text-[rgba(0,0,0,0.6)] text-[14px] pb-5">
                  2022-07-27
                </p>
              </Link>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default HaitianMultiComponent;
