import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import video from "../assets/ZHAFIR_Online-Teaser_v03.mp4";
import teaser from "../assets/zhafir-home-icon-products.png";
import teaserTwo from "../assets/zhafir-home-icon-service.png";
import venus from "../assets/Zhafir-venus-banner-en.jpeg";
import venusBig from "../assets/zf_ve1900_600x400.png";
import zeres from "../assets/Zhafir-zeres-banner-en.jpeg";
import zeresBig from "../assets/ZeresIII-Series.png";
import jenius from "../assets/Zhafir-jenius-banner-en.jpeg";
import jeniusBig from "../assets/zf_je10800_600x400.png";
import videoTwo from "../assets/video1574150495208.mp4";
import posterTwo from "../assets/7LUD5E_OS686RS7XAXD.png";

const ZhafirMachinery = () => {
  return (
    <div>
      <Header />
      <div className="font-sans absolute w-full">
        <div className="mt-[103px] md:mt-[152px] lg:mt-[158.5px] min-h-[40vw]">
          <div className="h-full w-full relative">
            <div className="relative bg-[rgba(3,3,66,0.38)]">
              <video autoPlay muted loop className="w-full">
                <source src={video} type="video/mp4" />
              </video>
              <div className="absolute top-[60px] text-center md:top-[140px] lg:top-[200px] whiteshad text-[#081739] w-[80%] left-1/2 translate-x-[-50%]">
                <h2 className="md:text-[29.9px] lg:text-[32.2px]">
                  TECHNOLOGY TO THE POINT
                </h2>
                <h1 className="text-[24px] md:text-[45.5px] lg:text-[49px] mt-4">
                  PREMIUM ELECTRICAL SOLUTIONS
                </h1>
              </div>

              <div className="hidden xl:flex absolute top-[430px] w-full left-0 justify-between">
                <div className="bg-[rgba(255,255,255,0.8)] h-[180px] w-[24%]" />
                <div className="bg-[rgba(255,255,255,0.8)] h-[180px] w-[24%] flex items-center justify-center">
                  <div className="text-center text-[#081739]">
                    <img
                      src={teaser}
                      alt="manufactuting power"
                      className="w-[70px] h-[70px] mx-auto"
                    />
                    <h4>
                      MANUFACTURING POWER
                      <br />
                      <small>efficiency to the point</small>
                    </h4>
                  </div>
                </div>
                <div className="bg-[rgba(255,255,255,0.8)] h-[180px] w-[24%] flex items-center justify-center">
                  <div className="text-center text-[#081739]">
                    <img
                      src={teaserTwo}
                      alt="our service"
                      className="w-[70px] h-[70px] mx-auto"
                    />
                    <h4>
                      OUR SERVICE
                      <br />
                      <small>reliability to the point</small>
                    </h4>
                  </div>
                </div>
                <div className="bg-[rgba(255,255,255,0.8)] h-[180px] w-[24%]" />
              </div>
            </div>
          </div>

          <div className="w-[92%] md:w-[80%] mx-auto text-left pt-12">
            <h2 className="relative pb-[7px] text-[16px] md:text-[32px] text-[#013563] font-semibold after:content-[''] after:w-[70px] after:h-[4px] after:absolute after:left-0 after:ml-0 after:bottom-[-10px] after:bg-[#cdcdcd]">
              OUR FACTORIES
            </h2>

            <div className="lg:flex lg:justify-between lg:mt-16">
              <div className="bg-germany bg-cover fifty mt-10 lg:mt-0 lg:w-[47%]">
                <div className="pt-[250px]">
                  <div className="bg-[rgba(255,255,255,0.8)] py-[20px] px-[10px]">
                    <h3 className="text-[18px] text-[rgba(20,58,132,0.7)] pb-[10px]">
                      EBERMANNSDORF, GERMANY
                    </h3>
                    <section className="flex justify-between items-center">
                      <p className="text-[14px] leading-[23.8px] text-[rgba(0,0,0,0.7)] xl:w-[90%]">
                        OUR EUROPEAN HEADQUARTER AND SUBSIDIARY WITH COVERING AN
                        AREA OF ABOUT 25,000 SQM
                      </p>
                      <Link
                        to="/company/worldwide-manufacturing"
                        className="text-[#093175]"
                      >
                        More
                      </Link>
                    </section>
                  </div>
                </div>
              </div>

              <div className="bg-china bg-cover fifty mt-10 lg:mt-0 lg:w-[47%]">
                <div className="pt-[250px]">
                  <div className="bg-[rgba(255,255,255,0.8)] py-[20px] px-[10px]">
                    <h3 className="text-[18px] text-[rgba(20,58,132,0.7)] pb-[10px]">
                      CHUNXIAO, CHINA
                    </h3>
                    <section className="flex justify-between items-center">
                      <p className="text-[14px] leading-[23.8px] text-[rgba(0,0,0,0.7)] xl:w-[90%]">
                        OUR ZHAFIR HEADQUARTER AND FACTORY 15 FOR ELECTRIC
                        MACHINES WITH COVERING AN AREA OF ABOUT 226,000 SQM
                      </p>
                      <Link
                        to="/company/worldwide-manufacturing"
                        className="text-[#093175]"
                      >
                        More
                      </Link>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-[92%] md:w-[80%] mx-auto text-left pt-12">
            <h2 className="relative pb-[7px] text-[16px] md:text-[32px] text-[#143a84] md:text-[#444] font-semibold after:content-[''] after:w-[70px] after:h-[4px] after:absolute after:left-0 after:ml-0 after:bottom-[-10px] after:bg-[#cdcdcd]">
              PRODUCT OVERVIEW
            </h2>

            <div className="md:hidden">
              <div className="border-l-[3px] border-l-[#d0353b] mt-16">
                <img src={venus} alt="venus" className="w-full" />
              </div>
              <div className="border-r-[3px] border-r-[#f58220] mt-8">
                <img src={zeres} alt="zeres" className="w-full" />
              </div>
              <div className="border-l-[3px] border-l-[#7c252c] mt-8">
                <img src={jenius} alt="jenius" className="w-full" />
              </div>
            </div>

            <div className="hidden md:block lg:flex lg:justify-between lg:mt-4">
              <div className="mt-8 lg:w-[30%]">
                <h2 className="font-extrabold text-[5em] text-[#cdcdcd] pl-8">
                  VE
                </h2>
                <img
                  src={venusBig}
                  alt="venus"
                  className="w-full mt-[-30px] border-b border-b-[#000]"
                />
                <h4 className="text-[#444] text-[18px] font-semibold mt-1">
                  ZHAFIR VENUS SERIES
                </h4>
                <ul className="list-disc list-inside text-[#666]">
                  <li>
                    <span>
                      400 – 8,000 KN
                      <br />
                    </span>
                    FULLY ELECTRICAL SOLUTION
                  </li>
                </ul>
              </div>

              <div className="mt-8 lg:w-[30%]">
                <h2 className="font-extrabold text-[5em] text-[#cdcdcd] pl-8">
                  ZE
                </h2>
                <img
                  src={zeresBig}
                  alt="zeres"
                  className="w-full mt-[-30px] border-b border-b-[#000]"
                />
                <h4 className="text-[#444] text-[18px] font-semibold mt-1">
                  ZHAFIR ZERES SERIES
                </h4>
                <ul className="list-disc list-inside text-[#666]">
                  <li>
                    <span>
                      400 – 13,800 KN
                      <br />
                    </span>
                    ELECTRICAL SOLUTION
                  </li>
                </ul>
              </div>

              <div className="mt-8 lg:w-[30%]">
                <h2 className="font-extrabold text-[5em] text-[#cdcdcd] pl-8">
                  JE
                </h2>
                <img
                  src={jeniusBig}
                  alt="jenius"
                  className="w-full mt-[-30px] border-b border-b-[#000]"
                />
                <h4 className="text-[#444] text-[18px] font-semibold mt-1">
                  ZHAFIR JENIUS SERIES
                </h4>
                <ul className="list-disc list-inside text-[#666]">
                  <li>
                    <span>
                      3,600 – 33,000 KN
                      <br />
                    </span>
                    HYBRID ELECTRICAL / TWO-PLATEN SOLUTION
                  </li>
                </ul>
              </div>
            </div>

            <div className="text-white mt-16 lg:mt-24 lg:flex lg:justify-between">
              <video
                controls
                poster={posterTwo}
                className="w-full lg:w-[55%] xl:w-[60%]"
              >
                <source src={videoTwo} type="video/mp4" />
              </video>
              <section className="bg-[#081739] p-[30px] pt-[15px] xl:w-[35%]">
                <h2 className="text-[28px] font-bold leading-[1.2em]">
                  OUR WORLD OF PLASTICS.
                </h2>
                <p className="text-[14px] mt-5">
                  Zhafir Plastics Machinery was founded in 2005 and now has
                  developed into a premium brand under Haitian International.
                  Our sophisticated range of electric injection molding machines
                  from 400 – 33,000 kN covers the full performance spectrum of
                  the plastics processing industry and meets the widest range of
                  customer requirements in the manufacture of standard and
                  precision plastic parts with a focus on premium quality and
                  cost efficiency.
                </p>
              </section>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ZhafirMachinery;
