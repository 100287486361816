import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import video from "../assets/HTPM-Intro_v04.mp4";
import teaser from "../assets/teaser_icon3.png";
import teaserTwo from "../assets/teaser_icon2.png";
import mars from "../assets/Haitian-mars-banner-en.jpeg";
import marsBig from "../assets/MAIII_teaser-1.png";
import jupiter from "../assets/Haitian-jupiter-banner-en.jpeg";
import jupiterBig from "../assets/JUIII_teaser-1.png";
import iapetus from "../assets/Haitian-iapetus-banner-en.jpeg";
import iapetusBig from "../assets/Haitian_MAII_4700_1_Frontal_S_tr-Kopie.png";
import videoTwo from "../assets/Haitian-International-2019-web.mp4";
import posterTwo from "../assets/HTI-Image-Thumbnail-2019.jpeg";

const PlasticsMachinery = () => {
  return (
    <div>
      <Header />
      <div className="font-sans absolute w-full">
        <div className="mt-[103px] md:mt-[152px] lg:mt-[158.5px] min-h-[40vw]">
          <div className="h-full w-full relative">
            <div className="relative bg-[rgba(3,3,66,0.38)]">
              <video autoPlay muted loop className="saturate-200 w-full">
                <source src={video} type="video/mp4" />
              </video>
              <div className="absolute top-[60px] md:top-[140px] lg:top-[200px] whiteshad text-white w-[80%] left-1/2 translate-x-[-50%]">
                <h2 className="md:text-[29.9px] lg:text-[32.2px]">
                  TECHNOLOGY TO THE POINT
                </h2>
                <h1 className="text-[24px] md:text-[45.5px] lg:text-[49px] mt-4">
                  SERVO-HYDRAULIC SOLUTIONS
                </h1>
              </div>

              <div className="hidden xl:flex absolute top-[430px] w-full left-0 justify-between">
                <div className="bg-[rgba(20,58,132,0.5)] h-[180px] w-[24%]" />
                <div className="bg-[rgba(20,58,132,0.5)] h-[180px] w-[24%] flex items-center justify-center">
                  <div className="text-center text-white">
                    <img
                      src={teaser}
                      alt="manufactuting power"
                      className="w-[70px] h-[70px] mx-auto"
                    />
                    <h4>
                      MANUFACTURING POWER
                      <br />
                      <small>efficiency to the point</small>
                    </h4>
                  </div>
                </div>
                <div className="bg-[rgba(20,58,132,0.5)] h-[180px] w-[24%] flex items-center justify-center">
                  <div className="text-center text-white">
                    <img
                      src={teaserTwo}
                      alt="our service"
                      className="w-[70px] h-[70px] mx-auto"
                    />
                    <h4>
                      OUR SERVICE
                      <br />
                      <small>reliability to the point</small>
                    </h4>
                  </div>
                </div>
                <div className="bg-[rgba(20,58,132,0.5)] h-[180px] w-[24%]" />
              </div>
            </div>
          </div>

          <div className="w-[92%] md:w-[80%] mx-auto text-left pt-12">
            <h2 className="relative pb-[7px] text-[16px] md:text-[32px] text-[#444] font-semibold after:content-[''] after:w-[70px] after:h-[4px] after:absolute after:left-0 after:ml-0 after:bottom-[-10px] after:bg-[#cdcdcd]">
              OUR FACTORIES
            </h2>

            <div className="lg:flex lg:justify-between lg:mt-16">
              <div className="bg-machinery bg-cover fifty mt-10 lg:mt-0 lg:w-[47%]">
                <div className="pt-[250px]">
                  <div className="bg-[rgba(255,255,255,0.8)] py-[20px] px-[10px]">
                    <h3 className="text-[18px] text-[rgba(20,58,132,0.7)] pb-[10px]">
                      DOMESTIC PLANTS
                    </h3>
                    <section className="flex justify-between items-center">
                      <p className="text-[14px] leading-[23.8px] text-[rgba(0,0,0,0.7)] xl:w-[90%]">
                        7 DOMESTIC PLANT AREAS IN NINGBO, WUXI, AND GUANGZHOU
                        WITH A TOTAL AREA COVERAGE OF 1,410,000 SQM
                      </p>
                      <Link
                        to="/company/worldwide-manufacturing"
                        className="text-[#093175]"
                      >
                        More
                      </Link>
                    </section>
                  </div>
                </div>
              </div>

              <div className="bg-overseas bg-cover fifty mt-10 lg:mt-0 lg:w-[47%]">
                <div className="pt-[250px]">
                  <div className="bg-[rgba(255,255,255,0.8)] py-[20px] px-[10px]">
                    <h3 className="text-[18px] text-[rgba(20,58,132,0.7)] pb-[10px]">
                      OVERSEAS PLANTS
                    </h3>
                    <section className="flex justify-between items-center">
                      <p className="text-[14px] leading-[23.8px] text-[rgba(0,0,0,0.7)] xl:w-[90%]">
                        3 OVERSEAS PLANTS IN GERMANY, VIETNAM AND INDIA WITH A
                        TOTAL AREA COVERAGE OF MORE THAN 130,000 SQM
                      </p>
                      <Link
                        to="/company/worldwide-manufacturing"
                        className="text-[#093175]"
                      >
                        More
                      </Link>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-[92%] md:w-[80%] mx-auto text-left pt-12">
            <h2 className="relative pb-[7px] text-[16px] md:text-[32px] text-[#143a84] md:text-[#444] font-semibold after:content-[''] after:w-[70px] after:h-[4px] after:absolute after:left-0 after:ml-0 after:bottom-[-10px] after:bg-[#cdcdcd]">
              PRODUCT OVERVIEW
            </h2>

            <div className="md:hidden">
              <div className="border-l-[3px] border-l-[#d0353b] mt-16">
                <img src={mars} alt="mars" className="w-full" />
              </div>
              <div className="border-r-[3px] border-r-[#30903a] mt-8">
                <img src={jupiter} alt="jupiter" className="w-full" />
              </div>
              <div className="border-l-[3px] border-l-[#ffdc37] mt-8">
                <img src={iapetus} alt="iapetus" className="w-full" />
              </div>
            </div>

            <div className="hidden md:block lg:flex lg:justify-between lg:mt-4">
              <div className="mt-8 lg:w-[30%]">
                <h2 className="font-extrabold text-[5em] text-[#cdcdcd] pl-8">
                  MA
                </h2>
                <img
                  src={marsBig}
                  alt="mars"
                  className="w-full mt-[-30px] border-b border-b-[#000]"
                />
                <h4 className="text-[#444] text-[18px] font-semibold mt-1">
                  HAITIAN MARS SERIES
                </h4>
                <ul className="list-disc list-inside text-[#666]">
                  <li>
                    <span>
                      600 – 33,000 KN
                      <br />
                    </span>
                    BESTSELLER WITH OVER 350,000 SOLD MACHINES
                  </li>
                </ul>
              </div>

              <div className="mt-8 lg:w-[30%]">
                <h2 className="font-extrabold text-[5em] text-[#cdcdcd] pl-8">
                  JU
                </h2>
                <img
                  src={jupiterBig}
                  alt="jupiter"
                  className="w-full mt-[-30px] border-b border-b-[#000]"
                />
                <h4 className="text-[#444] text-[18px] font-semibold mt-1">
                  HAITIAN JUPITER SERIES
                </h4>
                <ul className="list-disc list-inside text-[#666]">
                  <li>
                    <span>
                      4,500 – 66,000 KN
                      <br />
                    </span>
                    TWO PLATEN TECHNOLOGY
                  </li>
                </ul>
              </div>

              <div className="mt-8 lg:w-[30%]">
                <h2 className="font-extrabold text-[5em] text-[#cdcdcd] pl-8">
                  IA
                </h2>
                <img
                  src={iapetusBig}
                  alt="iapetus"
                  className="w-full mt-[-30px] border-b border-b-[#000]"
                />
                <h4 className="text-[#444] text-[18px] font-semibold mt-1">
                  HAITIAN IAPETUS SERIES
                </h4>
                <ul className="list-disc list-inside text-[#666]">
                  <li>
                    <span>
                      1,200 – 18,500 KN
                      <br />
                    </span>
                    MULTI COMPONENT TECHNOLOGY
                  </li>
                </ul>
              </div>
            </div>

            <div className="text-white mt-16 lg:mt-24 lg:flex lg:justify-between">
              <video
                controls
                poster={posterTwo}
                className="w-full lg:w-[55%] xl:w-[60%]"
              >
                <source src={videoTwo} type="video/mp4" />
              </video>
              <section className="bg-[#093175] p-[30px] pt-[15px] xl:w-[35%]">
                <h2 className="text-[28px] font-bold leading-[1.2em]">
                  OUR WORLD OF PLASTICS.
                </h2>
                <p className="text-[14px] mt-5">
                  After over five decades of fundamental, technological
                  experience in the production of injection molding machines
                  under the brand name of ‘Haitian’, Haitian International
                  Holdings Ltd. reached a new milestone in the company history.
                  The main focus of the Haitian brand is on the development and
                  production of standard injection molding machines for the mass
                  production market.
                </p>
              </section>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default PlasticsMachinery;
