import React, { useState } from "react";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoCloseSharp } from "react-icons/io5";
import { IoMdArrowDropdown } from "react-icons/io";
import { IoMdArrowDropup } from "react-icons/io";

const Header = (props) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [companyDropdownOpen, setCompanyDropdownOpen] = useState(false);

  const toggleMenu = () => {
    setMobileMenuOpen((prevState) => !prevState);
  };

  const toggleDropdown = () => {
    setCompanyDropdownOpen((prevState) => !prevState);
  };

  return (
    <div>
      <header className="absolute px-4 md:px-20 lg:px-24 xl:px-36 pt-7 border-b-2 border-[#001B36] font-sans text-[14px] lg:text-[15px] bg-white lg:fixed lg:top-0 z-10 w-full">
        <nav className="flex items-center justify-between pb-7 w-full">
          <Link to="/">
            <img
              src="/assets/haitian-group-logo.svg"
              alt="logo"
              className="w-[170px] h-[45px] lg:w-[197.28px] lg:h-[50px]"
            />
          </Link>

          <button onClick={toggleMenu} className="md:hidden">
            {mobileMenuOpen ? (
              <IoCloseSharp className="text-[#093175] w-[30px] h-[30px]" />
            ) : (
              <GiHamburgerMenu className="text-[#093175] w-[30px] h-[30px]" />
            )}
          </button>
        </nav>

        {mobileMenuOpen && (
          <ul className="mt-[-18px] pb-3 text-[#444444] md:hidden">
            <li
              className={`py-[10px] ${
                props.selected === "Company" && "text-[#001B36]"
              }`}
            >
              <span className="flex justify-between items-center">
                <Link to="/company">COMPANY</Link>{" "}
                <button onClick={toggleDropdown}>
                  {companyDropdownOpen ? (
                    <IoMdArrowDropup />
                  ) : (
                    <IoMdArrowDropdown />
                  )}
                </button>
              </span>
              {companyDropdownOpen && (
                <ul className="border-t-2 border-t-[#143a84] mt-2.5 p-[10px] bg-[#f2f2f2] shadow-[0px_0px_8px_0px_rgb(0_0_0_/_20%)]">
                  <li className="mt-2 text-[#143a84] relative before:absolute before:content-[''] before:h-[2px] before:w-10 before:bg-[#143a84] before:top-[22px]">
                    VISIT OUR BRANDS
                  </li>
                  <li className="mt-3.5">
                    <Link to="/zhafir-plastics-machinery">
                      Zhafir Plastics Machinery
                    </Link>
                  </li>
                  <li className="mt-2">
                    <Link to="/haitian-plastics-machinery">
                      Haitian Plastics Machinery
                    </Link>
                  </li>
                  <li className="mt-2">
                    <Link to="/haitian-smart-solutions">
                      Haitian Smart Solutions
                    </Link>
                  </li>

                  <li className="mt-3 text-[#143a84] relative before:absolute before:content-[''] before:h-[2px] before:w-10 before:bg-[#143a84] before:top-[22px]">
                    WHO WE ARE
                  </li>
                  <li className="mt-3.5">
                    <Link to="/company/facts-figures">Facts & Figures</Link>
                  </li>
                  <li className="mt-2">
                    <Link to="/company/our-strategy">Our Strategy</Link>
                  </li>
                  <li className="mt-2">
                    <Link to="/company/our-culture">Our Culture</Link>
                  </li>
                  <li className="mt-2">
                    <Link to="/company/our-mission">Our Mission</Link>
                  </li>
                  <li className="mt-2">
                    <Link to="/company/milestones">Milestones</Link>
                  </li>
                  <li className="mt-2">
                    <Link to="/company/start-as-our-employee">
                      Start as our employee
                    </Link>
                  </li>

                  <li className="mt-3 text-[#143a84] relative before:absolute before:content-[''] before:h-[2px] before:w-10 before:bg-[#143a84] before:top-[22px]">
                    GLOBAL PRESENCE
                  </li>
                  <li className="mt-3.5">
                    <Link to="/company/worldwide-manufacturing">
                      Worldwide Manufacturing
                    </Link>
                  </li>
                  <li className="mt-2">
                    <Link to="/company/our-subsidiaries">Regional Offices</Link>
                  </li>
                </ul>
              )}
            </li>
            <li
              className={`py-[10px] ${
                props.selected === "Portfolios" && "text-[#001B36]"
              }`}
            >
              <Link to="/portfolios">PORTFOLIOS</Link>
            </li>
            <li
              className={`py-[10px] ${
                props.selected === "Culture" && "text-[#001B36]"
              }`}
            >
              <Link to="/culture">CULTURE</Link>
            </li>
            <li
              className={`py-[10px] ${
                props.selected === "Social Value" && "text-[#001B36]"
              }`}
            >
              <Link to="/social-value">SOCIAL VALUE</Link>
            </li>
            <li
              className={`py-[10px] ${
                props.selected === "Newsroom" && "text-[#001B36]"
              }`}
            >
              <Link to="/newsroom">NEWSROOM</Link>
            </li>
            <li
              className={`py-[10px] ${
                props.selected === "Careers" && "text-[#001B36]"
              }`}
            >
              <Link to="/careers">CAREERS</Link>
            </li>
            <li
              className={`py-[10px] ${
                props.selected === "Contact" && "text-[#001B36]"
              }`}
            >
              <Link to="/contact">CONTACT</Link>
            </li>
          </ul>
        )}

        <ul className="pb-5 pt-2 px-6 lg:px-12 hidden md:px-0 md:flex md:justify-between md:items-center text-[#444444]">
          <li
            className={`flex justify-between items-center  ${
              props.selected === "Company" && "text-[#001B36]"
            } hover:text-[#001B36]`}
          >
            <Link to="/company">COMPANY</Link>
            <button onClick={toggleDropdown} className="pl-3">
              {companyDropdownOpen ? (
                <IoMdArrowDropup />
              ) : (
                <IoMdArrowDropdown />
              )}
            </button>
          </li>
          <li
            className={`${
              props.selected === "Portfolios" && "text-[#001B36]"
            } hover:text-[#001B36]`}
          >
            <Link to="/portfolios">PORTFOLIOS</Link>
          </li>
          <li
            className={`${
              props.selected === "Culture" && "text-[#001B36]"
            } hover:text-[#001B36]`}
          >
            <Link to="/culture">CULTURE</Link>
          </li>
          <li
            className={`${
              props.selected === "Social Value" && "text-[#001B36]"
            } hover:text-[#001B36]`}
          >
            <Link to="/social-value">SOCIAL VALUE</Link>
          </li>
          <li
            className={`${
              props.selected === "Newsroom" && "text-[#001B36]"
            } hover:text-[#001B36]`}
          >
            <Link to="/newsroom">NEWSROOM</Link>
          </li>
          <li
            className={`${
              props.selected === "Careers" && "text-[#001B36]"
            } hover:text-[#001B36]`}
          >
            <Link to="/careers">CAREERS</Link>
          </li>
          <li
            className={`${
              props.selected === "Contact" && "text-[#001B36]"
            } hover:text-[#001B36]`}
          >
            <Link to="/contact">CONTACT</Link>
          </li>
        </ul>

        {companyDropdownOpen && (
          <ul
            onMouseLeave={() => {
              setTimeout(() => {
                setCompanyDropdownOpen(false);
              }, 200);
            }}
            className="hidden md:inline lg:fixed lg:left-0 lg:w-full md:z-20 bg-white border-y-2 border-y-[#143a84] pt-[50px]"
          >
            <div className="pt-[10px] pb-[40px] px-[30px] lg:px-[102.5px] xl:px-[144px] xl:pr-[400px] flex justify-between">
              <div>
                <li className="text-[#143a84] relative before:absolute before:content-[''] before:h-[2px] before:w-10 before:bg-[#143a84] before:top-[28px]">
                  VISIT OUR BRANDS
                </li>
                <li className="mt-4 hover:text-[#143a84]">
                  <Link to="/zhafir-plastics-machinery">
                    Zhafir Plastics Machinery
                  </Link>
                </li>
                <li className="mt-2 hover:text-[#143a84]">
                  <Link to="/haitian-plastics-machinery">
                    Haitian Plastics Machinery
                  </Link>
                </li>
                <li className="mt-2 hover:text-[#143a84]">
                  <Link to="/haitian-smart-solutions">
                    Haitian Smart Solutions
                  </Link>
                </li>
              </div>

              <div>
                <li className="text-[#143a84] relative before:absolute before:content-[''] before:h-[2px] before:w-10 before:bg-[#143a84] before:top-[28px]">
                  WHO WE ARE
                </li>
                <li className="mt-4 hover:text-[#143a84]">
                  <Link to="/company/facts-figures">Facts & Figures</Link>
                </li>
                <li className="mt-2 hover:text-[#143a84]">
                  <Link to="/company/our-strategy">Our Strategy</Link>
                </li>
                <li className="mt-2 hover:text-[#143a84]">
                  <Link to="/company/our-culture">Our Culture</Link>
                </li>
                <li className="mt-2 hover:text-[#143a84]">
                  <Link to="/company/our-mission">Our Mission</Link>
                </li>
                <li className="mt-2 hover:text-[#143a84]">
                  <Link to="/company/milestones">Milestones</Link>
                </li>
                <li className="mt-2 hover:text-[#143a84]">
                  <Link to="/company/start-as-our-employee">
                    Start as our employee
                  </Link>
                </li>
              </div>

              <div>
                <li className="text-[#143a84] relative before:absolute before:content-[''] before:h-[2px] before:w-10 before:bg-[#143a84] before:top-[28px]">
                  GLOBAL PRESENCE
                </li>
                <li className="mt-4 hover:text-[#143a84]">
                  <Link to="/company/worldwide-manufacturing">
                    Worldwide Manufacturing
                  </Link>
                </li>
                <li className="mt-2 hover:text-[#143a84]">
                  <Link to="/company/our-subsidiaries">Regional Offices</Link>
                </li>
              </div>
            </div>
          </ul>
        )}
      </header>
    </div>
  );
};

export default Header;
