import React from "react";
import { Routes, Route } from "react-router-dom";
import Careers from "./pages/Careers";
import Company from "./pages/Company";
import Contact from "./pages/Contact";
import Culture from "./pages/Culture";
import Employee from "./pages/Employee";
import Facts from "./pages/Facts";
import HaitianChint from "./pages/HaitianChint";
import HaitianEastern from "./pages/HaitianEastern";
import HaitianLighting from "./pages/HaitianLighting";
import HaitianMultiComponent from "./pages/HaitianMultiComponent";
import Home from "./pages/Home";
import Manufacturing from "./pages/Manufacturing";
import Milestones from "./pages/Milestones";
import NewDevelopments from "./pages/NewDevelopments";
import Newsroom from "./pages/Newsroom";
import NotFound from "./pages/NotFound";
import OurCulture from "./pages/OurCulture";
import OurMission from "./pages/OurMission";
import PlasticsMachinery from "./pages/PlasticsMachinery";
import Portfolios from "./pages/Portfolios";
import Regional from "./pages/Regional";
import SmartSolutions from "./pages/SmartSolutions";
import SocialValue from "./pages/SocialValue";
import Strategy from "./pages/Strategy";
import Tour from "./pages/Tour";
import ZhafirMachinery from "./pages/ZhafirMachinery";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/careers" element={<Careers />} />
      <Route path="/company" element={<Company />} />
      <Route path="/company/facts-figures" element={<Facts />} />
      <Route path="/company/milestones" element={<Milestones />} />
      <Route path="/company/our-culture" element={<OurCulture />} />
      <Route path="/company/our-mission" element={<OurMission />} />
      <Route path="/company/our-strategy" element={<Strategy />} />
      <Route path="/company/our-subsidiaries" element={<Regional />} />
      <Route path="/company/start-as-our-employee" element={<Employee />} />
      <Route
        path="/company/worldwide-manufacturing"
        element={<Manufacturing />}
      />
      <Route path="/contact" element={<Contact />} />
      <Route path="/culture" element={<Culture />} />
      <Route path="/haitian-chint" element={<HaitianChint />} />
      <Route path="/haitian-eastern" element={<HaitianEastern />} />
      <Route path="/haitian-lighting" element={<HaitianLighting />} />
      <Route
        path="/haitian-multi-component"
        element={<HaitianMultiComponent />}
      />
      <Route
        path="/haitian-plastics-machinery"
        element={<PlasticsMachinery />}
      />
      <Route path="/haitian-smart-solutions" element={<SmartSolutions />} />
      <Route path="/new-developments" element={<NewDevelopments />} />
      <Route path="/newsroom" element={<Newsroom />} />
      <Route path="/portfolios" element={<Portfolios />} />
      <Route path="/social-value" element={<SocialValue />} />
      <Route path="/tour" element={<Tour />} />
      <Route path="/zhafir-plastics-machinery" element={<ZhafirMachinery />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
