import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

const SocialValue = () => {
  return (
    <div>
      <Header selected="Social Value" />
      <div className="font-sans absolute w-full">
        <div className="mt-[103px] md:mt-[152px] lg:mt-[158.5px] min-h-[40vw] lg:bg-hero-social lg:bg-cover lg:px-[6%]">
          <img
            src="/assets/group_values_teaser.jpg"
            alt="hero-social"
            className="lg:hidden"
          />
          <h2 className="hidden lg:block text-white lg:pl-20 text-[45px] uppercase font-semibold leading-[1em] pt-[180px]">
            Our World -<br />
            Our Responsibility.
          </h2>
        </div>
        <div className="h-[30px] bg-[#E4BE70]" />

        <div className="w-[92%] md:w-[80%] mx-auto pt-10 md:pt-9 text-left">
          <h2 className="text-[32px] text-[#001b36] uppercase font-semibold leading-[1em] lg:hidden">
            Our World -<br />
            Our Responsibility.
          </h2>

          <h2 className="font-medium text-[20px] md:text-[24px] mt-8 md:mt-24 lg:mt-12 mb-2.5 text-[#001b36] uppercase">
            Haitian Social Projects
          </h2>
          <h3 className="text-[32px] text-[#001b36] uppercase font-semibold leading-[1em]">
            Help For People And The Community
          </h3>

          <div className="lg:flex lg:justify-between">
            <div className="lg:w-[73.625%]">
              <p className="text-[rgba(0,0,0,0.7)] text-[14px] leading-[23.8px] mt-8">
                <strong>
                  The value of an entrepreneur lies not only in development of
                  the corporation, but also for contribution&nbsp;to community
                  and responsibility to contribute to a better country and for
                  the people.“ spoke&nbsp;Mr. Zhang Jingzhang, Chairman of
                  Haitian International Holdings Ltd., at the inauguration
                  ceremony&nbsp;of Haitian Charity Foundation.
                </strong>
              </p>
              <p className="text-[rgba(0,0,0,0.7)] text-[14px] leading-[23.8px] mt-3.5">
                <strong>
                  We believe the success in business shall go hand-in-hand with
                  responsibility to community and provision&nbsp;of help to
                  groups in need. At the same time, charity activities provide
                  valuable opportunities&nbsp;for our employees and partners to
                  contribute to community with their own skills and resources.
                </strong>
              </p>
            </div>
            <div className="flex justify-end mt-6 lg:w-[20.875%] lg:h-[95.03px] xl:h-[118.8px]">
              <img
                src="/assets/Social_Values_Logo.jpg"
                alt="social-values-logo"
                className="w-[60%]"
              />
            </div>
          </div>

          <p className="mt-2 text-[rgba(0,0,0,0.7)] text-[14px] leading-[23.8px]">
            Since the founding of Haitian Group, our efforts to contribute to
            our community have never stopped. Our employees also contributed
            personally for aids to natural disasters and this was echoed with
            “One-Day Donation” since 2003. The total contribution from Haitian
            Group and its employees till 2020 for charitable purposes had
            reached RMB 200 million. In order to conduct such charitable
            activities in a systematic and consistent manner, the Zhejiang
            Haitian Charity Foundation was founded on January 8, 2013.
          </p>
          <p className="mt-4 text-[rgba(0,0,0,0.7)] text-[14px] leading-[23.8px]">
            The purpose of the foundation is to support people in need in our
            community, such as children deprived of education, people who are in
            financial distress due to illness,&nbsp;physical disability or loss
            of family members But also to support our employees, for example
            through workplace and training programmes as well as daily life
            support. Today,&nbsp;the Haitian group has more than 90 public
            programs. Expenditure in the field of charitable charity amounted to
            5,980,000 RMB per year.
          </p>
          <img
            src="/assets/inauguration-4.jpg"
            alt="inauguration"
            className="mt-2"
          />
          <p className="mt-7 text-[rgba(0,0,0,0.7)] text-[14px] leading-[23.8px]">
            Inauguration ceremony of the Zhejiang Haitian Charity Foundation on
            February 4, 2013
          </p>

          <h4 className="mt-4 mb-3 text-[#001b36] text-[15px] font-medium md:text-[24px] md:mt-[48px]">
            DISCOVER
          </h4>
          <h5 className="text-[#001b36] font-semibold text-[32px] leading-[1em]">
            REGULAR ACTIVITIES
          </h5>
        </div>

        <div className="bg-social bg-cover mt-7">
          <div className="w-[92%] md:w-[80%] mx-auto pt-7 text-left">
            <h5 className="text-[#001b36] font-semibold text-[32px] leading-[1em]">
              HOUSEHOLD &amp; EVERYDAY LIFE
            </h5>

            <div className="mt-6 lg:flex lg:justify-between">
              <div className="mb-[30px] lg:w-[30%]">
                <img
                  src="/assets/HTIGROUP_WEB_SOCIAL_V03.jpg"
                  alt="HTIGROUP_WEB_SOCIAL_V03"
                  className="border-b-4 border-b-[#d0a740]"
                />
                <div className="bg-white px-[20px] py-[30px] lg:h-[114px] xl:h-[96px]">
                  <p className="text-[#001b36] text-[20px] leading-[18px]">
                    APPLIANCES REPAIR GROUP
                  </p>
                </div>
              </div>

              <div className="mb-[30px] lg:w-[30%]">
                <img
                  src="/assets/Social_Values_Bike-Repair_02_2.jpg"
                  alt="HTIGROUP_WEB_SOCIAL_V03"
                  className="border-b-4 border-b-[#d0a740]"
                />
                <div className="bg-white px-[20px] py-[30px]">
                  <p className="text-[#001b36] text-[20px] leading-[18px]">
                    BIKE &amp; ELECTRO <br />
                    MOBILE REPAIR GROUP
                  </p>
                </div>
              </div>

              <div className="lg:w-[30%]">
                <img
                  src="/assets/Social_Values_Day_02_2.jpg"
                  alt="HTIGROUP_WEB_SOCIAL_V03"
                  className="border-b-4 border-b-[#d0a740]"
                />
                <div className="bg-white px-[20px] py-[30px]">
                  <p className="text-[#001b36] text-[20px] leading-[18px]">
                    PHILANTHROPHY <br />
                    &amp; COMMONWEAL DAY
                  </p>
                </div>
              </div>
            </div>

            <p className="text-[rgba(0,0,0,0.7)] text-[14px] leading-[23.8px] mt-2.5 lg:mt-0 mb-6">
              The Small Household Appliances Repair Group, the Bike &amp;
              Electromobile Repair Group and other activities are organized by
              our staff. We also encourage our employees&nbsp;to organize new
              charity activities so that in addition to participation, they are
              able to input their idea in the design, planning and
              implementation of such activities and our&nbsp;Foundation would
              provide financial support. Also we provide finan cial household
              support to families in need as a result of natur al disasters,
              accidents or severe sickness.
            </p>

            <hr />

            <h5 className="text-[#001b36] font-semibold text-[32px] leading-[1em] mt-7 mb-6">
              MEDICINE &amp; HEALTH
            </h5>
            <div className="lg:w-[30%]">
              <img
                src="/assets/Social_Values_Medical_01_2.jpg"
                alt="HTIGROUP_WEB_SOCIAL_V03"
                className="border-b-4 border-b-[#d0a740]"
              />
              <div className="bg-white px-[20px] py-[30px]">
                <p className="text-[#001b36] text-[20px] leading-[18px]">
                  MEDICAL CHECK-UP
                </p>
              </div>
            </div>
            <p className="text-[rgba(0,0,0,0.7)] text-[14px] leading-[23.8px] mt-3 lg:mt-4 mb-6">
              We provide free initial medical check-ups and seminar on common
              disease preventive measures and treatment by doctors with
              different expertise. Other Community Service&nbsp;are the visit of
              elderly and support to special groups of people in need. For
              example in joint operation with Ningbo Beilun Xiaogang Jiedao
              Women Association we visited&nbsp;women in financial needs and
              suffering from cancer and youths in need to improve their
              livelihood and learning. Also we provide financial support to
              people in need as a&nbsp;result of severe sickness or who cannot
              afford medical expenses.
            </p>

            <hr />

            <h5 className="text-[#001b36] font-semibold text-[32px] leading-[1em] mt-7 mb-6">
              COMMUNITY
            </h5>

            <div className="mt-6 lg:flex lg:justify-between">
              <div className="mb-[30px] lg:w-[30%]">
                <img
                  src="/assets/Social_Values_Walfare_03_2.jpg"
                  alt="HTIGROUP_WEB_SOCIAL_V03"
                  className="border-b-4 border-b-[#d0a740]"
                />
                <div className="bg-white px-[20px] py-[30px] lg:h-[114px] xl:h-[96px]">
                  <p className="text-[#001b36] text-[20px] leading-[18px]">
                    WALFARE
                    <br />
                    ACTIVITY GROUP
                  </p>
                </div>
              </div>

              <div className="mb-[30px] lg:w-[30%]">
                <img
                  src="/assets/Social_Values_Clothing_01_1.jpg"
                  alt="HTIGROUP_WEB_SOCIAL_V03"
                  className="border-b-4 border-b-[#d0a740]"
                />
                <div className="bg-white px-[20px] py-[30px] lg:h-[114px] xl:h-[96px]">
                  <p className="text-[#001b36] text-[20px] leading-[18px]">
                    CLOTHING DONATION
                  </p>
                </div>
              </div>

              <div className="lg:w-[30%]">
                <img
                  src="/assets/Social_Values_Reading_01.jpg"
                  alt="HTIGROUP_WEB_SOCIAL_V03"
                  className="border-b-4 border-b-[#d0a740]"
                />
                <div className="bg-white px-[20px] py-[30px] lg:h-[114px] xl:h-[96px]">
                  <p className="text-[#001b36] text-[20px] leading-[18px]">
                    READING CAMP
                  </p>
                </div>
              </div>
            </div>
            <p className="text-[rgba(0,0,0,0.7)] text-[14px] leading-[23.8px] mt-3 lg:mt-0 mb-6">
              Activities such as environmental protection or clothing donations
              are among our regular program points. Special charity projects
              include reading camps for children as&nbsp;well as financial
              support for families in need. We also devote a lot of attention
              and financial support to families who are struggling to send their
              children to school and to&nbsp;the educational needs of adults at
              high school and university level. Last but not least, the annual
              Haitian Charity Day offers free services such as haircut,
              photography, eye&nbsp;examination, blood tests, etc.
            </p>

            <hr />

            <h5 className="text-[#001b36] font-semibold text-[32px] leading-[1em] mt-7 mb-6">
              EDUCATIONAL
            </h5>
            <div className="lg:w-[30%]">
              <img
                src="/assets/Tibet1.jpg"
                alt="HTIGROUP_WEB_SOCIAL_V03"
                className="border-b-4 border-b-[#d0a740]"
              />
              <div className="bg-white px-[20px] py-[30px]">
                <p className="text-[#001b36] text-[20px] leading-[28px] uppercase">
                  Visit to Ka Ang Minority Schools in Qinghai Province
                </p>
              </div>
            </div>
            <p className="text-[rgba(0,0,0,0.7)] text-[14px] leading-[23.8px] mt-3 lg:mt-4 mb-6 pb-2">
              The Ka Ang Minority School in Qinghai Province in the northeast of
              the Tibetan highlands provides free education. The children
              receiving education at this school are orphans,&nbsp;children from
              single-parent families and families without labour capabilities.
              Haitian Group has been providing financial support to the school
              since 2014 and Haitian&nbsp;Charity Foundation financed the new
              school building in 2016.
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default SocialValue;
