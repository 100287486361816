import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import revenue from "../assets/2021国际4.png";
import sales from "../assets/2021国际2-1.png";
import machines from "../assets/2021国际3.png";

const Facts = () => {
  return (
    <div>
      <Header />
      <div className="font-sans absolute w-full">
        <div className="mt-[103px] md:mt-[152px] lg:mt-[158.5px] min-h-[40vw]">
          <div className="bg-facts bg-cover bg-center h-[200px] md:h-[300px] lg:h-[400px]" />
        </div>

        <div className="w-[92%] md:w-[80%] mx-auto pt-8 md:pt-12 xl:pt-0 text-left">
          <h1 className="text-[rgba(20,58,132,0.7)] text-[24px]">
            FACTS & FIGURES
          </h1>
          <h2 className="relative pb-[7px] uppercase text-[16px] md:text-[32px] text-[#143a84] font-semibold after:content-[''] after:w-[70px] after:h-[4px] after:absolute after:left-0 after:ml-0 after:bottom-[-10px] after:bg-[#cdcdcd]">
            Haitian International Holdings Ltd.
          </h2>
          <p className="text-[16px] text-[rgba(0,0,0,0.7)] mt-8 leading-[23.8px]">
            In 2021, the Covid 19 virus outbreak and its rapid spread into a
            pandemic severely shook the entire global economy. Nearly all
            countries and all major economies in the world are still reeling
            from the effects today. China’s economy benefited from strict
            preventive and control measures taken by the government and was soon
            able to return to positive growth and return to normal economic
            operation. Thanks to this rapid recovery in the domestic economic
            situation, the entire Group experienced strong, growing demand.
            &nbsp;
          </p>
          <p className="text-[16px] text-[rgba(0,0,0,0.7)] mt-3.5 leading-[23.8px]">
            As a result, Haitian International posted a new sales record for the
            completed 2021 fiscal year, with well over 56,000 machines sold.
            This represents total sales of RMB 16,018 million and a year-on-year
            increase in sales of 35.7 percent. &nbsp;
          </p>

          <h2 className="text-[rgba(20,58,132,0.7)] mt-10 xl:mt-24 text-[24px]">
            NUMERICAL OVERVIEW
          </h2>
        </div>

        <div className="w-[92%] md:w-[80%] mx-auto pt-8 md:pt-12 xl:pt-20 text-left uppercase lg:flex lg:justify-between">
          <div className="py-[30px] border-b-[1px] border-b-[#ddd] lg:w-[30%] lg:border-b-0 lg:border-r-[1px] lg:border-r-[#ddd] lg:pr-5">
            <h3 className="text-[30px] xl:text-[38px] text-[#143a84] leading-[18px] lg:leading-8 xl:mt-[76px]">
              <strong>2,512 Mio. USD</strong>
            </h3>
            <p className="text-[16px] text-[rgba(0,0,0,0.7)]">-</p>
            <h3 className="text-[20px] xl:text-[25px] text-[#143a84] leading-[18px]">
              Total Revenues In 2021
            </h3>
          </div>

          <div className="py-[30px] border-b-[1px] border-b-[#ddd] lg:w-[30%] lg:border-b-0 lg:border-r-[1px] lg:border-r-[#ddd]">
            <h3 className="text-[30px] xl:text-[38px] text-[#143a84] leading-[18px] xl:mt-[76px]">
              <strong>773 Mio. USD</strong>
            </h3>
            <p className="text-[16px] text-[rgba(0,0,0,0.7)]">-</p>
            <h3 className="text-[20px] xl:text-[25px] xl:w-[80%] text-[#143a84] leading-[18px] lg:leading-8">
              Total Revenues Of export in 2021
            </h3>
          </div>

          <div className="py-[30px] border-b-[1px] border-b-[#ddd] lg:w-[30%] lg:border-b-0">
            <h3 className="text-[30px] xl:text-[38px] text-[#143a84] leading-[18px] lg:leading-8 xl:mt-[76px]">
              <strong>6,668</strong>
            </h3>
            <p className="text-[16px] text-[rgba(0,0,0,0.7)]">-</p>
            <h3 className="text-[20px] xl:text-[25px] text-[#143a84] leading-[18px]">
              Employees worldwide
            </h3>
          </div>
        </div>

        <div className="w-[92%] md:w-[80%] mx-auto text-left uppercase lg:flex lg:justify-between">
          <div className="py-[30px] border-b-[1px] border-b-[#ddd] lg:w-[30%] lg:border-b-0 lg:border-r-[1px] lg:border-r-[#ddd] lg:pr-5">
            <h3 className="text-[30px] xl:text-[38px] text-[#143a84] leading-[18px] lg:leading-8 xl:mt-[76px]">
              <strong>56,000 machines</strong>
            </h3>
            <p className="text-[16px] text-[rgba(0,0,0,0.7)]">-</p>
            <h3 className="text-[20px] text-[#143a84] leading-[18px]">
              Total delivered Machines in 2021
            </h3>
          </div>

          <div className="py-[30px] border-b-[1px] border-b-[#ddd] lg:w-[30%] lg:border-b-0 lg:border-r-[1px] lg:border-r-[#ddd]">
            <h3 className="text-[30px] xl:text-[38px] text-[#143a84] leading-[18px] xl:mt-[76px]">
              <strong>1,120,000 sqm</strong>
            </h3>
            <p className="text-[16px] text-[rgba(0,0,0,0.7)]">-</p>
            <h3 className="text-[20px] xl:text-[25px] xl:w-[80%] text-[#143a84] leading-[18px] lg:leading-8">
              Production area
            </h3>
          </div>

          <div className="py-[30px] border-b-[1px] border-b-[#ddd] lg:w-[30%] lg:border-b-0">
            <h3 className="text-[30px] text-[#143a84] leading-[18px] lg:leading-8 xl:mt-[76px]">
              <strong>1,540,000 sqm</strong>
            </h3>
            <p className="text-[16px] text-[rgba(0,0,0,0.7)]">-</p>
            <h3 className="text-[20px] xl:text-[25px] text-[#143a84] leading-[18px]">
              Total area coverage
            </h3>
          </div>
        </div>

        <div className="w-[92%] md:w-[80%] text-[rgba(20,58,132,0.7)] mx-auto text-left uppercase">
          <h2 className="mt-10 xl:mt-24 text-[24px]">GRAPHICAL OVERVIEW</h2>

          <div className="lg:flex lg:justify-between mt-12 lg:mt-20">
            <div className="p-5 pt-2 border border-[#646565] lg:w-[48%]">
              <h3 className="lg:text-[18px]">Revenue 2021 (Mio)</h3>
              <img src={revenue} alt="revenue" className="w-full mt-5" />
            </div>
            <div className="p-5 pt-2 border border-[#646565] mt-8 lg:mt-0 lg:w-[48%]">
              <h3 className="lg:text-[18px]">Sale by region 2021</h3>
              <img src={sales} alt="sales" className="w-full mt-5" />
            </div>
          </div>

          <div className="lg:flex lg:justify-center lg:items-center">
            <div className="p-5 pt-2 border border-[#646565] mt-8 lg:mt-12 lg:w-[60%]">
              <h3 className="lg:text-[18px]">Delivered Machines in 2021</h3>
              <img src={machines} alt="sales" className="w-full mt-5" />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Facts;
