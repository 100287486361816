import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import communication from "../assets/communication_icon_400x400_Schatten.png";
import innovation from "../assets/innovation_icon_400x400_Schatten.png";
import efficiency from "../assets/efficiency_icon_400x400_Schatten.png";

const OurMission = () => {
  return (
    <div>
      <Header />
      <div className="font-sans absolute w-full">
        <div className="mt-[103px] md:mt-[152px] lg:mt-[158.5px] min-h-[40vw]">
          <div className="hidden md:block mission py-10 lg:py-12">
            <div className="w-[80%] xl:w-[70%] mx-auto lg:flex lg:justify-between">
              <div>
                <img
                  src={communication}
                  alt="communication"
                  className="max-w-[180px] mx-auto"
                />
                <h3 className="text-center text-white mt-7">COMMUNICATION.</h3>
              </div>

              <div className="mt-12 lg:mt-0">
                <img
                  src={innovation}
                  alt="innovation"
                  className="max-w-[180px] mx-auto"
                />
                <h3 className="text-center text-white mt-7">INNOVATION</h3>
                <p className="text-center text-white">
                  means to us
                  <br />
                  TECHNOLOGY TO THE POINT
                </p>
              </div>

              <div className="mt-12 lg:mt-0">
                <img
                  src={efficiency}
                  alt="efficiency"
                  className="max-w-[180px] mx-auto"
                />
                <h3 className="text-center text-white mt-7">EFFICIENCY.</h3>
              </div>
            </div>
          </div>

          <div className="w-[92%] md:w-[80%] mx-auto pt-8 md:pt-12 xl:pt-20 text-left">
            <h1 className="text-[rgba(20,58,132,0.7)] text-[24px]">
              OUR MISSION
            </h1>
            <h2 className="relative pb-[7px] uppercase text-[16px] md:text-[32px] text-[#143a84] font-semibold after:content-[''] after:w-[70px] after:h-[4px] after:absolute after:left-0 after:ml-0 after:bottom-[-10px] after:bg-[#cdcdcd]">
              Leadership has many Dimensions
            </h2>

            <div className="lg:flex lg:justify-between lg:mt-12">
              <div className="lg:w-[47%]">
                <p className="text-[14px] text-[rgba(0,0,0,0.7)] mt-8 lg:mt-0 leading-[23.8px]">
                  The success of the plastics processor is of pivotal importance
                  to our approach at Haitian. As the Planets have orbital
                  reliance on the Sun, we have branded our products with the
                  same customer commitment. With consistent customer focus we
                  became a world leader in the injection molding machine
                  industry.
                </p>
                <p className="text-[14px] text-[rgba(0,0,0,0.7)] mt-4 leading-[23.8px]">
                  Belief in our core values is paramount, as is a sincere
                  appreciation for the trust of our customers and the support of
                  our competent partners and investors supported by the
                  continuous diligence of all our staff.
                </p>
                <p className="text-[14px] text-[rgba(0,0,0,0.7)] mt-4 leading-[23.8px]">
                  An important reason for our success is that we have pursued
                  and lived our
                </p>
              </div>

              <div className="lg:w-[47%]">
                <p className="text-[14px] text-[rgba(0,0,0,0.7)] mt-4 lg:mt-0 leading-[23.8px]">
                  vision in every detail – to offer technology of premium
                  quality at affordable prices. We have continually questioned
                  our thinking and actions through our communication with all
                  market partners and other involved parties. We work on
                  creating innovation – whether it is big or small, and
                  constantly strive for perfection and the highest efficiency
                  with our own manufacturing. This is equally true for machine
                  performance on the customer’s premises.
                </p>
                <p className="text-[14px] text-[rgba(0,0,0,0.7)] mt-4 leading-[23.8px]">
                  We continue to maintain this policy with competent expansion.
                  Everything we do is intended to place our customers in a
                  position to overcome challenges profitably and sustainably.
                  With this promise we shall enjoy success together in the
                  future.
                </p>
              </div>
            </div>
          </div>

          <div className="bg-our-mission bg-cover px-[4%] pb-[25px] whiteshad mt-16 xl:mt-32">
            <Carousel showThumbs={false} showStatus={false} infiniteLoop={true}>
              <div className="text-white pb-[90px] lg:flex lg:justify-between">
                <img
                  style={{ width: "55%", paddingTop: "120px" }}
                  src={communication}
                  alt="communication"
                  className="communication"
                />
                <div className="lg:pt-48 lg:w-[50%]">
                  <h2 className="uppercase text-[20px] lg:text-[46px] lg:leading-[36px] lg:font-light lg:text-left font-medium mb-6">
                    Our Mission: COMMUNICATION.
                  </h2>
                  <p className="text-[14px] text-left">
                    Photosynthesis is the source of all life and growth on our
                    planet. Year by year plants produce more than 100 billion
                    tonnes of biomass and solar energy radiation which is
                    transformed into new life – and thereby into new energy.
                  </p>
                  <p className="text-[14px] text-left mt-5">
                    It is a perfectly balanced combination, a masterful dialogue
                    of nature. And this provides an analogy for communication
                    with our customers, suppliers, business partners, investors
                    and colleagues.
                  </p>
                </div>
              </div>

              <div className="text-white pb-[90px] lg:flex lg:justify-between">
                <img
                  style={{ width: "55%", paddingTop: "15px" }}
                  src={communication}
                  alt="communication"
                  className="communication"
                />
                <div className="lg:pt-12 lg:w-[50%]">
                  <h2 className="uppercase text-[20px] lg:text-[24px] font-medium mb-6 text-left mt-8">
                    KNOWLEDGE WITH UNDERSTANDING, LEARNING FOR IMPROVEMENT
                  </h2>
                  <p className="text-[14px] text-left">
                    Our machine and solution concepts have reached a secure
                    design status for specific market and customer requirements.
                    For mutual inspiration, we continually pursue information
                    between all market partners to gain perspectives, understand
                    trends and fulfill customer desires to create a stronger
                    than ever influence on our product design.
                  </p>
                  <p className="text-[14px] text-left mt-5">
                    Modern communication tools should facilitate this goal.
                    Internet portals for information and news, newsletters and
                    magazines for background and progress reports, networks for
                    knowledge transfer, think-tanks for research and
                    development, web seminars and online training are just some
                    of the opportunities. With these tools, we can efficiently
                    communicate our vision and knowledge with practical benefits
                    for everyoneso we all achieve even better solutions.
                  </p>
                </div>
              </div>
            </Carousel>
          </div>

          <div className="bg-our-mission-two bg-cover px-[4%] pb-[25px] whiteshad mt-16">
            <Carousel showThumbs={false} showStatus={false} infiniteLoop={true}>
              <div className="text-white pb-[90px] lg:flex lg:justify-between">
                <img
                  style={{ width: "55%", paddingTop: "70px" }}
                  src={innovation}
                  alt="innovation"
                  className="communication"
                />
                <div className="lg:pt-24 lg:w-[50%]">
                  <h2 className="uppercase text-[20px] lg:text-[46px] lg:leading-[36px] lg:font-light lg:text-left font-medium mb-6 mt-7">
                    Our Mission: INNOVATION.
                  </h2>
                  <p className="text-[14px] text-left">
                    The story of evolution is bursting with innovation and
                    flexibility.
                  </p>
                  <p className="text-[14px] text-left mt-5">
                    Nature has many wonderful examples of innovation. Like “The
                    lotus effect”,” Flying Fish”, “Gecko Feet” with super
                    adhesive power. These are just a few examples from which we
                    draw great motivation for our vision and strategy at Haitian
                    International.
                  </p>
                  <p className="text-[14px] text-left mt-5">
                    Nature’s ability to renew and adapt to evolving
                    environmental conditions is what we also utilize so
                    successfully. We preserve and encourage the innovative
                    strength of our research and development and are committed
                    to overcoming new challenges quickly and effectively.
                  </p>
                </div>
              </div>

              <div className="text-white pb-[40px] lg:flex lg:justify-between">
                <img
                  style={{ width: "55%", paddingTop: "15px" }}
                  src={innovation}
                  alt="innovation"
                  className="communication"
                />
                <div className="lg:pt-16 lg:w-[50%]">
                  <h2 className="uppercase text-[24px] lg:text-left font-medium mb-6 mt-8">
                    INNOVATIVE THINKING IS THINKING IN TERMS OF THE CUSTOMER
                  </h2>
                  <p className="text-[14px] text-left">
                    Offering standard solutions is the strength of our business.
                    We meet 80% of the plastics industry’s requirements from
                    machines developed by our Haitian, Zhafir and Tianjin
                    brands. With our machines it is possible to process almost
                    every material using a diverse selection of options. With
                    new materials and technologies pushing the boundaries of
                    processing, we are constantly preparing for the future.
                  </p>
                  <p className="text-[14px] text-left mt-5">
                    We will focus on the basic strengths of injection moulding
                    technology, and invest in proper solutions which will both
                    expand the standardized process performance capability and
                    also optimize the cost-benefit ratio. That means solutions
                    which correspond to available budgets and which put our
                    customers ahead. That is what counts.
                  </p>
                </div>
              </div>
            </Carousel>
          </div>

          <div className="bg-our-mission-three bg-cover px-[4%] pb-[25px] whiteshad mt-16">
            <Carousel showThumbs={false} showStatus={false} infiniteLoop={true}>
              <div className="text-white pb-[1px] lg:flex lg:justify-between">
                <img
                  style={{ width: "55%", paddingTop: "70px" }}
                  src={efficiency}
                  alt="efficiency"
                  className="communication"
                />
                <div className="lg:pt-24 lg:w-[50%]">
                  <h2 className="uppercase text-[20px] lg:text-[46px] lg:leading-[36px] lg:font-light lg:text-left font-medium mb-6 mt-7">
                    Our Mission: EFFICIENCY.
                  </h2>
                  <p className="text-[14px] text-left">
                    Focusing on what is really needed for improvement is the key
                    to innovation
                  </p>
                  <p className="text-[14px] text-left mt-5">
                    Nature is a perfect example of effective innovation. For
                    instance, plant’s attract bees through their colourful
                    flowers and scent, but also use electric emissions toprovide
                    a signal for the level of their nectar. Bees use this signal
                    to determine whether the flower is full or empty. This
                    reflects Nature’s perfect example for foresight, flexibility
                    and speed in adapting to changing conditions. This is a
                    perfect analogy for what we understand as efficiency.
                  </p>
                </div>
              </div>

              <div className="text-white pb-[40px] lg:flex lg:justify-between">
                <img
                  style={{ width: "55%", paddingTop: "15px" }}
                  src={efficiency}
                  alt="efficiency"
                  className="communication"
                />
                <div className="lg:pt-16 lg:w-[50%]">
                  <h2 className="uppercase text-[24px] lg:text-left font-medium mb-6 mt-8">
                    EFFICIENT MACHINES, <br />
                    EFFICIENT PRODUCTION
                  </h2>
                  <p className="text-[14px] text-left">
                    Our daily manufacturing goal is to work with the highest
                    possible efficiency through the constant improvement of all
                    value-added processes, from sourcing through to quality
                    control. Manufacturing with high quality but using
                    standardised components to reduce individual unit costs is
                    our goal. Our facilities adopt a vertically integrated
                    manufacturing system with the constant expansion of our
                    worldwide production network, saving on logistics costs.
                  </p>
                  <p className="text-[14px] text-left mt-5">
                    In this way we have been able to sustainably reduce our
                    manufacturing costs considerably over the past few years.
                    This was achieved with a simultaneous increase in output
                    capacity, providing improved benefits to our customers. And
                    so shall it continue.
                  </p>
                </div>
              </div>
            </Carousel>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default OurMission;
