import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";

const Tour = () => {
  return (
    <div>
      <Header />
      <div className="font-sans absolute w-full">
        <div className="mt-[153px] md:mt-[202px] lg:mt-[208.5px] w-[92%] md:w-[80%] mx-auto pt-16 pb-10 border-[0.75pt] border-[rgba(0,0,0,0.4)]">
          <h1 className="text-center text-[#d0a740] uppercase text-[23px] font-semibold leading-[50px] px-[25px]">
            A Tour in Haitian International Germany Open House
          </h1>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] text-center mt-3">
            2022-07-27
          </p>
          <img
            src="/assets/germany-open-house_home.jpg"
            alt="germany-open-house_home"
            className="w-full my-[25px]"
          />
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] mt-6 px-[25px]">
            <strong>
              From July 4 to 7, 2022, Haitian International Germany held its
              annual open house in Ebermannsdorf, Bavaria to show Haitian’s
              latest star models and popular industry applications. During this
              period, many customer partners came to the plant to discuss
              technology and share Bavarian food.
            </strong>
          </p>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] mt-5 pb-3.5 px-[25px]">
            As usual, the wonderful content arrived as scheduled. This year,
            Haitian Germany showed people the injection molding technology with
            “Technology to the point””, efficient industry solutions and related
            automation.
          </p>
          <section className="lg:flex lg:justify-between">
            <img
              src="/assets/germany-open-house_1.jpg"
              alt="germany-open-house_1"
              className="w-full px-[25px] mt-6 lg:w-[31%] lg:pr-0"
            />
            <img
              src="/assets/germany-open-house_2.jpg"
              alt="germany-open-house_2"
              className="w-full px-[25px] mt-6 lg:w-[31%] lg:px-0"
            />
            <img
              src="/assets/germany-open-house_3.jpg"
              alt="germany-open-house_3"
              className="w-full px-[25px] mt-6 lg:w-[31%] lg:pl-0"
            />
          </section>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] mt-8 pb-3.5 px-[25px]">
            At the event, Haitian International Germany showed its mature
            injection molding equipment and comprehensive strength, covering
            auto parts, thin-wall packaging, cosmetics, daily necessities and
            other industries, to fully demonstrate Haitian’s ability and
            confidence to be close to European customers and meet the European
            market. The audience also had “zero” contact with Haitian and ZHAFIR
            product families at the plant to know Haitian’s rich range of
            manufacturing processes.
          </p>
          <section className="lg:flex lg:justify-between">
            <img
              src="/assets/germany-open-house_4.jpg"
              alt="germany-open-house_4"
              className="w-full px-[25px] mt-6 lg:w-[31%] lg:pr-0"
            />
            <img
              src="/assets/germany-open-house_5.jpg"
              alt="germany-open-house_5"
              className="w-full px-[25px] mt-6 lg:w-[31%] lg:px-0"
            />
            <img
              src="/assets/germany-open-house_6.jpg"
              alt="germany-open-house_6"
              className="w-full px-[25px] mt-6 lg:w-[31%] lg:pl-0"
            />
          </section>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] mt-8 px-[25px]">
            At the same time, Haitian International Germany also provided a
            series of interactions during the open house, including technical
            information release, factory visit, industry lectures and
            discussions and other activities, and our partners also showed their
            own solutions on site.
          </p>
          <p className="text-[rgba(0,0,0,0.7)] text-[14px] mt-3.5 px-[25px]">
            Haitian has been deeply engaged in the European market for many
            years. With its profound technological accumulation and localization
            advantages, it has joined hands with partners to respond to
            customers more efficiently and to provide them with all-round
            support such as R&amp; D, manufacturing, training, services and
            overall project management, as well as more ingenious and customized
            solutions, so as to create outstanding value for European customers.
          </p>
        </div>

        <div className="mt-10 w-[92%] md:w-[80%] mx-auto border-[0.75pt] border-[rgba(0,0,0,0.4)] p-[25px]">
          <h2 className="text-[#d0a740] text-[18px] font-bold">
            RELATED POSTS
          </h2>
          <div className="xl:flex xl:justify-between">
            <div className="md:flex md:justify-between xl:w-[65%]">
              <div className="md:w-[48%]">
                <Link to="/haitian-chint">
                  <img
                    src="/assets/zhengtai_home-648x250.jpg"
                    alt="hti-china-headquarter"
                    className="my-4 min-h-[150px] object-cover w-full"
                  />
                  <p className="text-[#d0a740] text-[14px] font-bold uppercase">
                    Haitian &amp; Chint｜Thematic Exchange Meeting – Grow
                    together with Customers
                  </p>
                  <p className="text-[rgba(0,0,0,0.6)] text-[14px] pb-5">
                    2022-08-31
                  </p>
                </Link>
              </div>

              <div className="md:w-[48%]">
                <Link to="/new-developments">
                  <img
                    src="/assets/hti-china-headquarter-1024x576-1-648x250.jpg"
                    alt="hti-china-headquarter"
                    className="my-4 min-h-[150px] object-cover w-full"
                  />
                  <p className="text-[#d0a740] text-[14px] font-bold uppercase">
                    New developments and European premieres at K 2022
                  </p>
                  <p className="text-[rgba(0,0,0,0.6)] text-[14px] pb-5">
                    2022-08-05
                  </p>
                </Link>
              </div>
            </div>

            <div className="xl:w-[33%]">
              <Link to="/haitian-eastern">
                <img
                  src="/assets/huanan_1-648x250.jpg"
                  alt="huanan_1"
                  className="my-4 min-h-[150px] object-cover w-full"
                />
                <p className="text-[#d0a740] text-[14px] font-bold uppercase">
                  HAITIAN EASTERN OPERATION CENTER SOUTHEAST CHINA SETS SAIL
                </p>
                <p className="text-[rgba(0,0,0,0.6)] text-[14px]">2022-07-22</p>
              </Link>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Tour;
